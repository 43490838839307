import styled from "@emotion/styled";
import { colors, boxFrame, fontWeight, fontSize, rem } from "@/styleguide";
export var StyledRoot = styled.div({
    alignItems: "center",
    backgroundColor: colors.alabaster,
    display: "flex",
    padding: boxFrame([0, 25]),
});
export var Details = styled.div({
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
});
export var ViewerName = styled.span({
    fontSize: rem(fontSize.regular),
    fontWeight: fontWeight.semiBold,
});
