import styled from "@emotion/styled";
import { Tr } from "@/components/molecules/Table";
import { fontSize, fontWeight, colors } from "@/styleguide";
export var Header = styled.header({
    fontSize: fontSize.medium,
    fontWeight: fontWeight.semiBold,
    marginTop: 45,
});
export var CustomRow = styled(Tr)({
    alignItems: "flex-start",
});
export var InvalidRow = styled(CustomRow)({
    border: "2px solid " + colors.red,
});
export var SelectAllWrapper = styled.div({
    alignItems: "center",
    display: "flex",
    marginTop: 30,
});
export var CheckboxLabel = styled.span({
    fontSize: fontSize.regular,
    marginLeft: 30,
});
export var TableWrapper = styled.div({
    marginTop: 40,
});
export var Buttons = styled.div({
    backgroundColor: colors.alabaster,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    padding: "20px 0",
    position: "fixed",
    right: 0,
    button: {
        marginLeft: 50,
    },
});
export var Empty = styled.p({
    color: colors.grey,
    margin: "30px 0 0",
    fontSize: fontSize.regular,
});
export var Pagination = styled.div({
    marginLeft: 60,
    marginTop: 20,
});
