import styled from "@emotion/styled";
import { Gap } from "@/components/atoms/Gap";
import { colors } from "@/styleguide";
export var BackgroundProvider = styled.div({
    backgroundColor: colors.white,
});
export var TableWrapper = styled.div({
    marginTop: 40,
});
export var Plug = styled.p({
    color: colors.dustyGrey,
    lineHeight: 1.5,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
});
export var ErrorMessage = styled(Plug)({
    color: colors.buttercup,
});
export var PaginationWrapper = styled.div({
    marginTop: 30,
});
export var Separator = styled.div({
    height: 10,
});
export var InfoHeader = styled.div({
    margin: "0 18px 10px",
});
export var SelectActions = styled.div({
    alignItems: "flex-start",
    display: "flex",
    fontSize: "14px",
    justifyContent: "flex-end",
    marginTop: "20px",
});
export var ActionCol = styled(Gap)({
    padding: "10px 20px",
    width: "15%",
});
export var Stats = styled.div({
    marginTop: "45px",
    paddingRight: "10px",
});
export var StatsInfo = styled.div({
    fontSize: "14px",
});
export var RequestStatus = styled.div(function (props) { return ({
    color: props.status === "declined"
        ? colors.sweetPink
        : props.status === "approved"
            ? colors.java
            : colors.grey,
    textTransform: "capitalize",
}); });
export var TransactionStatus = styled.div(function (props) { return ({
    color: props.status === "n/a"
        ? colors.sweetPink
        : props.status === "completed"
            ? colors.java
            : colors.grey,
    textTransform: "capitalize",
}); });
