var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { StyledLink } from "./styles";
import { themes } from "./themes";
export function Link(props) {
    var _a;
    return React.createElement(StyledLink, __assign({}, props, { theme: themes[(_a = props.theme) !== null && _a !== void 0 ? _a : "java"] }));
}
