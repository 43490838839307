var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { FormField } from "@/components/atoms/FormField";
import { Gap } from "@/components/atoms/Gap";
import { TextInput } from "@/components/atoms/TextInput";
import { FormMessage } from "@/components/molecules/FormMessage";
import { createValidation, passwordRules } from "@/utils/validate";
import { ButtonWrapper, FormMessageWrapper } from "./styles";
var validate = createValidation({
    oldPassword: passwordRules,
    newPassword: passwordRules,
    confirmNewPassword: __spreadArrays(passwordRules, [
        [function (val, values) { return val === values.newPassword; }, "Has to match new password field"],
    ]),
});
export function ChangePasswordForm(props) {
    var _a, _b;
    var t = useTranslation("UserSettingsView.ChangePasswordForm").t;
    var _c = useFormik({
        validate: validate,
        initialValues: {
            confirmNewPassword: "",
            newPassword: (_a = process.env.DEFAULT_USER_PASSWORD) !== null && _a !== void 0 ? _a : "",
            oldPassword: (_b = process.env.DEFAULT_USER_PASSWORD) !== null && _b !== void 0 ? _b : "",
        },
        onSubmit: props.onSubmit,
    }), errors = _c.errors, touched = _c.touched, handleBlur = _c.handleBlur, handleChange = _c.handleChange, handleSubmit = _c.handleSubmit, values = _c.values;
    return (React.createElement("form", { className: props.className, onSubmit: handleSubmit },
        React.createElement(Gap, { direction: "y", size: 15 },
            React.createElement(FormField, { error: touched.oldPassword ? errors.oldPassword : undefined, label: t("oldPasswordLabel") },
                React.createElement(TextInput, { name: "oldPassword", onBlur: handleBlur, onChange: handleChange, placeholder: t("oldPasswordPlaceholder"), type: "password", value: values.oldPassword })),
            React.createElement(FormField, { error: touched.newPassword ? errors.newPassword : undefined, label: t("newPasswordLabel") },
                React.createElement(TextInput, { name: "newPassword", onBlur: handleBlur, onChange: handleChange, placeholder: t("newPasswordPlaceholder"), type: "password", value: values.newPassword })),
            React.createElement(FormField, { error: touched.confirmNewPassword ? errors.confirmNewPassword : undefined, label: t("confirmNewPasswordLabel") },
                React.createElement(TextInput, { name: "confirmNewPassword", onBlur: handleBlur, onChange: handleChange, placeholder: t("confirmNewPasswordPlaceholder"), type: "password", value: values.confirmNewPassword })),
            React.createElement(ButtonWrapper, null,
                React.createElement(Button, { disabled: props.loading, type: "submit", width: 225 }, t("save")))),
        props.formMessage && props.formStatus && (React.createElement(FormMessageWrapper, null,
            React.createElement(FormMessage, { type: props.formStatus }, props.formMessage)))));
}
