import React from "react";
import { Icon } from "@/components/atoms/Icon";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { Logo } from "@/components/atoms/Logo";
import { ViewPath } from "@/constants/routing";
import { useDropdown } from "@/hooks/useDropdown";
import { colors } from "@/styleguide";
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownLink, Header, HeaderSection, IconWrapper, LogoLink, LogoutWrapper, Settings, StyledAppNav, StyledViewerArea, Wrapper, } from "./styles";
export var AppHeader = function (props) {
    var ref = React.useRef(null);
    var _a = useDropdown(ref), isMenuOpen = _a[0], closeMenu = _a[2], toggleMenu = _a[3];
    var t = useTranslation('AppHeader').t;
    return (React.createElement(Header, null,
        React.createElement(InnerContainer, { noPadding: true },
            React.createElement(Wrapper, null,
                React.createElement(HeaderSection, null,
                    React.createElement(LogoLink, { to: props.homeUrl },
                        React.createElement(Logo, { theme: "topNav" })),
                    React.createElement(StyledAppNav, null, props.children)),
                React.createElement(HeaderSection, null,
                    React.createElement(Settings, { ref: ref },
                        React.createElement(IconWrapper, { onClick: toggleMenu },
                            React.createElement(Icon, { name: "cog", size: 22 })),
                        isMenuOpen && (React.createElement(Dropdown, null,
                            (props.viewerRole === "employer" || props.viewerRole === "key_contact") && (React.createElement(DropdownLink, { onClick: closeMenu, to: ViewPath.WITHDRAWAL_SETTINGS }, t('withdrawalSettings'))),
                            React.createElement(DropdownLink, { onClick: closeMenu, to: ViewPath.USER_SETTINGS }, t('manageProfile'))))),
                    React.createElement(StyledViewerArea, { viewerName: props.viewerName }),
                    React.createElement(LogoutWrapper, { onClick: props.onLogOut },
                        React.createElement(Icon, { color: colors.java, name: "shutdown", size: 24 })))))));
};
