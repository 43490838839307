import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { colors, fontWeight, fontSize } from "@/styleguide";
export var StyledRoot = styled.figure({
    alignItems: "center",
    display: "inline-flex",
    margin: 0,
});
export var Caption = styled.figcaption({
    color: colors.shipGrey,
    display: "inline",
    fontSize: fontSize.small,
    marginLeft: 20,
    maxWidth: "calc(100% - 50px)",
    fontWeight: fontWeight.semiBold,
}, function (props) { return ({
    fontWeight: props.theme.fontWeight,
    color: props.theme.color,
}); });
export var CaptionLink = styled(Caption)({
    textDecoration: "none",
}).withComponent(Link);
