import styled from "@emotion/styled";
import { fontSize, colors } from "@/styleguide";
export var AlignToCenter = styled.div({
    alignItems: "center",
    display: "flex",
});
export var KYBStatus = styled.p({
    fontSize: fontSize.small,
    marginTop: 15,
});
export var SwitchLabel = styled.span({
    fontSize: fontSize.regular,
    marginRight: 25,
});
export var Status = styled.span({
    marginLeft: 9,
}, function (props) { return ({
    color: props.kybActive ? colors.java : colors.red,
}); });
