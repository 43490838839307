import isEmpty from "lodash/isEmpty";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { Icon } from "@/components/atoms/Icon";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { Link } from "@/components/atoms/Link";
import { AvatarWithLabel } from "@/components/molecules/AvatarWithLabel";
import { Pagination } from "@/components/molecules/Pagination";
import { Table, Thead, Tbody, Th, Td, Tr } from "@/components/molecules/Table";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { PER_PAGE } from "@/constants/queries";
import { ViewPath } from "@/constants/routing";
import { useCompaniesQuery } from "@/containers/queries/CompaniesQuery";
import { useViewer } from "@/hooks/useViewer";
import { colors } from "@/styleguide";
import { formatMoney } from "@/utils/format";
import { getAvatarPlaceholder } from "@/utils/misc";
import { applyUrlParams } from "@/utils/routing";
import { CompanyActions } from "./CompanyActions";
import { AlignToCenter, BackgroundProvider, Empty, Header, MoneyCell, TableWrapper, PaginationWrapper, } from "./styles";
export var CompaniesView = function () {
    var t = useTranslation("CompaniesView").t;
    var _a = React.useState(1), page = _a[0], setPage = _a[1];
    var companiesQuery = useCompaniesQuery({ page: page, perPage: PER_PAGE }, { fetchPolicy: "cache-and-network" });
    var isAdmin = useViewer().isAdmin;
    return (React.createElement(ProtectedView, { pageTitle: t("pageTitle"), theme: "darken" },
        React.createElement(BackgroundProvider, null,
            React.createElement(InnerContainer, null,
                React.createElement(Header, null,
                    React.createElement(Button, { theme: "secondary", to: ViewPath.NEW_COMPANY, type: "button", width: 320 },
                        React.createElement(Icon, { color: colors.java, name: "personWithPlus", size: 24 }),
                        t("addCompany"))))),
        React.createElement(InnerContainer, null, (function () {
            if (companiesQuery.loading && isEmpty(companiesQuery.data)) {
                return t("loadingComponents");
            }
            if (companiesQuery.error || !companiesQuery.data) {
                return t("errorOccurred");
            }
            var companies = companiesQuery.data.companies.data;
            var _a = companiesQuery.data.companies, currentPage = _a.currentPage, perPage = _a.perPage, totalCount = _a.totalCount;
            if (companies.length === 0) {
                return (React.createElement(Empty, null,
                    "No companies are created yet.",
                    React.createElement("br", null),
                    React.createElement(Link, { to: ViewPath.NEW_COMPANY, title: t("createCompany") }, "Click here"),
                    " ",
                    "to create one."));
            }
            var totalPages = Math.ceil(totalCount / perPage);
            return (React.createElement(TableWrapper, null,
                React.createElement(Table, { cols: [15, 11, 14, 14, 14, 12, 20] },
                    React.createElement(Thead, null,
                        React.createElement(Th, null, t("companyName")),
                        React.createElement(Th, null, t("keyContact")),
                        React.createElement(Th, null, t("totalEmployees")),
                        React.createElement(Th, null, t("activeEmployees")),
                        React.createElement(Th, null, t("totalTransactions")),
                        React.createElement(Th, null, t("totalPayAdvanced")),
                        React.createElement(Th, null)),
                    React.createElement(Tbody, null, companies.map(function (company) { return (React.createElement(Tr, { key: company.id },
                        React.createElement(Td, null,
                            React.createElement(AvatarWithLabel, { label: company.name, placeholder: getAvatarPlaceholder(company.name), theme: company.active ? "primary" : "secondary", url: applyUrlParams(ViewPath.UPDATE_COMPANY, {
                                    companyId: company.id,
                                }) })),
                        React.createElement(Td, null,
                            company.keyContactFirstName,
                            " ",
                            company.keyContactLastName),
                        React.createElement(Td, null, company.statistics.totalCount),
                        React.createElement(Td, null, company.statistics.activeCount),
                        React.createElement(Td, null, company.transactionsCount),
                        React.createElement(Td, null,
                            React.createElement(MoneyCell, null, formatMoney(company.paidAmount))),
                        React.createElement(Td, null,
                            React.createElement(AlignToCenter, null,
                                React.createElement(CompanyActions, { companyId: company.id, email: company.employer.email, isActive: company.active, isAdmin: isAdmin }))))); }))),
                totalPages > 1 && (React.createElement(PaginationWrapper, null,
                    React.createElement(Pagination, { currentPage: currentPage, onPageChange: setPage, totalPages: totalPages })))));
        })())));
};
