import React from "react";
export var useToggle = function (initialState) {
    if (initialState === void 0) { initialState = false; }
    var _a = React.useState(initialState), isOpen = _a[0], setState = _a[1];
    var open = React.useCallback(function () {
        setState(true);
    }, []);
    var close = React.useCallback(function () {
        setState(false);
    }, []);
    var toggle = React.useCallback(function () {
        setState(!isOpen);
    }, [isOpen]);
    return [isOpen, open, close, toggle];
};
