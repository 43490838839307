var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { format, getMonth, getYear } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { AvatarWithLabel } from "@/components/molecules/AvatarWithLabel";
import { Checkbox } from "@/components/molecules/Checkbox";
import { Pagination } from "@/components/molecules/Pagination";
import { SortingTrigger } from "@/components/molecules/SortingTrigger";
import { Table, Tbody, Td, Th, Thead, Tr } from "@/components/molecules/Table";
import { ConfirmModal } from "@/components/organisms/ConfirmModal";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { DEFAULT_PAGE, DEFAULT_SORT_DIR, DEFAULT_SORT_OPTION, PER_PAGE } from "@/constants/queries";
import { useUpdateTransactionsStatus } from "@/containers/mutations/UpdateTransactionsStatus";
import { useFundsQuery } from "@/containers/queries/FundsQuery";
import { useFileDownload } from "@/hooks/useFileDownload";
import { useNotifier } from "@/hooks/useNotifier";
import { useViewer } from "@/hooks/useViewer";
import { formatDate, formatErrorMessage, formatMoney, getDateLocale } from "@/utils/format";
import { getAvatarPlaceholder, getMonthDatesInRange } from "@/utils/misc";
import { FundsViewHeader } from "./FundsViewHeader";
import { ActionCol, BackgroundProvider, ErrorMessage, InfoHeader, PaginationWrapper, Plug, RequestStatus, SelectActions, Stats, StatsInfo, TableWrapper, TransactionStatus, } from "./styles";
function isFundDisabled(fund) {
    return fund.requestStatus === "declined" && fund.transactionStatus === "n/a";
}
var now = new Date();
export function FundsView() {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g;
    var t = useTranslation("FundsView").t;
    var tXHR = useTranslation("XRHResponse");
    var notify = useNotifier().notify;
    var viewer = useViewer().viewer;
    var _h = useState(DEFAULT_SORT_DIR), sortDir = _h[0], setSortDir = _h[1];
    var _j = useState(DEFAULT_SORT_OPTION), sortOption = _j[0], setSortOption = _j[1];
    var _k = useState(DEFAULT_PAGE), page = _k[0], setPage = _k[1];
    var _l = useState([]), selectedFundsIds = _l[0], setSelectedFundsIds = _l[1];
    var _m = useState(false), openReportModal = _m[0], setOpenReportModal = _m[1];
    var _o = useState(null), transactionAction = _o[0], setTransactionAction = _o[1];
    var companyId = viewer && viewer.company ? viewer.company.id : "";
    var companyName = viewer && viewer.company ? viewer.company.name : "";
    var confirmedAt = viewer ? viewer.confirmedAt : now;
    var monthDates = getMonthDatesInRange(confirmedAt, now);
    var lastMonthDate = monthDates[monthDates.length - 1] || null;
    var _p = useState(lastMonthDate), reportPeriod = _p[0], setReportPeriod = _p[1];
    var onColumnSort = useCallback(function (name, direction) {
        setSortOption(name);
        setSortDir(direction);
    }, []);
    var onChangeReportPeriod = useCallback(function (_, value) {
        setReportPeriod(value);
        setSelectedFundsIds([]);
    }, []);
    var order = useMemo(function () {
        return {
            key: sortOption,
            orderDirection: sortDir,
        };
    }, [sortDir, sortOption]);
    var selectOptions = useMemo(function () {
        return monthDates.map(function (date) { return ({
            label: format(date, "MMMM YYYY", {
                locale: getDateLocale(),
            }),
            value: date,
        }); });
    }, [monthDates]);
    var _q = useMemo(function () {
        var date = reportPeriod || now;
        return [getMonth(date) + 1, getYear(date)];
    }, [reportPeriod]), month = _q[0], year = _q[1];
    var fundsQueryVariables = useMemo(function () { return ({
        companyId: companyId,
        order: order,
        page: page,
        period: { month: month, year: year },
        perPage: PER_PAGE,
    }); }, [companyId, order, page, month, year]);
    var fundsQuery = useFundsQuery(fundsQueryVariables, { fetchPolicy: "no-cache" });
    var sorterProps = {
        activeDirection: sortDir,
        activeName: sortOption,
        onSort: onColumnSort,
    };
    var isCurrentMonth = month === now.getMonth() + 1 && year === now.getFullYear();
    var nonDisabledFunds = ((_c = (_b = (_a = fundsQuery.data) === null || _a === void 0 ? void 0 : _a.funds) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : []).filter(function (fund) {
        return !isFundDisabled(fund);
    });
    var allFundsIds = nonDisabledFunds.map(function (fund) { return fund.id; });
    var areAllSelected = allFundsIds.every(function (fundId) {
        return selectedFundsIds.includes(fundId);
    });
    var handleAllChange = useCallback(function () {
        setSelectedFundsIds(areAllSelected ? [] : allFundsIds);
    }, [areAllSelected, allFundsIds]);
    var handleChange = useCallback(function (fundId) {
        setSelectedFundsIds(selectedFundsIds.includes(fundId)
            ? selectedFundsIds.filter(function (selectedId) { return selectedId !== fundId; })
            : __spreadArrays(selectedFundsIds, [fundId]));
    }, [selectedFundsIds]);
    var onUserActionSuccess = useCallback(function () {
        if (transactionAction === null) {
            return;
        }
        var actionName;
        switch (transactionAction) {
            case "approve": {
                actionName = t("___approved");
                break;
            }
            case "decline": {
                actionName = t("___declined");
                break;
            }
            case "reverse": {
                actionName = t("___reversed");
                break;
            }
        }
        // Notify user about success.
        notify(t("transactionSuccessful", { value: actionName }));
        // Hide confirmation modal, no action to confirm.
        setTransactionAction(null);
        // Unselect all transactions selected for change.
        setSelectedFundsIds([]);
        // Fetch new list of funds to display new statuses.
        fundsQuery.refetch(fundsQueryVariables);
    }, [notify, transactionAction, fundsQuery, fundsQueryVariables, t]);
    var _r = useUpdateTransactionsStatus({
        onCompleted: onUserActionSuccess,
        onError: function () { return setTransactionAction(null); },
    }), updateTransactionsStatus = _r[0], updateTransactionsStatusMeta = _r[1];
    useEffect(function () {
        if (updateTransactionsStatusMeta.severity === "error" && updateTransactionsStatusMeta.message) {
            notify(t(updateTransactionsStatusMeta.message), { severity: "error" });
        }
    }, [updateTransactionsStatusMeta === null || updateTransactionsStatusMeta === void 0 ? void 0 : updateTransactionsStatusMeta.message, updateTransactionsStatusMeta === null || updateTransactionsStatusMeta === void 0 ? void 0 : updateTransactionsStatusMeta.severity, notify, t]);
    var handleTrasactionsStatusUpdate = useCallback(function () {
        if (transactionAction === null) {
            return;
        }
        updateTransactionsStatus({
            companyId: companyId,
            action: transactionAction,
            transactionIds: selectedFundsIds,
        });
    }, [updateTransactionsStatus, companyId, selectedFundsIds, transactionAction]);
    var toggleOpenReportModal = function () {
        setOpenReportModal(!openReportModal);
    };
    var download = useFileDownload("/reports/sepa_report?year=" + year + "&month=" + month, {
        fallbackFileName: "sepa-report-" + month + "-" + year + ".xml",
    });
    var downloadReport = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    setOpenReportModal(false);
                    return [4 /*yield*/, download()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, fundsQuery.refetch(fundsQueryVariables)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    notify(error_1.response.status === 404
                        ? t("noFundsRequestedForCycle")
                        : t("unableToDownloadReports"), { severity: "error" });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [notify, download, fundsQuery, fundsQueryVariables, t]);
    var selectedTransactionsTotal = useMemo(function () {
        return nonDisabledFunds
            .filter(function (fund) { return selectedFundsIds.includes(fund.id); })
            .reduce(function (sum, fund) { return sum + parseFloat(fund.amount); }, 0);
    }, [selectedFundsIds, nonDisabledFunds]);
    var actionModalMessage = useMemo(function () {
        switch (transactionAction) {
            case "approve": {
                return t("actionModalMessageApprove");
            }
            case "decline": {
                return t("actionModalMessageDecline");
            }
            case "reverse": {
                return t("actionModalMessageReverse");
            }
        }
        return "";
    }, [transactionAction, t]);
    return (React.createElement(ProtectedView, { pageTitle: t("pageTitle"), theme: "darken" },
        React.createElement(BackgroundProvider, null,
            React.createElement(InnerContainer, null,
                React.createElement(FundsViewHeader, { companyName: companyName, openReportModal: toggleOpenReportModal, reportPeriod: reportPeriod, onChangeReportPeriod: onChangeReportPeriod, selectOptions: selectOptions, totalCompleted: (_g = (_f = (_e = (_d = fundsQuery.data) === null || _d === void 0 ? void 0 : _d.funds) === null || _e === void 0 ? void 0 : _e.stats) === null || _f === void 0 ? void 0 : _f.totalCompleted) !== null && _g !== void 0 ? _g : 0, isCurrentMonth: isCurrentMonth }))),
        React.createElement(InnerContainer, null,
            transactionAction !== null && (React.createElement(ConfirmModal, { onCancel: function () { return setTransactionAction(null); }, onConfirm: handleTrasactionsStatusUpdate, confirmButtonText: t("confirm") }, actionModalMessage)),
            openReportModal && (React.createElement(ConfirmModal, { onCancel: toggleOpenReportModal, onConfirm: downloadReport, confirmButtonText: t("confirm") }, t("areYouSureToSendBankTransfer"))),
            React.createElement(TableWrapper, null, (function () {
                if (fundsQuery.error) {
                    return (React.createElement(ErrorMessage, null, tXHR.t(formatErrorMessage(fundsQuery.error))));
                }
                if (!fundsQuery.data ||
                    !fundsQuery.data.funds ||
                    fundsQuery.data.funds.data.length === 0) {
                    return React.createElement(Plug, null, fundsQuery.loading ? t("loading") : t("empty"));
                }
                var _a = fundsQuery.data.funds, currentPage = _a.currentPage, totalCount = _a.totalCount, perPage = _a.perPage, funds = _a.data;
                var totalPages = Math.ceil(totalCount / perPage);
                var totalApproved = fundsQuery.data.funds.stats.totalApproved;
                var columnsNumber = [6, 20, 12, 22, 10, 15, 15];
                return (React.createElement(React.Fragment, null,
                    React.createElement(InfoHeader, null, t("select")),
                    React.createElement(Table, { cols: columnsNumber },
                        React.createElement(Thead, null,
                            React.createElement(Th, null,
                                React.createElement(Checkbox, { id: "isSelected", name: "isSelected", onChange: handleAllChange, value: areAllSelected })),
                            React.createElement(Th, null,
                                React.createElement(SortingTrigger, __assign({}, sorterProps, { name: "employeeName" }), t("employeeName"))),
                            React.createElement(Th, null,
                                React.createElement(SortingTrigger, __assign({}, sorterProps, { name: "createdAt" }), t("requestDate"))),
                            React.createElement(Th, null,
                                React.createElement(SortingTrigger, __assign({}, sorterProps, { name: "iban" }), t("iban"))),
                            React.createElement(Th, null,
                                React.createElement(SortingTrigger, __assign({}, sorterProps, { name: "amount" }), t("requestAmount"))),
                            React.createElement(Th, null,
                                React.createElement(SortingTrigger, __assign({}, sorterProps, { name: "requestStatus" }), t("requestStatus"))),
                            React.createElement(Th, null,
                                React.createElement(SortingTrigger, __assign({}, sorterProps, { name: "transactionStatus" }), t("transactionStatus")))),
                        React.createElement(Tbody, null, funds.map(function (fund) { return (React.createElement(Tr, { key: fund.id },
                            React.createElement(Td, null,
                                React.createElement(Checkbox, { id: fund.id, disabled: isFundDisabled(fund), name: fund.id, value: selectedFundsIds.includes(fund.id), onChange: function () { return handleChange(fund.id); } })),
                            React.createElement(Td, null,
                                React.createElement(AvatarWithLabel, { label: fund.employeeFirstName + " " + fund.employeeLastName, placeholder: getAvatarPlaceholder(fund.employeeFirstName, fund.employeeLastName), theme: "primary", url: null })),
                            React.createElement(Td, null, formatDate(fund.createdAt)),
                            React.createElement(Td, null, fund.iban),
                            React.createElement(Td, null, formatMoney(fund.amount)),
                            React.createElement(Td, null,
                                React.createElement(RequestStatus, { status: fund.requestStatus }, t(fund.requestStatus))),
                            React.createElement(Td, null,
                                React.createElement(TransactionStatus, { status: fund.transactionStatus }, t(fund.transactionStatus))))); }))),
                    selectedFundsIds.length > 0 && (React.createElement(SelectActions, null,
                        isCurrentMonth && (React.createElement(React.Fragment, null,
                            React.createElement(Stats, null,
                                React.createElement(StatsInfo, null,
                                    t("totalSelected"),
                                    ": ",
                                    formatMoney(selectedTransactionsTotal)),
                                React.createElement(StatsInfo, null,
                                    t("totalApproved"),
                                    ": ",
                                    formatMoney(totalApproved))),
                            React.createElement(ActionCol, { size: 5, direction: "y" },
                                React.createElement("span", null,
                                    t("selected"),
                                    ": ",
                                    selectedFundsIds.length),
                                React.createElement(Button, { theme: "pink", onClick: function () { return setTransactionAction("decline"); }, type: "button", size: "small", width: 120 }, t("__decline")),
                                React.createElement(Button, { theme: "primary", onClick: function () { return setTransactionAction("approve"); }, type: "button", size: "small", width: 120 }, t("__approve"))))),
                        React.createElement(ActionCol, { size: 5, direction: "y" },
                            React.createElement("span", null,
                                t("selected"),
                                ": ",
                                selectedFundsIds.length),
                            React.createElement(Button, { theme: "dark", onClick: function () { return setTransactionAction("reverse"); }, type: "button", size: "small", width: 120 }, t("__reverse"))))),
                    totalPages > 1 && (React.createElement(PaginationWrapper, null,
                        React.createElement(Pagination, { currentPage: currentPage, onPageChange: setPage, totalPages: totalPages })))));
            })()))));
}
