import styled from "@emotion/styled";
import { boxFrame, colors, fontWeight } from "@/styleguide";
export var BackgroundProvider = styled.div({
    backgroundColor: colors.white,
});
export var Header = styled.header({
    display: "flex",
    justifyContent: "flex-end",
    padding: boxFrame([30, 0]),
});
export var Empty = styled.p({
    color: colors.dustyGrey,
    lineHeight: 1.5,
    margin: "30px auto",
    textAlign: "center",
});
export var TableWrapper = styled.div({
    marginTop: 30,
});
export var AlignToCenter = styled.div({
    textAlign: "center",
});
export var MoneyCell = styled.div({
    color: colors.shipGrey,
    fontWeight: fontWeight.semiBold,
});
export var PaginationWrapper = styled.div({
    marginTop: 30,
});
