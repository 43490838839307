import React from "react";
import { useToggle } from "./useToggle";
export var useDropdown = function (ref) {
    var _a = useToggle(), isOpen = _a[0], openDropdown = _a[1], closeDropdown = _a[2], toggleDropdown = _a[3];
    var onClick = React.useCallback(function (evt) {
        var node = ref.current;
        // @ts-ignore
        // EventTarget is not assignable to Node
        if (node && node.contains(evt.target)) {
            return;
        }
        closeDropdown();
    }, [closeDropdown, ref]);
    React.useEffect(function () {
        document.addEventListener("click", onClick);
        return function () {
            document.removeEventListener("click", onClick);
        };
    }, [onClick]);
    return [isOpen, openDropdown, closeDropdown, toggleDropdown];
};
