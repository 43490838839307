var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { createPortal } from "react-dom";
import { Icon } from "@/components/atoms/Icon";
import { ModalClose, ModalContent, ModalHeader, ModalTitle, ModalWrapper, Overlay } from "./styles";
import { sizes } from "./themes";
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal(props) {
        var _this = _super.call(this, props) || this;
        _this.stopPropagation = function (evt) {
            evt.stopPropagation();
        };
        _this.modalRoot = document.getElementById("modal-root");
        return _this;
    }
    Modal.prototype.render = function () {
        if (this.modalRoot === null) {
            return null;
        }
        var sizeStyle = sizes[this.props.size];
        return createPortal(React.createElement(React.Fragment, null,
            React.createElement(Overlay, null),
            React.createElement(ModalWrapper, { closable: this.props.closable, onClick: this.props.onModalClose },
                React.createElement(ModalContent, { onClick: this.stopPropagation, theme: sizeStyle },
                    this.props.title && (React.createElement(ModalHeader, null,
                        React.createElement(ModalTitle, null, this.props.title),
                        this.props.closable && (React.createElement(ModalClose, { onClick: this.props.onModalClose },
                            React.createElement(Icon, { name: "cross", size: 16 }))))),
                    this.props.children))), this.modalRoot);
    };
    Modal.defaultProps = {
        closable: true,
    };
    return Modal;
}(React.Component));
export { Modal };
