import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { CompaniesReportsView } from "@/components/views/CompaniesReportsView";
import { CompaniesView } from "@/components/views/CompaniesView";
import { CreateCompanyView } from "@/components/views/CreateCompanyView";
import { EmployeesView } from "@/components/views/EmployeesView";
import { ForgotPasswordView } from "@/components/views/ForgotPasswordView";
import { UpdateCompanyView } from "@/components/views/UpdateCompanyView";
import { UserSettingsView } from "@/components/views/UserSettingsView";
import { ViewPath } from "@/constants/routing";
import { transformUrlToRoute } from "@/utils/routing";
export var AdminRouter = function () {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: ViewPath.APP_ROOT },
            React.createElement(Switch, { key: "appRoutes" },
                React.createElement(Route, { component: UpdateCompanyView, exact: true, path: transformUrlToRoute(ViewPath.UPDATE_COMPANY) }),
                React.createElement(Route, { component: EmployeesView, exact: true, path: transformUrlToRoute(ViewPath.COMPANY_EMPLOYEES) }),
                React.createElement(Route, { component: UserSettingsView, exact: true, path: ViewPath.USER_SETTINGS }),
                React.createElement(Route, { component: ForgotPasswordView, exact: true, path: ViewPath.RESET_PASSWORD }),
                React.createElement(Route, { component: CreateCompanyView, exact: true, path: ViewPath.NEW_COMPANY }),
                React.createElement(Route, { component: CompaniesView, exact: true, path: ViewPath.COMPANIES }),
                React.createElement(Route, { component: CompaniesReportsView, exact: true, path: ViewPath.REPORTS }))),
        React.createElement(Redirect, { to: ViewPath.COMPANIES })));
};
