import styled from "@emotion/styled";
import { fontSize, rem, colors, fontWeight } from "@/styleguide";
export var AlignToCenter = styled.div({
    alignItems: "center",
    display: "flex",
});
export var SwitchLabel = styled.span({
    fontSize: fontSize.regular,
    marginRight: 25,
});
export var IdNumber = styled.span({
    color: colors.grey,
    fontSize: rem(fontSize.regular),
    fontWeight: fontWeight.semiBold,
});
