var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Logo } from "@/components/atoms/Logo";
import { AbstractView } from "@/components/templates/AbstractView";
import { ViewPath } from "@/constants/routing";
import { ContentColumn, ContentWrapper, InnerContainer, OuterContainer, Header, LogoLink, } from "./styles";
var PublicView = /** @class */ (function (_super) {
    __extends(PublicView, _super);
    function PublicView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PublicView.prototype.render = function () {
        return (React.createElement(OuterContainer, null,
            React.createElement(InnerContainer, null,
                React.createElement(ContentColumn, null,
                    React.createElement(LogoLink, { to: ViewPath.LOGIN, title: "Go to login page" },
                        React.createElement(Logo, { theme: "login" })),
                    React.createElement(ContentWrapper, null,
                        this.props.viewTitle && React.createElement(Header, null, this.props.viewTitle),
                        this.props.children)))));
    };
    return PublicView;
}(AbstractView));
export { PublicView };
