import styled from "@emotion/styled";
import { css } from "emotion";
import { fontSize, rem, fontWeight } from "@/styleguide";
export var Email = styled.span({
    fontSize: rem(fontSize.small),
    fontWeight: fontWeight.medium,
});
export var formClassName = css({
    marginTop: 25,
});
