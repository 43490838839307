import styled from "@emotion/styled";
import { colors, boxFrame, rem, fontSize, fontWeight } from "@/styleguide";
export var Card = styled.div({
    alignSelf: "stretch",
    borderRadius: "0 0 12px 12px",
    borderTop: "5px solid " + colors.java,
    boxShadow: "0 0 10px 0 " + colors.alto,
    display: "flex",
    flexDirection: "column",
    padding: boxFrame([35, 40]),
});
export var Heading = styled.h3({
    alignItems: "center",
    color: colors.shipGrey,
    display: "flex",
    fontSize: rem(fontSize.medium),
    fontWeight: fontWeight.semiBold,
    margin: 0,
});
export var Subheading = styled.p({
    color: colors.grey,
    fontSize: rem(fontSize.small),
    margin: boxFrame([10, 0, 0]),
});
