import styled from "@emotion/styled";
import { colors, fontSize, fontWeight, rem, boxFrame } from "@/styleguide";
export var ContentWrapper = styled.div({
    marginTop: 7,
    maxWidth: "100%",
    width: 440,
});
export var Paragraph = styled.p({
    color: colors.grey,
    fontSize: rem(fontSize.regular),
    fontWeight: fontWeight.regular,
    margin: boxFrame([22, 0, 28]),
});
