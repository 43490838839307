import styled from "@emotion/styled";
import { colors, fontSize, fontWeight, rem } from "@/styleguide";
export var BackgroundProvider = styled.div({
    backgroundColor: colors.white,
    padding: "40px 0",
});
export var SwitchWrapper = styled.div({
    display: "flex",
    justifyContent: "center",
});
export var StatsPanel = styled.div({
    alignItems: "stretch",
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
});
export var StatsCards = styled.div({
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
    width: 320,
    "> * + *": {
        marginTop: 20,
    },
});
export var SelectWrapper = styled.div({
    width: 240,
});
export var ContentHeaderWrapper = styled.div({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
});
export var ContentHeader = styled.header({
    color: colors.armadillo,
    fontSize: rem(fontSize.regular),
    fontWeight: fontWeight.semiBold,
    padding: "30px 10px 30px 0",
});
export var Plug = styled.p({
    color: colors.dustyGrey,
    lineHeight: 1.5,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
});
export var ReportPanelWrapper = styled.div({
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
});
