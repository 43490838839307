var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from "axios";
import noop from "lodash/noop";
import React from "react";
import { getAuthContext } from "@/utils/auth";
export var useFileDownload = function (downloadUrl, _a) {
    var fallbackFileName = _a.fallbackFileName;
    return React.useCallback(function () {
        return axios
            .get("" + process.env.REST_API_URL + downloadUrl, __assign(__assign({}, getAuthContext()), { responseType: "blob" }))
            .then(function (response) {
            var url = window.URL.createObjectURL(new Blob([response.data]));
            var link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", response.headers["x-filename"] || fallbackFileName);
            document.body.appendChild(link);
            link.click();
            var parentNode = link.parentNode;
            if (parentNode) {
                parentNode.removeChild(link);
            }
        })
            .catch(noop);
    }, [downloadUrl, fallbackFileName]);
};
