var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Icon } from "@/components/atoms/Icon";
import { colors } from "@/styleguide";
import { Input, StyledLabel, StyledRoot, Text } from "./styles";
import { sizes } from "./themes";
export function Checkbox(_a) {
    var className = _a.className, description = _a.description, _b = _a.size, size = _b === void 0 ? "regular" : _b, value = _a.value, props = __rest(_a, ["className", "description", "size", "value"]);
    var theme = sizes[size];
    return (React.createElement(StyledRoot, { className: className },
        React.createElement(StyledLabel, { isChecked: value, isDisabled: !!props.disabled, htmlFor: props.id, theme: theme },
            React.createElement(Input, __assign({}, props, { checked: value, id: props.id, type: "checkbox" })),
            value && React.createElement(Icon, { color: colors.white, name: "checkRounded", size: theme.iconSize })),
        description && React.createElement(Text, null, description)));
}
