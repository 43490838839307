var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { parse as parseQueryString } from "query-string";
import { format, parse } from "url";
var POSSIBLE_PARAMS_IN_URL_REGEX = /[{}]/;
// Let's have 'url' defined like '/entity//345/edit'.
// If we call 'normalizeUrl' on this path we will get: '/entity/345/edit'.
export var normalizeUrl = function (url) {
    return url.replace(/\/{2,}/g, "/");
};
// Let's have 'rawUrl' defined like '/entity/{rootEntity:string}/{entityId:number}/edit'.
// If we call 'applyUrlParams' on this path with 'params' { rootEntity: "xyz", entityId: 345 }
// we will get: '/entity/xyz/345/edit'.
export var applyUrlParams = function (rawUrl, params) {
    var paramsKeys = Object.keys(params);
    var url = rawUrl;
    paramsKeys.forEach(function (paramKey) {
        var regex = new RegExp("\\{" + paramKey + "(\\:(string|number|uuid))?\\}");
        url = url.replace(regex, params[paramKey]);
    });
    return url;
};
export var applyUrlQuery = function (rawUrl, addQuery, mergeQuery) {
    if (mergeQuery === void 0) { mergeQuery = true; }
    var parsedUrl = parse(rawUrl, true);
    var query = mergeQuery ? __assign(__assign({}, parsedUrl.query), addQuery) : addQuery;
    var formatObject = __assign(__assign({}, parsedUrl), { query: query, search: undefined });
    return format(formatObject);
};
// Let's have 'path' defined like '/entity/{rootEntity:string}/{entityId:number}/edit'.
// If we call 'applyUrlParams' on this path with 'params' { rootEntity: "xyz" }
// we will get 'url' equal to '/entity/xyz/{entityId:number}/edit'.
// Then if we call 'someUrlParamsNotApplied' on returned 'url', this function will return TRUE.
//
// Let's have 'path' defined like '/entity/{rootEntity:string}/{entityId:number}/edit'.
// If we call 'applyUrlParams' on this path with 'params' { rootEntity: "xyz", entityId: 345 }
// we will get: '/entity/xyz/345/edit'.
// Then if we call 'someUrlParamsNotApplied' on returned 'url', this function will return FALSE.
export var someUrlParamsNotApplied = function (url) {
    return POSSIBLE_PARAMS_IN_URL_REGEX.test(url);
};
// Opposite of 'someUrlParamsNotApplied'.
export var allUrlParamsApplied = function (url) {
    return !someUrlParamsNotApplied(url);
};
// Let's have a path defined like "/entity/{rootEntity:string}/{entityId:number}/edit".
// If we apply 'transformUrlToRoute' to this path we will get:
// "/entity/:rootEntity/:entityId(\\d+)/edit".
export var transformUrlToRoute = function (url, skipNames) {
    if (skipNames === void 0) { skipNames = false; }
    var transformedUrl = url.replace(/\{/g, ":");
    if (skipNames) {
        transformedUrl = transformedUrl.replace(/:[a-z]+:/gi, ":");
    }
    return transformedUrl
        .replace(/:string\}/g, "(.+)")
        .replace(/:number\}/g, "(\\d+)")
        .replace(/:uuid\}/g, "([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})");
};
export var extractQueryParams = function (queryString) {
    return parseQueryString(queryString);
};
