var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fontSize, colors } from "@/styleguide";
export var themes = {
    primary: {
        backgroundColor: colors.java,
        backgroundColorActive: colors.turquoise,
        borderColor: "transparent",
        color: colors.white,
    },
    secondary: {
        backgroundColor: "transparent",
        backgroundColorActive: colors.alabaster,
        borderColor: colors.java,
        color: colors.java,
    },
    dark: {
        backgroundColor: colors.alabaster,
        backgroundColorActive: colors.alabaster,
        borderColor: colors.alto,
        color: colors.shipGrey,
    },
    red: {
        backgroundColor: "transparent",
        backgroundColorActive: colors.alabaster,
        borderColor: colors.sweetPink,
        color: colors.red,
    },
    pink: {
        backgroundColor: "transparent",
        backgroundColorActive: "transparent",
        borderColor: colors.sweetPink,
        color: colors.sweetPink,
    },
};
export var sizes = {
    medium: {
        borderRadius: 6,
        fontSize: fontSize.small,
        height: 45,
        padding: [0, 24],
    },
    regular: {
        borderRadius: 6,
        fontSize: fontSize.small,
        height: 40,
        padding: [0, 24],
    },
    small: {
        borderRadius: 6,
        fontSize: fontSize.tiny,
        height: 30,
        padding: [0, 12],
    },
};
export var getTheme = function (theme, size) {
    var themeStyles = themes[theme];
    var sizeStyles = sizes[size];
    return __assign(__assign({}, themeStyles), sizeStyles);
};
