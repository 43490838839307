import styled from "@emotion/styled";
import { fontSize, colors } from "@/styleguide";
export var Paragraph = styled.p({
    color: colors.grey,
    fontSize: fontSize.small,
    lineHeight: 1.5,
    margin: "30px 0",
    textAlign: "center",
});
export var Buttons = styled.div({
    display: "flex",
    justifyContent: "space-between",
    margin: "0 auto",
    width: 380,
});
