var FIRST_IDX = 1;
var MARGIN_SIZE = 2;
var createPaginationItem = function (element) {
    var id = Math.random().toString();
    return { element: element, id: id };
};
export var cretePaginationItems = function (currentPage, totalPages) {
    var LAST_IDX = totalPages;
    var paginationItems = [];
    /**
     * This formula: 4 * MARGIN_SIZE + 1
     * represent maximum number of pages when it's guaranteed that pagination
     * won't include separator, so we can just return pagination items
     * from 1 to 'totalPage'
     */
    if (totalPages <= 4 * MARGIN_SIZE + 1) {
        for (var element = FIRST_IDX; element <= totalPages; element += 1) {
            paginationItems.push(createPaginationItem(element));
        }
        return paginationItems;
    }
    for (var element = FIRST_IDX; element <= MARGIN_SIZE; element += 1) {
        paginationItems.push(createPaginationItem(element));
    }
    var leftMarginSize = Math.min(MARGIN_SIZE, currentPage - FIRST_IDX);
    var leftEdge = Math.max(FIRST_IDX, currentPage - leftMarginSize);
    var rightMarginSize = Math.min(MARGIN_SIZE, LAST_IDX - currentPage);
    var rightEdge = Math.min(LAST_IDX, currentPage + rightMarginSize);
    leftMarginSize = MARGIN_SIZE - (currentPage - leftEdge);
    rightMarginSize = MARGIN_SIZE - (rightEdge - currentPage);
    leftEdge -= rightMarginSize;
    rightEdge += leftMarginSize;
    leftEdge = Math.max(FIRST_IDX + MARGIN_SIZE, leftEdge);
    rightEdge = Math.min(LAST_IDX - MARGIN_SIZE, rightEdge);
    if (leftEdge > MARGIN_SIZE + 1) {
        paginationItems.push(createPaginationItem("separator"));
    }
    for (var element = leftEdge; element <= rightEdge; element += 1) {
        paginationItems.push(createPaginationItem(element));
    }
    if (rightEdge < LAST_IDX - MARGIN_SIZE) {
        paginationItems.push(createPaginationItem("separator"));
    }
    for (var element = LAST_IDX - (MARGIN_SIZE - 1); element <= LAST_IDX; element += 1) {
        paginationItems.push(createPaginationItem(element));
    }
    return paginationItems;
};
