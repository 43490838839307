import React from "react";
import { useTranslation } from 'react-i18next';
import { Bars, YLabelText, YLines, XLabelText } from "./styles";
import { chartCalculator } from "./utils";
export var Chart = function (props) {
    var t = useTranslation('Chart').t;
    var calculator = React.useMemo(function () {
        return chartCalculator({
            height: props.height,
            width: props.width,
        });
    }, [props.width, props.height]);
    var _a = React.useMemo(function () {
        return {
            barsLabels: calculator.getBarsLabels(props.series),
            barsPath: calculator.getBarsPath(props.series, props.max),
            yLabels: calculator.getYLinesLabels(props.max),
            yLinesPath: calculator.getYLinesPath(),
        };
    }, [calculator, props.max, props.series]), barsLabels = _a.barsLabels, barsPath = _a.barsPath, yLabels = _a.yLabels, yLinesPath = _a.yLinesPath;
    return (React.createElement("svg", { width: props.width, viewBox: "0 0 " + props.width + " " + props.height },
        yLabels.map(function (label) { return (React.createElement(YLabelText, { key: label.key, x: label.x, y: label.y }, label.text)); }),
        React.createElement(YLines, { d: yLinesPath }),
        React.createElement(Bars, { d: barsPath }),
        barsLabels.map(function (label) { return (React.createElement(XLabelText, { key: label.key, x: label.x, y: label.y }, t(label.text))); })));
};
