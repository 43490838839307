import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { CreateEmployeeView } from "@/components/views/CreateEmployeeView";
import { EmployeesReportsView } from "@/components/views/EmployeesReportsView";
import { EmployeesUploadReviewView } from "@/components/views/EmployeesUploadReviewView";
import { EmployeesView } from "@/components/views/EmployeesView";
import { FundsView } from "@/components/views/FundsView";
import { UpdateEmployeeView } from "@/components/views/UpdateEmployeeView";
import { UserSettingsView } from "@/components/views/UserSettingsView";
import { WithdrawalSettingsView } from "@/components/views/WithdrawalSettingsView";
import { ViewPath } from "@/constants/routing";
import { transformUrlToRoute } from "@/utils/routing";
export var EmployerRouter = function () {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: ViewPath.APP_ROOT },
            React.createElement(Switch, { key: "appRoutes" },
                React.createElement(Route, { component: WithdrawalSettingsView, exact: true, path: ViewPath.WITHDRAWAL_SETTINGS }),
                React.createElement(Route, { component: UpdateEmployeeView, exact: true, path: transformUrlToRoute(ViewPath.UPDATE_EMPLOYEE) }),
                React.createElement(Route, { component: EmployeesUploadReviewView, exact: true, path: ViewPath.EMPLOPYEES_REVIEW_UPLOAD }),
                React.createElement(Route, { component: CreateEmployeeView, exact: true, path: ViewPath.NEW_EMPLOYEE }),
                React.createElement(Route, { component: UserSettingsView, exact: true, path: ViewPath.USER_SETTINGS }),
                React.createElement(Route, { component: EmployeesView, exact: true, path: ViewPath.EMPLOYEES }),
                React.createElement(Route, { component: EmployeesReportsView, exact: true, path: ViewPath.REPORTS }),
                React.createElement(Route, { component: FundsView, exact: true, path: ViewPath.FUNDS }))),
        React.createElement(Redirect, { to: ViewPath.EMPLOYEES })));
};
