import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { colors, fontSize, fontWeight, rem } from "@/styleguide";
export var BackgroundProvider = styled.div({
    backgroundColor: colors.white,
    padding: "40px 0",
});
export var SwitchWrapper = styled.div({
    display: "flex",
    justifyContent: "flex-start",
    width: "calc(50% + 165px)",
});
export var StatsPanel = styled.div({
    alignItems: "stretch",
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
});
export var StatsCards = styled.div({
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
    width: 320,
    "> * + *": {
        marginTop: 20,
    },
});
export var SelectWrapper = styled.div({
    width: 240,
});
export var ContentHeader = styled.header({
    color: colors.armadillo,
    fontSize: rem(fontSize.regular),
    fontWeight: fontWeight.semiBold,
    padding: "30px 10px 30px 0",
});
export var ContentHeaderWrapper = styled.div({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
});
export var Plug = styled.p({
    color: colors.dustyGrey,
    lineHeight: 1.5,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
});
export var Header = styled.div({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
});
export var BackLinkWrapper = styled.div({
    alignItems: "center",
    display: "flex",
});
export var HeaderLink = styled(Link)({
    lineHeight: 0,
    marginRight: 20,
});
export var CompanyNameWrapper = styled.span({
    fontWeight: fontWeight.semiBold,
});
