var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useTranslation } from "react-i18next";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { SimpleHeader } from "@/components/molecules/SimpleHeader";
import { EmployeeForm } from "@/components/organisms/EmployeeForm";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { DEFAULT_SORT_DIR, DEFAULT_PAGE, PER_PAGE, DEFAULT_SEARCH_TEXT, DEFAULT_SORT_OPTION, } from "@/constants/queries";
import { ViewPath } from "@/constants/routing";
import { useCreateEmployee } from "@/containers/mutations/CreateEmployee";
import { refetchCompanyEmployeesQuery } from "@/containers/queries/CompanyEmployeesQuery";
import { useCountriesQuery } from "@/containers/queries/CountriesQuery";
import { refetchEmployeesStatsQuery } from "@/containers/queries/EmployeesStatsQuery";
import { useNotifier } from "@/hooks/useNotifier";
import { formatErrorMessage } from "@/utils/format";
export function CreateEmployeeView(props) {
    var t = useTranslation("CreateEmployeeView").t;
    var notify = useNotifier().notify;
    var countriesQuery = useCountriesQuery({});
    var onCompleted = React.useCallback(function () {
        notify(t("notifyEmployeeAdded"), { severity: "success" });
        props.history.push(ViewPath.EMPLOYEES);
    }, [props.history, notify, t]);
    var _a = useCreateEmployee({
        onCompleted: onCompleted,
        refetchQueries: function () { return [
            refetchEmployeesStatsQuery({}),
            refetchCompanyEmployeesQuery({
                order: {
                    key: DEFAULT_SORT_OPTION,
                    orderDirection: DEFAULT_SORT_DIR,
                },
                page: DEFAULT_PAGE,
                perPage: PER_PAGE,
                search: DEFAULT_SEARCH_TEXT,
            }),
        ]; },
    }), createEmployee = _a[0], createEmployeeMeta = _a[1];
    var onCreateEmployee = React.useCallback(function (values) {
        createEmployee(__assign(__assign({}, values), { countryId: String(values.countryId), grossSalary: parseInt(values.grossSalary, 10), lineOne: values.address, partTime: parseFloat(values.partTime), postCode: values.postalCode, provinceCode: values.provinceCode }));
    }, [createEmployee]);
    var countries = countriesQuery.data ? countriesQuery.data.countries : [];
    return (React.createElement(ProtectedView, { pageTitle: t("addNewEmployee") },
        React.createElement(InnerContainer, null,
            React.createElement(SimpleHeader, { title: t("newEmployee") }),
            countriesQuery.loading ? (t("loading")) : countriesQuery.error ? (formatErrorMessage(countriesQuery.error)) : (React.createElement(EmployeeForm, { countries: countries, onSubmit: onCreateEmployee, statusMessage: createEmployeeMeta.message, statusSeverity: createEmployeeMeta.severity, submitting: createEmployeeMeta.loading })))));
}
