import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { boxFrame, fontSize, rem, colors, fontWeight } from "@/styleguide";
var shouldForwardProp = function (prop) {
    return prop !== "isActive";
};
export var StyledLink = styled(Link, { shouldForwardProp: shouldForwardProp })({
    alignItems: "center",
    borderStyle: "solid",
    borderTopColor: "transparent",
    borderWidth: "5px 0",
    display: "flex",
    fontSize: rem(fontSize.regular),
    padding: boxFrame([0, 30]),
    textDecoration: "none",
}, function (props) { return ({
    borderBottomColor: props.isActive ? colors.java : colors.white,
    color: props.isActive ? colors.shipGrey : colors.grey,
    fontWeight: props.isActive ? fontWeight.semiBold : fontWeight.medium,
}); });
