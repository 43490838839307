import { colors, fontWeight } from "@/styleguide";
export var themes = {
    error: {
        color: colors.red,
        fontWeight: fontWeight.semiBold,
    },
    primary: {
        color: colors.shipGrey,
        fontWeight: fontWeight.semiBold,
    },
    secondary: {
        color: colors.grey,
        fontWeight: fontWeight.semiBold,
    },
    inactive: {
        color: colors.shipGrey,
        fontWeight: fontWeight.regular,
    },
};
