import styled from "@emotion/styled";
import { StatCard } from "@/components/molecules/StatCard";
import { boxFrame } from "@/styleguide";
export var Header = styled.header({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: boxFrame([30, 0]),
}, function (props) { return ({
    justifyContent: props.isAdmin ? "space-evenly" : "space-between",
}); });
export var StyledStatCard = styled(StatCard)({
    width: "calc(50% - 180px)",
});
