import React from "react";
import { useTranslation } from "react-i18next";
import { InfoBox } from "@/components/atoms/InfoBox";
import { StyledRoot } from "./styles";
export var CompanyDetails = function (props) {
    var t = useTranslation("Molecules.CompanyDetails").t;
    var sectorsT = useTranslation("Sectors");
    return (React.createElement(StyledRoot, { className: props.className },
        React.createElement(InfoBox, { content: props.name, title: t("companyName") }),
        React.createElement(InfoBox, { content: sectorsT.t(props.sector || "") || t("na"), title: t("industry") })));
};
