var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { startOfMonth, isWithinRange, addMonths } from "date-fns";
export var createSelectOptionsFromArray = function (arrayOfItems, valueKey, labelKey) {
    return arrayOfItems.map(function (arrayItem) {
        var optionLabel = arrayItem[labelKey];
        var optionValue = arrayItem[valueKey];
        return {
            label: optionLabel,
            value: optionValue,
        };
    });
};
export var getAvatarPlaceholder = function (arg0) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    var chunks = [];
    for (var _a = 0, _b = __spreadArrays([arg0], args); _a < _b.length; _a++) {
        var arg = _b[_a];
        chunks.push(arg.trim().charAt(0));
    }
    return chunks
        .filter(Boolean)
        .map(function (chunk) { return chunk.toUpperCase(); })
        .join("");
};
export var getMonthDatesInRange = function (startDate, endDate) {
    if (endDate === void 0) { endDate = new Date(); }
    var monthDates = [];
    var startDateReset = startOfMonth(startDate);
    var endDateReset = startOfMonth(endDate);
    var incrementalDate = startDateReset;
    try {
        while (isWithinRange(incrementalDate, startDateReset, endDateReset)) {
            monthDates.push(incrementalDate.toISOString());
            incrementalDate = addMonths(incrementalDate, 1);
        }
    }
    catch (_a) {
        // Ignore errors.
    }
    return monthDates;
};
