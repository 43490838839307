var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import qs from "query-string";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AvatarWithLabel } from "@/components/molecules/AvatarWithLabel";
import { FormMessage } from "@/components/molecules/FormMessage";
import { PublicView } from "@/components/templates/PublicView";
import { useResetPasswordWithToken } from "@/containers/mutations/ResetPasswordWithToken";
import { useCheckTokenQuery } from "@/containers/queries/CheckTokenQuery";
import { useViewer } from "@/hooks/useViewer";
import { formatErrorMessage } from "@/utils/format";
import { getAvatarPlaceholder } from "@/utils/misc";
import { SetPasswordForm } from "./SetPasswordForm";
import { FormWrapper, Paragraph, AvatarWrapper } from "./styles";
export function SetPasswordView(props) {
    var _a;
    var t = useTranslation("SetPasswordView").t;
    var onLogIn = useViewer().onLogIn;
    var token = useMemo(function () {
        var search = props.location.search;
        var queryParams = qs.parse(search);
        if (typeof queryParams.token === "string") {
            return queryParams.token;
        }
        return null;
    }, [props.location]);
    var query = useCheckTokenQuery({ token: token || "" });
    var onCompleted = useCallback(function (data) {
        if (data.authResult.viewer.role === "employee") {
            return;
        }
        onLogIn(data.authResult.token, data.authResult.viewer);
    }, [onLogIn]);
    var _b = useResetPasswordWithToken({
        onCompleted: onCompleted,
        successMessage: t("yourPasswordHasBeenSet"),
    }), resetPassword = _b[0], resetPasswordMeta = _b[1];
    var handleSubmit = useCallback(function (values) {
        if (token === null) {
            return;
        }
        resetPassword(__assign(__assign({}, values), { token: token }));
    }, [resetPassword, token]);
    if (token === null) {
        return null;
    }
    var userName = (_a = query.data) === null || _a === void 0 ? void 0 : _a.user.firstName;
    var viewTitle = userName ? t("viewTitle", { value: userName }) : null;
    return (React.createElement(PublicView, { pageTitle: t("pageTitle"), viewTitle: viewTitle }, (function () {
        if (query.loading) {
            return t("validatingToken");
        }
        if (query.error || !query.data) {
            return (React.createElement(FormMessage, { type: "error" }, formatErrorMessage(query.error, t("unexpectedError"))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(AvatarWrapper, null,
                React.createElement(AvatarWithLabel, { label: query.data.user.email, placeholder: getAvatarPlaceholder(query.data.user.firstName, query.data.user.lastName), theme: "primary" })),
            React.createElement(Paragraph, null, t("setUpYourPassword")),
            React.createElement(FormWrapper, null,
                React.createElement(SetPasswordForm, { formMessage: resetPasswordMeta.message, formStatus: resetPasswordMeta.severity, loading: resetPasswordMeta.loading, onSubmit: handleSubmit }))));
    })()));
}
