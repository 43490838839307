var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Checkbox } from "../../Checkbox";
import { CheckboxCell, StyledRoot, Wrapper } from "./styles";
export var Tr = function (_a) {
    var onSelect = _a.onSelect, _b = _a.selected, selected = _b === void 0 ? false : _b, props = __rest(_a, ["onSelect", "selected"]);
    var onRowSelect = React.useCallback(function (evt) {
        var name = evt.target.name;
        var _a = name.split("--"), key = _a[1];
        if (onSelect) {
            onSelect(key);
        }
    }, [onSelect]);
    var cols = props.cols || [];
    var hasCheckbox = typeof onSelect === "function";
    if (hasCheckbox && !props.rowId) {
        throw new Error("rowId has to be specified is table row is selectable");
    }
    var id = "tableRow--" + props.rowId;
    return (React.createElement(StyledRoot, null,
        hasCheckbox && (React.createElement(CheckboxCell, null, props.selectable && (React.createElement(Checkbox, { id: id, name: id, onChange: onRowSelect, value: selected })))),
        React.createElement(Wrapper, { className: props.className }, React.Children.map(props.children, function (child, index) {
            if (!child) {
                return null;
            }
            var columnWidth = cols[index];
            if (columnWidth === undefined) {
                columnWidth = 10;
            }
            return React.cloneElement(child, { columnWidth: columnWidth });
        }))));
};
