import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ForgotPasswordView } from "@/components/views/ForgotPasswordView";
import { LoginView } from "@/components/views/LoginView";
import { ResetPasswordWithTokenView } from "@/components/views/ResetPasswordWithTokenView";
import { SetPasswordView } from "@/components/views/SetPasswordView";
import { TermsAndConditionsView } from "@/components/views/TermsAndConditionsView";
import { ViewPath } from "@/constants/routing";
export var PublicRouter = function () {
    return (React.createElement(Switch, null,
        React.createElement(Route, { component: SetPasswordView, exact: true, path: ViewPath.SET_PASSWORD }),
        React.createElement(Route, { component: TermsAndConditionsView, exact: true, path: ViewPath.TERMS_AND_CONDITIONS }),
        React.createElement(Route, { component: ResetPasswordWithTokenView, exact: true, path: ViewPath.RESET_PASSWORD_WITH_TOKEN }),
        React.createElement(Route, { component: ForgotPasswordView, exact: true, path: ViewPath.RESET_PASSWORD }),
        React.createElement(Route, { component: LoginView, exact: true, path: ViewPath.LOGIN }),
        React.createElement(Redirect, { exact: true, from: ViewPath.HOME, to: ViewPath.LOGIN }),
        React.createElement(Redirect, { from: ViewPath.APP_ROOT, to: ViewPath.LOGIN })));
};
