var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { Switch } from "@/components/atoms/Switch";
import { SimpleHeader } from "@/components/molecules/SimpleHeader";
import { CompanyForm } from "@/components/organisms/CompanyForm";
import { ConfirmModal } from "@/components/organisms/ConfirmModal";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { PER_PAGE } from "@/constants/queries";
import { ViewPath } from "@/constants/routing";
import { useUpdateCompany } from "@/containers/mutations/UpdateCompany";
import { useUpdateCompanyStatus } from "@/containers/mutations/UpdateCompanyStatus";
import { refetchCompaniesQuery } from "@/containers/queries/CompaniesQuery";
import { useCompanyQuery, refetchCompanyQuery } from "@/containers/queries/CompanyQuery";
import { useCountriesQuery } from "@/containers/queries/CountriesQuery";
import { useSectorsQuery } from "@/containers/queries/SectorsQuery";
import { useNotifier } from "@/hooks/useNotifier";
import { useToggle } from "@/hooks/useToggle";
import { formatErrorMessage } from "@/utils/format";
import { AlignToCenter, KYBStatus, SwitchLabel, Status } from "./styles";
export function UpdateCompanyView(props) {
    var companyId = props.match.params.companyId;
    var t = useTranslation("UpdateCompanyView").t;
    var notify = useNotifier().notify;
    var _a = useToggle(), confirmModalOpen = _a[0], openConfirmModal = _a[1], closeConfirmModal = _a[2];
    var companyQuery = useCompanyQuery({ companyId: companyId });
    var sectorsQuery = useSectorsQuery({});
    var countriesQuery = useCountriesQuery({});
    var onDetailsSaved = useCallback(function () {
        notify(t("notifyCompanyUpdated"), { severity: "success" });
        props.history.push(ViewPath.COMPANIES);
    }, [notify, props.history, t]);
    var onStatusSaved = useCallback(function () {
        notify(t("notifyCompanyStatusUpdated"), { severity: "success" });
        closeConfirmModal();
    }, [notify, closeConfirmModal, t]);
    var _b = useUpdateCompany({
        onCompleted: onDetailsSaved,
        awaitRefetchQueries: true,
        refetchQueries: function () { return [
            refetchCompanyQuery({ companyId: companyId }),
            refetchCompaniesQuery({ page: 1, perPage: PER_PAGE }),
        ]; },
    }), updateCompanyDetails = _b[0], updateCompanyDetailsMeta = _b[1];
    var updateCompanyStatus = useUpdateCompanyStatus({
        onCompleted: onStatusSaved,
        awaitRefetchQueries: true,
        refetchQueries: function () { return [
            refetchCompanyQuery({ companyId: companyId }),
            refetchCompaniesQuery({ page: 1, perPage: PER_PAGE }),
        ]; },
    })[0];
    var onUpdate = useCallback(function (values) {
        var _a, _b, _c;
        var employerId = (_c = (_b = (_a = companyQuery.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.employer.id) !== null && _c !== void 0 ? _c : null;
        var tradingAddressCountryId = values.copyRegisteredToTrading
            ? values.registeredAddressCountryId
            : values.tradingAddressCountryId;
        var tradingAddressLineOne = values.copyRegisteredToTrading
            ? values.registeredAddressLineOne
            : values.tradingAddressLineOne;
        var tradingAddressPostCode = values.copyRegisteredToTrading
            ? values.registeredAddressPostCode
            : values.tradingAddressPostCode;
        var tradingAddressPostTown = values.copyRegisteredToTrading
            ? values.registeredAddressPostTown
            : values.tradingAddressPostTown;
        var tradingAddressProvinceCode = values.copyRegisteredToTrading
            ? values.registeredAddressProvinceCode
            : values.tradingAddressProvinceCode;
        if (employerId === null) {
            throw new Error(t("errorGetCompanyId"));
        }
        updateCompanyDetails(__assign(__assign({}, values), { cucCode: values.cuc, companyRealCode: values.companyRealCode, idPayrollItem: values.idPayrollItem, payrollFileFormat: values.payrollFileFormat, dateOfBirth: values.employerDateOfBirth, employer: {
                dateOfBirth: values.employerDateOfBirth,
                email: values.employerEmail,
                firstName: values.employerFirstName,
                id: employerId,
                lastName: values.employerLastName,
                homeAddress: {
                    country: values.employerAddressCountryId,
                    lineOne: values.employerAddressLineOne,
                    postCode: values.employerAddressPostCode,
                    postTown: values.employerAddressPostTown,
                    provinceCode: values.employerAddressProvinceCode,
                },
            }, id: companyId, mobileNumber: values.employerMobileNumber, payDay: parseInt(values.payDay, 10), registeredAddress: {
                country: values.registeredAddressCountryId,
                lineOne: values.registeredAddressLineOne,
                postCode: values.registeredAddressPostCode,
                postTown: values.registeredAddressPostTown,
                provinceCode: values.registeredAddressProvinceCode,
            }, sectorId: values.sectorId, tradingAddress: {
                country: tradingAddressCountryId,
                lineOne: tradingAddressLineOne,
                postCode: tradingAddressPostCode,
                postTown: tradingAddressPostTown,
                provinceCode: tradingAddressProvinceCode,
            } }));
    }, [updateCompanyDetails, companyQuery.data, companyId, t]);
    var onStatusChangeConfirm = useCallback(function () {
        if (!companyQuery.data || !companyQuery.data.company) {
            return;
        }
        var company = companyQuery.data.company;
        updateCompanyStatus({
            active: !company.active,
            id: companyId,
        });
    }, [updateCompanyStatus, companyQuery, companyId]);
    return (React.createElement(ProtectedView, { pageTitle: t("pageTitle") },
        React.createElement(InnerContainer, null, (function () {
            if (companyQuery.loading || sectorsQuery.loading || countriesQuery.loading) {
                return t("loading");
            }
            if (companyQuery.error) {
                return formatErrorMessage(companyQuery.error);
            }
            if (sectorsQuery.error) {
                return formatErrorMessage(sectorsQuery.error);
            }
            if (countriesQuery.error) {
                return formatErrorMessage(countriesQuery.error);
            }
            if (countriesQuery.data === undefined) {
                return t("errorFetchCountries");
            }
            if (companyQuery.data === undefined) {
                return t("errorFetchCompany");
            }
            var company = companyQuery.data.company;
            if (company === null) {
                return t("errorCompanyNotFound");
            }
            if (sectorsQuery.data === undefined) {
                return t("errorFetchSectors");
            }
            var statusActionName = company.active ? t("disable") : t("enable");
            var kybActive = company.kybActive ? t("active") : t("not active");
            return (React.createElement(React.Fragment, null,
                confirmModalOpen && (React.createElement(ConfirmModal, { onCancel: closeConfirmModal, onConfirm: onStatusChangeConfirm }, t("pleaseConfirmCompany", { value: statusActionName }))),
                React.createElement(SimpleHeader, { backLink: ViewPath.COMPANIES, title: company.name, rightSlot: React.createElement(React.Fragment, null,
                        React.createElement(AlignToCenter, null,
                            React.createElement(SwitchLabel, null, t("toggleCompany")),
                            React.createElement(Switch, { id: "companyActive", name: "companyActive", onChange: openConfirmModal, value: company.active })),
                        React.createElement(AlignToCenter, null,
                            React.createElement(KYBStatus, null,
                                t("kybStatus"),
                                ":",
                                React.createElement(Status, { kybActive: company.kybActive }, kybActive)))) }),
                React.createElement(CompanyForm, { company: company, countries: countriesQuery.data.countries, onSubmit: onUpdate, sectors: sectorsQuery.data.sectors, statusMessage: updateCompanyDetailsMeta.message, statusSeverity: updateCompanyDetailsMeta.severity, submitting: updateCompanyDetailsMeta.loading })));
        })())));
}
