import styled from "@emotion/styled";
import { fontSize, fontWeight, colors } from "@/styleguide";
export var DropZoneHeader = styled.h3({
    fontSize: fontSize.big,
    fontWeight: fontWeight.semiBold,
});
export var FileInput = styled.input({
    display: "none",
});
export var ButtonWrapper = styled.div({
    margin: "40px auto 0",
    width: 350,
});
export var LinkWrapper = styled.div({
    marginTop: 20,
    textAlign: "center",
});
export var Link = styled.span({
    color: colors.shipGrey,
    cursor: "pointer",
    fontSize: fontSize.small,
    "&:hover": {
        textDecoration: "underline",
    },
});
