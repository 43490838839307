var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useLocation } from "react-router-dom";
import { StyledLink } from "./styles";
export var AppNavLink = function (props) {
    var pathname = useLocation().pathname;
    return React.createElement(StyledLink, __assign({ isActive: pathname.startsWith(props.to) }, props));
};
