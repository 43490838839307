import React from "react";
import { Icon } from "@/components/atoms/Icon";
import { colors } from "@/styleguide";
import { IconWrapper, Root, Paragraph } from "./styles";
export function FormStatus(props) {
    if (!props.message || !props.severity) {
        return null;
    }
    var iconColor = colors.buttercup;
    var iconName = "cross";
    if (props.severity === "success") {
        iconColor = colors.java;
        iconName = "check";
    }
    return (React.createElement(Root, { className: "flex-col-center mt-15" },
        React.createElement(IconWrapper, null,
            React.createElement(Icon, { color: iconColor, name: iconName })),
        React.createElement(Paragraph, null, props.message)));
}
