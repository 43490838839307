import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { CardSection } from "@/components/atoms/CardSection";
import { FormField } from "@/components/atoms/FormField";
import { Gap } from "@/components/atoms/Gap";
import { Hint } from "@/components/atoms/Hint";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { RangeInput } from "@/components/atoms/RangeInput";
import { TextInput } from "@/components/atoms/TextInput";
import { CardsViewButtons } from "@/components/molecules/CardsViewButtons";
import { FormMessage } from "@/components/molecules/FormMessage";
import { ViewPath } from "@/constants/routing";
import { useToggle } from "@/hooks/useToggle";
import { percent } from "@/styleguide";
import { isInRange, matchesRegex } from "@/utils/core";
import { createValidation } from "@/utils/validate";
import { CardsColumn, ContentWrapper, DescriptionColumn, FormMessageWrapper, Heading, Information, Row, FeeInputWrapper, } from "./styles";
var validate = createValidation({
    transactionFee: [
        [matchesRegex(/^-?\d+(\.\d+)?$/), "Fee must be a number"],
        [function (val) { return typeof val === "string" && isInRange(0, Infinity)(+val); }, "Fee must be positive"],
    ],
});
export function WithdrawalSettingsForm(props) {
    var t = useTranslation("WithdrawalSettingsView.WithdrawalSettingsForm").t;
    var _a = useToggle(), isOpen = _a[0], close = _a[2], toggle = _a[3];
    var _b = useFormik({
        validate: validate,
        initialValues: {
            transactionFee: String(props.company.transactionFee),
            transactionsLimit: String(props.company.transactionsLimit),
            withdrawalLimit: String(props.company.withdrawalLimit),
        },
        onSubmit: props.onSubmit,
    }), errors = _b.errors, touched = _b.touched, handleBlur = _b.handleBlur, handleChange = _b.handleChange, handleSubmit = _b.handleSubmit, values = _b.values;
    return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(InnerContainer, null,
            React.createElement(Row, null,
                React.createElement(DescriptionColumn, null,
                    React.createElement(Heading, null, t("withdrawalSettings"))),
                React.createElement(CardsColumn, null,
                    React.createElement(Gap, { direction: "y", size: 20 },
                        React.createElement(CardSection, null,
                            React.createElement(CardSection.Heading, null,
                                t("withdrawalLimit"),
                                React.createElement(Information, null,
                                    React.createElement(Hint, { isOpen: isOpen, onClickOutside: close, onToggle: toggle, width: 585, x: -220, y: 35 }, t("withdrawalInformation")))),
                            React.createElement(CardSection.Subheading, null, t("setPercentage")),
                            React.createElement(ContentWrapper, null,
                                React.createElement(FormField, { error: touched.withdrawalLimit ? errors.withdrawalLimit : undefined },
                                    React.createElement(RangeInput, { formatLabel: percent, maxValue: 50, minValue: 1, name: "withdrawalLimit", onChange: handleChange, value: parseFloat(values.withdrawalLimit) })))),
                        React.createElement(CardSection, null,
                            React.createElement(CardSection.Heading, null, t("transactionMonthlyLimit")),
                            React.createElement(ContentWrapper, null,
                                React.createElement(FormField, { error: touched.transactionsLimit ? errors.transactionsLimit : undefined },
                                    React.createElement(RangeInput, { maxValue: 5, minValue: 1, name: "transactionsLimit", onChange: handleChange, value: parseFloat(values.transactionsLimit) })),
                                React.createElement(FeeInputWrapper, null,
                                    React.createElement(FormField, { error: touched.transactionFee ? errors.transactionFee : undefined, label: t("transactionFeeEur") },
                                        React.createElement(TextInput, { name: "transactionFee", onBlur: handleBlur, onChange: handleChange, value: values.transactionFee })))))))),
            React.createElement(CardsViewButtons, { cancelHref: ViewPath.EMPLOYEES, isFormSubmitting: props.loading }),
            props.formMessage && props.formStatus && (React.createElement(FormMessageWrapper, null,
                React.createElement(FormMessage, { type: props.formStatus }, props.formMessage))))));
}
