import styled from "@emotion/styled";
import { transparentize } from "polished";
import { colors } from "@/styleguide";
export var DropZone = styled.div({
    alignItems: "center",
    backgroundColor: colors.alabaster,
    border: "2px dashed " + transparentize(0.5, colors.grey),
    display: "flex",
    fill: colors.java,
    flexDirection: "column",
    height: 230,
    justifyContent: "center",
    width: "100%",
});
