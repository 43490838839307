import styled from "@emotion/styled";
import { colors } from "@/styleguide";
export var Root = styled.div({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    paddingBottom: 130,
}, function (props) { return ({
    backgroundColor: props.themeName === "darken" ? colors.alabaster : colors.white,
}); });
