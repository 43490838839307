var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { FormField } from "@/components/atoms/FormField";
import { Gap } from "@/components/atoms/Gap";
import { TextInput } from "@/components/atoms/TextInput";
import { FormMessage } from "@/components/molecules/FormMessage";
import { createValidation, passwordRules } from "@/utils/validate";
import { ButtonWrapper, FormMessageWrapper } from "./styles";
var validate = createValidation({
    password: passwordRules,
    confirmPassword: __spreadArrays(passwordRules, [
        [function (val, values) { return val === values.password; }, "Has to match password field"],
    ]),
});
export function ResetPasswordWithTokenForm(props) {
    var _a;
    var t = useTranslation("ResetPasswordWithTokenView.ResetPasswordWithTokenForm").t;
    var _b = useFormik({
        validate: validate,
        initialValues: {
            confirmPassword: "",
            password: (_a = process.env.DEFAULT_USER_PASSWORD) !== null && _a !== void 0 ? _a : "",
        },
        onSubmit: props.onSubmit,
    }), errors = _b.errors, touched = _b.touched, handleBlur = _b.handleBlur, handleChange = _b.handleChange, handleSubmit = _b.handleSubmit, values = _b.values;
    return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(Gap, { direction: "y", size: 25 },
            React.createElement(FormField, { error: touched.password ? errors.password : undefined, label: t("passwordLabel") },
                React.createElement(TextInput, { name: "password", onBlur: handleBlur, onChange: handleChange, placeholder: t("passwordPlaceholder"), type: "password", value: values.password })),
            React.createElement(FormField, { error: touched.confirmPassword ? errors.confirmPassword : undefined, label: t("confirmPasswordLabel") },
                React.createElement(TextInput, { name: "confirmPassword", onBlur: handleBlur, onChange: handleChange, placeholder: t("confirmPasswordPlaceholder"), type: "password", value: values.confirmPassword }))),
        React.createElement(ButtonWrapper, null,
            React.createElement(Button, { disabled: props.loading, type: "submit" }, t("save"))),
        props.formMessage && props.formStatus && (React.createElement(FormMessageWrapper, null,
            React.createElement(FormMessage, { type: props.formStatus }, props.formMessage)))));
}
