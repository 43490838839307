var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Wrapper, ActiveOption, Option } from "./styles";
export var ButtonSwitch = function (_a) {
    var onChange = _a.onChange, value = _a.value, props = __rest(_a, ["onChange", "value"]);
    var onOptionClick = React.useCallback(function (evt) {
        var newValue = evt.currentTarget.getAttribute("data-value");
        if (newValue && value !== newValue) {
            onChange(newValue);
        }
    }, [onChange, value]);
    return (React.createElement(Wrapper, null, props.options.map(function (option) {
        var Component = option.value === value ? ActiveOption : Option;
        return (React.createElement(Component, { "data-value": option.value, key: option.value, onClick: onOptionClick }, option.label));
    })));
};
