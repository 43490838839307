export var sizes = {
    regular: {
        borderRadius: 6,
        boxSize: 30,
        iconSize: 12,
    },
    small: {
        borderRadius: 4,
        boxSize: 18,
        iconSize: 6,
    },
};
