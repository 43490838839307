import styled from "@emotion/styled";
import { boxFrame } from "@/styleguide";
export var Header = styled.header({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: boxFrame([30, 0]),
});
export var CompanyName = styled.div({
    fontSize: "22px",
    marginLeft: "18px",
});
export var PeriodSelect = styled.div({
    minWidth: "200px",
});
export var StatsInfo = styled.div({
    alignItems: "center",
    fontSize: "14px",
    display: "flex",
    marginRight: "-20px",
    width: "50%",
});
