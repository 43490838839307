import React from "react";
import { Icon } from "@/components/atoms/Icon";
import { colors } from "@/styleguide";
import { Heading, AlignToCenter, HeaderLink } from "./styles";
export function SimpleHeader(props) {
    return (React.createElement(Heading, null,
        React.createElement(AlignToCenter, null,
            props.backLink && (React.createElement(HeaderLink, { to: props.backLink },
                React.createElement(Icon, { color: colors.java, name: "arrowLeftCircle", size: 40 }))),
            props.title),
        props.rightSlot && React.createElement("div", null, props.rightSlot)));
}
