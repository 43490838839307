import styled from "@emotion/styled";
import { transparentize } from "polished";
import { colors, fontSize, fontWeight, ZIndex } from "@/styleguide";
export var StyledRoot = styled.div({
    lineHeight: 1,
    position: "relative",
});
export var IconWrapper = styled.span({
    cursor: "pointer",
    lineHeight: 1,
});
export var Content = styled.div({
    backgroundColor: colors.white,
    borderRadius: 12,
    boxShadow: "0 0 20px " + transparentize(0.85, colors.shipGrey),
    fontSize: fontSize.regular,
    fontWeight: fontWeight.regular,
    lineHeight: 1.4,
    position: "absolute",
    zIndex: ZIndex.HINT,
}, function (props) {
    var _a;
    return ({
        left: props.x,
        padding: (_a = props.padding) !== null && _a !== void 0 ? _a : "30px 25px",
        top: props.y,
        width: props.width || "auto",
    });
});
