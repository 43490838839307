import styled from "@emotion/styled";
import { colors, fontSize, fontWeight, boxFrame, rem } from "@/styleguide";
export var Paragraph = styled.p({
    color: colors.grey,
    fontSize: rem(fontSize.regular),
    fontWeight: fontWeight.regular,
    margin: boxFrame([25, 0, 20]),
});
export var FormWrapper = styled.div({
    maxWidth: "100%",
    width: 440,
});
export var AvatarWrapper = styled.div({
    marginTop: 15,
});
