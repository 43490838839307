var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useFormik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardSection } from "@/components/atoms/CardSection";
import { CardsRow } from "@/components/atoms/CardsRow";
import { FormField } from "@/components/atoms/FormField";
import { Gap } from "@/components/atoms/Gap";
import { Hint } from "@/components/atoms/Hint";
import { TextInput } from "@/components/atoms/TextInput";
import { WithLabel } from "@/components/atoms/WithLabel";
import { CardsViewButtons } from "@/components/molecules/CardsViewButtons";
import { Checkbox } from "@/components/molecules/Checkbox";
import { FormStatus } from "@/components/molecules/FormStatus";
import { Select } from "@/components/molecules/Select";
import { ViewPath } from "@/constants/routing";
import { provinces } from "@/data/provinces";
import { useToggle } from "@/hooks/useToggle";
import { isEmptyString, isTrue } from "@/utils/core";
import { formatMoney } from "@/utils/format";
import { createSelectOptionsFromArray } from "@/utils/misc";
import { abortIf, abortIfOther, accountNameRules, addressLineRules, adultDateOfBirthRules, cityRules, createValidation, emailRules, employeeIdRules, ibanRules, jobTitleRules, partTimeRules, personFirstNameRules, personLastNameRules, phoneNumberRules, postalCodeRules, requiredValidationRules, salaryRules, taxIdRules, } from "@/utils/validate";
import { AccruedAmount, FieldCell, FieldsRow, HeaderCell, HeaderInfoCell, HeaderWrapper, Information, InformationLabel, InputContent, InputWrapper, SectionHeader, LabelWithHint, HintWrapper, } from "./styles";
var validate = createValidation({
    accountName: __spreadArrays([[abortIfOther("copyNameToAccountName", isTrue)]], accountNameRules),
    address: addressLineRules,
    countryId: requiredValidationRules,
    dateOfBirth: adultDateOfBirthRules,
    employeeEmail: emailRules,
    employeeFirstName: personFirstNameRules,
    employeeId: __spreadArrays([[abortIf(isEmptyString)]], employeeIdRules),
    employeeLastName: personLastNameRules,
    grossSalary: salaryRules,
    iban: ibanRules,
    jobTitle: __spreadArrays([[abortIf(isEmptyString)]], jobTitleRules),
    mobileNumber: phoneNumberRules,
    partTime: partTimeRules,
    postalCode: postalCodeRules,
    postTown: cityRules,
    provinceCode: __spreadArrays([[abortIfOther("countryId", function (v) { return v !== "IT"; })]], requiredValidationRules),
    taxId: taxIdRules,
});
export function EmployeeForm(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12;
    var onSubmit = _a.onSubmit, props = __rest(_a, ["onSubmit"]);
    var t = useTranslation("Molecules.EmployeeForm").t;
    var _13 = useToggle(), isHintOpen = _13[0], closeHint = _13[2], toggleHint = _13[3];
    var _14 = useToggle(), isPartTimeHintOpen = _14[0], closePartTimeHint = _14[2], togglePartTimeHint = _14[3];
    var _15 = useMemo(function () {
        return {
            countriesOptions: createSelectOptionsFromArray(props.countries, "code", "name"),
            provinceOptions: createSelectOptionsFromArray(provinces, "code", "name"),
        };
    }, [props.countries]), countriesOptions = _15.countriesOptions, provinceOptions = _15.provinceOptions;
    var handleOnSubmit = useCallback(function (values) {
        onSubmit(__assign(__assign({}, values), { accountName: values.copyNameToAccountName
                ? (values.employeeFirstName + " " + values.employeeLastName).slice(0, 70)
                : values.accountName, provinceCode: values.countryId === "IT" ? values.provinceCode : t("na") }));
    }, [onSubmit, t]);
    var _16 = useFormik({
        validate: validate,
        onSubmit: handleOnSubmit,
        initialValues: {
            accountName: (_c = (_b = props.employee) === null || _b === void 0 ? void 0 : _b.accountName) !== null && _c !== void 0 ? _c : "",
            address: (_e = (_d = props.employee) === null || _d === void 0 ? void 0 : _d.address) !== null && _e !== void 0 ? _e : "",
            copyNameToAccountName: true,
            countryId: (_g = (_f = props.employee) === null || _f === void 0 ? void 0 : _f.countryId) !== null && _g !== void 0 ? _g : "",
            dateOfBirth: (_j = (_h = props.employee) === null || _h === void 0 ? void 0 : _h.dateOfBirth) !== null && _j !== void 0 ? _j : "",
            department: (_l = (_k = props.employee) === null || _k === void 0 ? void 0 : _k.department) !== null && _l !== void 0 ? _l : "",
            employeeEmail: (_o = (_m = props.employee) === null || _m === void 0 ? void 0 : _m.email) !== null && _o !== void 0 ? _o : "",
            employeeFirstName: (_q = (_p = props.employee) === null || _p === void 0 ? void 0 : _p.firstName) !== null && _q !== void 0 ? _q : "",
            employeeId: (_s = (_r = props.employee) === null || _r === void 0 ? void 0 : _r.employeeId) !== null && _s !== void 0 ? _s : "",
            employeeLastName: (_u = (_t = props.employee) === null || _t === void 0 ? void 0 : _t.lastName) !== null && _u !== void 0 ? _u : "",
            grossSalary: (_w = (_v = props.employee) === null || _v === void 0 ? void 0 : _v.salary) !== null && _w !== void 0 ? _w : "",
            iban: (_y = (_x = props.employee) === null || _x === void 0 ? void 0 : _x.iban) !== null && _y !== void 0 ? _y : "",
            jobTitle: (_0 = (_z = props.employee) === null || _z === void 0 ? void 0 : _z.jobTitle) !== null && _0 !== void 0 ? _0 : "",
            mobileNumber: (_2 = (_1 = props.employee) === null || _1 === void 0 ? void 0 : _1.mobile) !== null && _2 !== void 0 ? _2 : "",
            partTime: (_4 = (_3 = props.employee) === null || _3 === void 0 ? void 0 : _3.partTime) !== null && _4 !== void 0 ? _4 : "",
            postalCode: (_6 = (_5 = props.employee) === null || _5 === void 0 ? void 0 : _5.postCode) !== null && _6 !== void 0 ? _6 : "",
            postTown: (_8 = (_7 = props.employee) === null || _7 === void 0 ? void 0 : _7.postTown) !== null && _8 !== void 0 ? _8 : "",
            provinceCode: (_10 = (_9 = props.employee) === null || _9 === void 0 ? void 0 : _9.provinceCode) !== null && _10 !== void 0 ? _10 : "",
            taxId: (_12 = (_11 = props.employee) === null || _11 === void 0 ? void 0 : _11.taxId) !== null && _12 !== void 0 ? _12 : "",
        },
    }), errors = _16.errors, touched = _16.touched, handleBlur = _16.handleBlur, handleChange = _16.handleChange, handleSubmit = _16.handleSubmit, setFieldValue = _16.setFieldValue, values = _16.values;
    return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(CardsRow, { cardsCount: 2, minHeight: 660 },
            React.createElement(CardSection, null,
                React.createElement(CardSection.Heading, null, t("employeeDetails")),
                React.createElement(CardSection.Subheading, null, t("markedFieldsAreMandatory")),
                React.createElement(Gap, { className: "mt-25", direction: "y", size: 15 },
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.employeeFirstName ? errors.employeeFirstName : undefined, label: t("firstNameLabel") },
                                React.createElement(TextInput, { name: "employeeFirstName", onBlur: handleBlur, onChange: handleChange, placeholder: t("firstNamePlaceholder"), value: values.employeeFirstName }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.employeeLastName ? errors.employeeLastName : undefined, label: t("lastNameLabel") },
                                React.createElement(TextInput, { name: "employeeLastName", onBlur: handleBlur, onChange: handleChange, placeholder: t("lastNamePlaceholder"), value: values.employeeLastName })))),
                    React.createElement(FormField, { error: touched.employeeEmail ? errors.employeeEmail : undefined, label: t("emailLabel") },
                        React.createElement(TextInput, { name: "employeeEmail", onBlur: handleBlur, onChange: handleChange, placeholder: t("emailPlaceholder"), value: values.employeeEmail })),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.mobileNumber ? errors.mobileNumber : undefined, label: t("phoneLabel") },
                                React.createElement(TextInput, { name: "mobileNumber", onBlur: handleBlur, onChange: handleChange, placeholder: t("phonePlaceholder"), value: values.mobileNumber }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.dateOfBirth ? errors.dateOfBirth : undefined, label: t("birthDateLabel") },
                                React.createElement(TextInput, { name: "dateOfBirth", onBlur: handleBlur, onChange: handleChange, placeholder: t("birthDatePlaceholder"), value: values.dateOfBirth })))),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.jobTitle ? errors.jobTitle : undefined, label: t("jobTitleLabel") },
                                React.createElement(TextInput, { name: "jobTitle", onBlur: handleBlur, onChange: handleChange, placeholder: t("jobTitlePlaceholder"), value: values.jobTitle }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.department ? errors.department : undefined, label: t("departmentLabel") },
                                React.createElement(TextInput, { name: "department", onBlur: handleBlur, onChange: handleChange, placeholder: t("departmentPlaceholder"), value: values.department })))),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.employeeId ? errors.employeeId : undefined, label: t("employeeIdLabel") },
                                React.createElement(TextInput, { name: "employeeId", onBlur: handleBlur, onChange: handleChange, placeholder: t("employeeIdPlaceholder"), value: values.employeeId })))),
                    React.createElement(SectionHeader, null, t("employeeAddress")),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.countryId ? errors.countryId : undefined, label: t("countryLabel") },
                                React.createElement(Select, { name: "countryId", onBlur: handleBlur, onChange: setFieldValue, options: countriesOptions, placeholder: t("countryPlaceholder"), value: values.countryId }))),
                        values.countryId === "IT" && (React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.provinceCode ? errors.provinceCode : undefined, label: t("provinceLabel") },
                                React.createElement(Select, { name: "provinceCode", onBlur: handleBlur, onChange: setFieldValue, options: provinceOptions, placeholder: t("provincePlaceholder"), value: values.provinceCode }))))),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.postTown ? errors.postTown : undefined, label: t("cityLabel") },
                                React.createElement(TextInput, { name: "postTown", onBlur: handleBlur, onChange: handleChange, placeholder: t("cityPlaceholder"), value: values.postTown }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.postalCode ? errors.postalCode : undefined, label: t("postalCodeLabel") },
                                React.createElement(TextInput, { name: "postalCode", onBlur: handleBlur, onChange: handleChange, placeholder: t("postalCodePlaceholder"), value: values.postalCode })))),
                    React.createElement(FormField, { error: touched.address ? errors.address : undefined, label: t("addressLabel") },
                        React.createElement(TextInput, { name: "address", onBlur: handleBlur, onChange: handleChange, placeholder: t("addressPlaceholder"), value: values.address })))),
            React.createElement(CardSection, null,
                React.createElement(HeaderWrapper, null,
                    React.createElement(HeaderCell, null,
                        React.createElement(CardSection.Heading, null, t("salaryAndBankDetails")),
                        React.createElement(CardSection.Subheading, null, t("markedFieldsAreMandatory"))),
                    props.employee && (React.createElement(HeaderInfoCell, null,
                        React.createElement(Information, null,
                            React.createElement(InformationLabel, null, t("accruedAmountInactive")),
                            React.createElement(Hint, { isOpen: isHintOpen, onClickOutside: closeHint, onToggle: toggleHint, width: 560, x: -539, y: -5 }, t("accruedAmountInactiveDetails"))),
                        React.createElement(AccruedAmount, null, formatMoney(props.employee.accruedAmount))))),
                React.createElement(Gap, { className: "mt-25", direction: "y", size: 15 },
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.grossSalary ? errors.grossSalary : undefined, label: t("grossMonthlySalaryLabel") },
                                React.createElement(TextInput, { name: "grossSalary", onBlur: handleBlur, onChange: handleChange, placeholder: t("grossMonthlySalaryPlaceholder"), value: values.grossSalary }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.partTime ? errors.partTime : undefined, label: React.createElement(LabelWithHint, null,
                                    t("partTimeLabel"),
                                    React.createElement(HintWrapper, null,
                                        React.createElement(Hint, { isOpen: isPartTimeHintOpen, onClickOutside: closePartTimeHint, onToggle: togglePartTimeHint, padding: "10px 15px", x: -110, y: -50 }, t("insert100ForFullTime")))) },
                                React.createElement(TextInput, { name: "partTime", onBlur: handleBlur, onChange: handleChange, placeholder: t("partTimePlaceholder"), value: values.partTime })))),
                    values.copyNameToAccountName ? (React.createElement(WithLabel, { label: t("accountNameLabel") },
                        React.createElement(InputContent, null, (values.employeeFirstName + " " + values.employeeLastName).slice(0, 70)))) : (React.createElement(FormField, { error: touched.accountName ? errors.accountName : undefined, label: t("accountNameLabel") },
                        React.createElement(TextInput, { name: "accountName", onBlur: handleBlur, onChange: handleChange, placeholder: t("accountNamePlaceholder"), value: values.accountName }))),
                    React.createElement(InputWrapper, null,
                        React.createElement(Checkbox, { description: t("accountNameSameAsEmployeeLabel"), id: "copyNameToAccountName", name: "copyNameToAccountName", onBlur: handleBlur, onChange: handleChange, size: "small", value: values.copyNameToAccountName })),
                    React.createElement(FormField, { error: touched.iban ? errors.iban : undefined, label: t("ibanLabel") },
                        React.createElement(TextInput, { name: "iban", onBlur: handleBlur, onChange: handleChange, placeholder: t("ibanPlaceholder"), value: values.iban })),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.taxId ? errors.taxId : undefined, label: t("taxIdLabel") },
                                React.createElement(TextInput, { name: "taxId", onBlur: handleBlur, onChange: handleChange, placeholder: t("taxIdPlaceholder"), value: values.taxId }))))))),
        React.createElement(CardsViewButtons, { cancelHref: ViewPath.EMPLOYEES, isFormSubmitting: props.submitting }),
        React.createElement(FormStatus, { message: t(props.statusMessage, values), severity: props.statusSeverity })));
}
