import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import i18n from "i18next";
import React from "react";
import { render } from "react-dom";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { apolloClient } from "@/apollo";
import { NotifierContext } from "@/contexts/NotifierContext";
import { ViewerContextProvider } from "@/contexts/ViewerContext";
import { enTranslation } from "@/translations/en.translation";
import { itTranslation } from "@/translations/it.translation";
import { getLanguage } from "@/utils/format";
import { App } from "./App";
// It's important that Sentry initialization is the first thing here,
// for sure before app render.
if (process.env.SENTRY_DSN) {
    try {
        Sentry.init({ dsn: process.env.SENTRY_DSN });
    }
    catch (_a) {
        // Make sure sentry won't break the app in case of error.
    }
}
try {
    var language = getLanguage();
    i18n.use(initReactI18next).init({
        lng: language,
        fallbackLng: "en",
        resources: {
            en: enTranslation,
            it: itTranslation,
        },
    });
}
catch (e) {
    try {
        i18n.use(initReactI18next).init({
            lng: "it",
            fallbackLng: "en",
            resources: {
                en: enTranslation,
                it: itTranslation,
            },
        });
    }
    catch (er) {
        // Prevent to boot application gracefully.
    }
}
render(React.createElement(ApolloProvider, { client: apolloClient },
    React.createElement(BrowserRouter, null,
        React.createElement(NotifierContext.Provider, null,
            React.createElement(ViewerContextProvider, null,
                React.createElement(App, null))))), document.getElementById("app-root"));
if (process.env.NODE_ENV === "development") {
    if (module.hot) {
        module.hot.accept();
    }
}
