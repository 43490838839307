import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { Icon } from "@/components/atoms/Icon";
import { useResendInvitation } from "@/containers/mutations/ResendInvitation";
export var EmployeeActions = function (props) {
    var _a = useResendInvitation(), resendInvitation = _a[0], completed = _a[1].completed;
    var t = useTranslation("EmployeeView.EmployeeActions").t;
    var onResendInvitation = React.useCallback(function () {
        resendInvitation({ email: props.email });
    }, [resendInvitation, props.email]);
    if (props.isConfirmed) {
        return null;
    }
    if (completed) {
        return (React.createElement(Button, { size: "small", theme: "dark", type: "button", width: 130 },
            t("inviteResent"),
            React.createElement(Icon, { name: "checkRounded", size: 12 })));
    }
    return (React.createElement(Button, { onClick: onResendInvitation, size: "small", theme: "secondary", type: "button", width: 130 },
        t("resendInvite"),
        React.createElement(Icon, { name: "paperPlane", size: 16 })));
};
