import styled from "@emotion/styled";
import { fontSize, colors, rem } from "@/styleguide";
export var StyledRoot = styled.div({
    display: "flex",
    position: "relative",
}, function (props) { return ({
    width: props.width || "auto",
}); });
export var IconWrapper = styled.div({
    cursor: "pointer",
    fill: colors.java,
    position: "absolute",
    right: 10,
    top: 10,
});
export var Input = styled.input({
    backgroundColor: colors.white,
    border: "none",
    borderRadius: 6,
    color: colors.shipGrey,
    fontFamily: "inherit",
    fontSize: rem(fontSize.small),
    height: 40,
    outline: "none",
    paddingLeft: 20,
    paddingRight: 50,
    width: "100%",
});
