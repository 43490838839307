var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Sorter } from "@/components/atoms/Sorter";
import { Name, SorterWrapper, Wrapper } from "./styles";
export function SortingTrigger(_a) {
    var activeDirection = _a.activeDirection, onSort = _a.onSort, name = _a.name, props = __rest(_a, ["activeDirection", "onSort", "name"]);
    var handleSortSort = React.useCallback(function () {
        onSort(name, activeDirection === "asc" ? "desc" : "asc");
    }, [activeDirection, name, onSort]);
    return (React.createElement(Wrapper, null,
        React.createElement(Name, { onClick: handleSortSort }, props.children),
        name === props.activeName && (React.createElement(SorterWrapper, null,
            React.createElement(Sorter, { activeDirection: activeDirection })))));
}
