import styled from "@emotion/styled";
import { transparentize } from "polished";
import { colors, fontSize, fontWeight, rem } from "@/styleguide";
export var Wrapper = styled.div({
    borderRadius: 12,
    boxShadow: "0 0 10px " + transparentize(0.8, colors.shipGrey),
    padding: 20,
    width: 1000,
});
export var Header = styled.header({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    minHeight: 40,
});
export var ChartTitle = styled.span({
    color: colors.grey,
    fontSize: rem(fontSize.small),
    fontWeight: fontWeight.semiBold,
});
export var ChartWrapper = styled.div({
    marginTop: 10,
});
