var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { SimpleHeader } from "@/components/molecules/SimpleHeader";
import { CompanyForm } from "@/components/organisms/CompanyForm";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { PER_PAGE } from "@/constants/queries";
import { ViewPath } from "@/constants/routing";
import { useCreateCompany } from "@/containers/mutations/CreateCompany";
import { refetchCompaniesQuery } from "@/containers/queries/CompaniesQuery";
import { useCountriesQuery } from "@/containers/queries/CountriesQuery";
import { useSectorsQuery } from "@/containers/queries/SectorsQuery";
import { useNotifier } from "@/hooks/useNotifier";
import { formatErrorMessage } from "@/utils/format";
export function CreateCompanyView(props) {
    var t = useTranslation("CreateCompanyView").t;
    var notify = useNotifier().notify;
    var sectorsQuery = useSectorsQuery({});
    var countriesQuery = useCountriesQuery({});
    var onCompleted = useCallback(function () {
        notify(t("notifyCompanyProfileAdded"));
        props.history.push(ViewPath.COMPANIES);
    }, [props.history, notify, t]);
    var _a = useCreateCompany({
        onCompleted: onCompleted,
        refetchQueries: function () {
            return [refetchCompaniesQuery({ page: 1, perPage: PER_PAGE })];
        },
    }), createCompany = _a[0], createCompanyMeta = _a[1];
    var onCreateCompany = useCallback(function (values) {
        var tradingAddressCountryId = values.copyRegisteredToTrading
            ? values.registeredAddressCountryId
            : values.tradingAddressCountryId;
        var tradingAddressLineOne = values.copyRegisteredToTrading
            ? values.registeredAddressLineOne
            : values.tradingAddressLineOne;
        var tradingAddressPostCode = values.copyRegisteredToTrading
            ? values.registeredAddressPostCode
            : values.tradingAddressPostCode;
        var tradingAddressPostTown = values.copyRegisteredToTrading
            ? values.registeredAddressPostTown
            : values.tradingAddressPostTown;
        var tradingAddressProvinceCode = values.copyRegisteredToTrading
            ? values.registeredAddressProvinceCode
            : values.tradingAddressProvinceCode;
        createCompany(__assign(__assign({}, values), { tradingAddressCountryId: tradingAddressCountryId,
            tradingAddressLineOne: tradingAddressLineOne,
            tradingAddressPostCode: tradingAddressPostCode,
            tradingAddressPostTown: tradingAddressPostTown,
            tradingAddressProvinceCode: tradingAddressProvinceCode, employerAddressProvinceCode: values.employerAddressProvinceCode, payDay: parseInt(values.payDay, 10), registeredAddressProvinceCode: values.registeredAddressProvinceCode, sectorId: values.sectorId }));
    }, [createCompany]);
    return (React.createElement(ProtectedView, { pageTitle: t("createCompany") },
        React.createElement(InnerContainer, null,
            React.createElement(SimpleHeader, { title: t("addCompany") }),
            (function () {
                if (sectorsQuery.loading || countriesQuery.loading) {
                    return t("loading");
                }
                if (sectorsQuery.error) {
                    return formatErrorMessage(sectorsQuery.error);
                }
                if (countriesQuery.error) {
                    return formatErrorMessage(countriesQuery.error);
                }
                if (sectorsQuery.data === undefined) {
                    return t("errorFetchSectorList");
                }
                if (countriesQuery.data === undefined) {
                    return t("errorFetchCountryList");
                }
                return (React.createElement(CompanyForm, { countries: countriesQuery.data.countries, onSubmit: onCreateCompany, sectors: sectorsQuery.data.sectors, statusMessage: createCompanyMeta.message, statusSeverity: createCompanyMeta.severity, submitting: createCompanyMeta.loading }));
            })())));
}
