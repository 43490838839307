var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useTranslation } from "react-i18next";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { Switch } from "@/components/atoms/Switch";
import { SimpleHeader } from "@/components/molecules/SimpleHeader";
import { ConfirmModal } from "@/components/organisms/ConfirmModal";
import { EmployeeForm } from "@/components/organisms/EmployeeForm";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { ViewPath } from "@/constants/routing";
import { useUpdateEmployee } from "@/containers/mutations/UpdateEmployee";
import { useUpdateEmployeeStatus } from "@/containers/mutations/UpdateEmployeeStatus";
import { useCountriesQuery } from "@/containers/queries/CountriesQuery";
import { refetchEmployeeQuery, useEmployeeQuery } from "@/containers/queries/EmployeeQuery";
import { refetchEmployeesStatsQuery } from "@/containers/queries/EmployeesStatsQuery";
import { useNotifier } from "@/hooks/useNotifier";
import { useToggle } from "@/hooks/useToggle";
import { formatErrorMessage } from "@/utils/format";
import { AlignToCenter, IdNumber, SwitchLabel } from "./styles";
export function UpdateEmployeeView(props) {
    var _a, _b;
    var t = useTranslation("UpdateEmployeeView").t;
    var employeeId = props.match.params.employeeId;
    var _c = useToggle(), confirmModalOpen = _c[0], openConfirmModal = _c[1], closeConfirmModal = _c[2];
    var notify = useNotifier().notify;
    var onDetailsSaved = React.useCallback(function () {
        notify(t("notifyEmployeeUpdated"), { severity: "success" });
        props.history.push(ViewPath.EMPLOYEES);
    }, [props.history, notify, t]);
    var onStatusSaved = React.useCallback(function () {
        notify(t("notifyEmployeeStatusUpdated"), { severity: "success" });
        closeConfirmModal();
    }, [notify, closeConfirmModal, t]);
    var _d = useUpdateEmployee({
        onCompleted: onDetailsSaved,
        awaitRefetchQueries: true,
        refetchQueries: function () { return [refetchEmployeeQuery({ id: employeeId })]; },
    }), updateEmployeeDetails = _d[0], updateEmployeeDetailsMeta = _d[1];
    var updateEmployeeStatus = useUpdateEmployeeStatus({
        onCompleted: onStatusSaved,
        awaitRefetchQueries: true,
        refetchQueries: function () { return [
            refetchEmployeeQuery({ id: employeeId }),
            refetchEmployeesStatsQuery({}),
        ]; },
    })[0];
    var onSubmit = React.useCallback(function (values) {
        updateEmployeeDetails(__assign(__assign({}, values), { employeeTaxId: values.taxId, grossSalary: parseFloat(values.grossSalary), id: employeeId, partTime: parseFloat(values.partTime), homeAddress: {
                country: values.countryId,
                lineOne: values.address,
                postCode: values.postalCode,
                postTown: values.postTown,
                provinceCode: values.provinceCode,
            } }));
    }, [updateEmployeeDetails, employeeId]);
    var countriesQuery = useCountriesQuery({});
    var countries = countriesQuery.data ? countriesQuery.data.countries : [];
    var employeeQuery = useEmployeeQuery({ id: employeeId });
    var employeeQueryData = (_a = employeeQuery === null || employeeQuery === void 0 ? void 0 : employeeQuery.data) === null || _a === void 0 ? void 0 : _a.employee;
    var onStatusChangeConfirm = React.useCallback(function () {
        if (!employeeQuery.data || !employeeQuery.data.employee) {
            return;
        }
        var employee = employeeQuery.data.employee;
        updateEmployeeStatus({
            active: !employee.active,
            id: employeeId,
        });
    }, [updateEmployeeStatus, employeeQuery, employeeId]);
    var isActive = (_b = employeeQueryData === null || employeeQueryData === void 0 ? void 0 : employeeQueryData.active) !== null && _b !== void 0 ? _b : false;
    return (React.createElement(ProtectedView, { pageTitle: t("pageTitle") },
        React.createElement(InnerContainer, null, (function () {
            if (employeeQuery.loading) {
                return t("loading");
            }
            if (employeeQuery.error) {
                return formatErrorMessage(employeeQuery.error);
            }
            if (employeeQuery.data === undefined) {
                return t("errorFetchEmployee");
            }
            if (employeeQuery.data.employee === null) {
                return t("errorEmployeeNotFound");
            }
            var employee = employeeQuery.data.employee;
            var homeAddress = employee.user.homeAddress;
            var statusActionName = employee.active ? t("disable") : t("enable");
            return (React.createElement(React.Fragment, null,
                confirmModalOpen && (React.createElement(ConfirmModal, { onCancel: closeConfirmModal, onConfirm: onStatusChangeConfirm }, t("pleaseConfirmEmployee", { value: statusActionName }))),
                React.createElement(SimpleHeader, { backLink: ViewPath.EMPLOYEES, title: React.createElement("div", null,
                        employee.firstName,
                        " ",
                        employee.lastName,
                        React.createElement("br", null),
                        React.createElement(IdNumber, null, t("id", { value: employee.employeeId }))), rightSlot: React.createElement(AlignToCenter, null,
                        React.createElement(SwitchLabel, null, t("toggleEmployee")),
                        React.createElement(Switch, { id: "employeeActive", name: "employeeActive", onChange: openConfirmModal, value: isActive })) }),
                countriesQuery.loading ? (t("loading")) : countriesQuery.error ? (formatErrorMessage(countriesQuery.error)) : (React.createElement(EmployeeForm, { countries: countries, employee: {
                        accountName: employee.bank.accountName,
                        accruedAmount: employee.accruedAmount,
                        address: homeAddress ? homeAddress.lineOne : null,
                        countryId: homeAddress ? homeAddress.country : null,
                        dateOfBirth: employee.user.dateOfBirth,
                        department: employee.department,
                        email: employee.email,
                        employeeId: employee.employeeId,
                        firstName: employee.firstName,
                        iban: employee.bank.iban,
                        jobTitle: employee.jobTitle,
                        lastName: employee.lastName,
                        mobile: employee.mobile,
                        partTime: employee.salary.partTime,
                        postCode: homeAddress ? homeAddress.postCode : null,
                        postTown: homeAddress ? homeAddress.postTown : null,
                        provinceCode: homeAddress ? homeAddress.provinceCode : null,
                        salary: employee.salary.grossSalary,
                        taxId: employee.taxId,
                    }, onSubmit: onSubmit, statusMessage: updateEmployeeDetailsMeta.message, statusSeverity: updateEmployeeDetailsMeta.severity, submitting: updateEmployeeDetailsMeta.loading }))));
        })())));
}
