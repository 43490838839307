import styled from "@emotion/styled";
import { colors, fontSize, fontWeight } from "@/styleguide";
export var Root = styled.li({
    "&:not(:first-of-type)": {
        marginTop: 40,
    },
});
export var Header = styled.div({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
});
export var FileName = styled.span({
    fontSize: fontSize.regular,
    fontWeight: fontWeight.semiBold,
});
export var Close = styled.span({
    cursor: "pointer",
    fill: colors.java,
});
export var ErrorMessage = styled.p({
    color: colors.buttercup,
    fontSize: fontSize.small,
    margin: 0,
});
