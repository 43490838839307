var AUTH_TOKEN_KEY = "ConsonoAuthToken";
export var getAuthToken = function () {
    return localStorage.getItem(AUTH_TOKEN_KEY);
};
export var setAuthToken = function (token) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
};
export var removeAuthToken = function () {
    localStorage.removeItem(AUTH_TOKEN_KEY);
};
export var removeDirectLanguage = function () {
    localStorage.removeItem("DirectLanguage");
};
export var getAuthContext = function () {
    var token = getAuthToken();
    if (token === null) {
        return undefined;
    }
    return {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
};
