import styled from "@emotion/styled";
import { colors, fontSize, fontWeight, rem } from "@/styleguide";
export var StyledRoot = styled.div({
    display: "flex",
    flexDirection: "column",
});
export var FieldHeader = styled.div({
    display: "flex",
    marginBottom: 10,
});
export var Label = styled.label({
    color: colors.grey,
    fontSize: rem(fontSize.small),
    fontWeight: fontWeight.medium,
    whiteSpace: "nowrap",
});
