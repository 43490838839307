import React from "react";
import { useTranslation } from "react-i18next";
import { CardSection } from "@/components/atoms/CardSection";
import { CardsRow } from "@/components/atoms/CardsRow";
import { Gap } from "@/components/atoms/Gap";
import { Icon } from "@/components/atoms/Icon";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { SimpleHeader } from "@/components/molecules/SimpleHeader";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { useViewer } from "@/hooks/useViewer";
import { colors } from "@/styleguide";
import { ChangePassword } from "./ChangePassword";
import { CompanyDetails } from "./CompanyDetails";
import { PersonalDetails } from "./PersonalDetails";
import { Email } from "./styles";
export var UserSettingsView = function () {
    var _a = useViewer(), isEmployer = _a.isEmployer, viewer = _a.viewer;
    var t = useTranslation("UserSettingsView").t;
    if (viewer === null) {
        return null;
    }
    var cardsCount = isEmployer && viewer.company ? 3 : 2;
    return (React.createElement(ProtectedView, { pageTitle: t("pageTitle") },
        React.createElement(InnerContainer, null,
            React.createElement(SimpleHeader, { title: t("headerTitle") }),
            React.createElement(CardsRow, { cardsCount: cardsCount, minHeight: 660 },
                React.createElement(PersonalDetails, { email: viewer.email, firstName: viewer.firstName, lastName: viewer.lastName, mobile: viewer.mobile }),
                isEmployer && viewer.company && (React.createElement(CompanyDetails, { name: viewer.company.name, sector: viewer.company.sector ? viewer.company.sector.name : null })),
                React.createElement(CardSection, null,
                    React.createElement(CardSection.Heading, null, t("changePassword")),
                    React.createElement(Gap, { className: "items-center mt-15", size: 10 },
                        React.createElement(Icon, { color: colors.java, name: "envelope", size: 15 }),
                        React.createElement(Email, null, viewer.email)),
                    React.createElement(ChangePassword, null))))));
};
