var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { InfoBox } from '@/components/atoms/InfoBox';
import { Select } from '@/components/molecules/Select';
import { getLanguage } from '@/utils/format';
import i18n from 'i18next';
import * as React from 'react';
import { withTranslation } from "react-i18next";
import { StyledRoot } from "./styles";
var WTChangeLanguageBlock = /** @class */ (function (_super) {
    __extends(WTChangeLanguageBlock, _super);
    function WTChangeLanguageBlock() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            language: getLanguage()
        };
        return _this;
    }
    WTChangeLanguageBlock.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var currentLanguage = getLanguage();
        var prevLanguage = prevState.language;
        if (currentLanguage !== prevLanguage) {
            this.setState({
                language: currentLanguage
            });
        }
    };
    WTChangeLanguageBlock.prototype.render = function () {
        return React.createElement(StyledRoot, { className: this.props.className },
            React.createElement(InfoBox, { content: null, title: this.props.t("language") }),
            React.createElement(Select, { name: "language", onChange: this.onChange.bind(this), options: [
                    { value: 'en', label: 'English' },
                    { value: 'it', label: 'Italiano' }
                ], value: this.state.language }));
    };
    WTChangeLanguageBlock.prototype.onChange = function (name, value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        window.localStorage.setItem('DirectLanguage', value);
                        return [4 /*yield*/, i18n.changeLanguage(value)];
                    case 1:
                        _a.sent();
                        this.props.onSubmit(value);
                        return [2 /*return*/];
                }
            });
        });
    };
    return WTChangeLanguageBlock;
}(React.Component));
export var ChangeLanguageBlock = withTranslation("Molecules.ChangeLanguageBlock")(WTChangeLanguageBlock);
