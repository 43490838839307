import styled from "@emotion/styled";
import { boxFrame, fontSize, fontWeight, rem } from "@/styleguide";
export var Profile = styled.div({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: boxFrame([35, 0, 40]),
}, function (props) { return ({
    backgroundColor: props.theme.backgroundColor,
}); });
export var UserName = styled.span({
    fontSize: rem(fontSize.medium),
    fontWeight: fontWeight.semiBold,
    marginTop: 15,
});
