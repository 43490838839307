var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { SearchInput } from "@/components/atoms/SearchInput";
import { ButtonWrapper, Toolbar } from "./styles";
export function EmployeesViewToolbar(_a) {
    var onChange = _a.onChange, props = __rest(_a, ["onChange"]);
    var t = useTranslation("EmployeeView.EmployeeViewToolbar").t;
    var onSearchChange = React.useCallback(function (evt) {
        onChange(evt.target.value);
    }, [onChange]);
    return (React.createElement(Toolbar, null,
        React.createElement(SearchInput, { name: "search", onChange: onSearchChange, onSearch: props.onSearch, placeholder: t("searchPlaceholder"), value: props.searchText, width: 780 }),
        props.canReset && (React.createElement(ButtonWrapper, null,
            React.createElement(Button, { onClick: props.onReset, theme: "dark", type: "button" }, t("clearFilters"))))));
}
