import React from "react";
import { Avatar } from "@/components/atoms/Avatar";
import { getAvatarPlaceholder } from "@/utils/misc";
import { Details, StyledRoot, ViewerName } from "./styles";
export function ViewerArea(props) {
    return (React.createElement(StyledRoot, { className: props.className },
        React.createElement(Avatar, { placeholder: getAvatarPlaceholder(props.viewerName), size: 30, theme: "primary" }),
        React.createElement(Details, null,
            React.createElement(ViewerName, null, props.viewerName))));
}
