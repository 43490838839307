import { colors } from "@/styleguide";
export var themes = {
    java: {
        color: colors.java,
        colorHover: colors.turquoise,
    },
    shipGrey: {
        color: colors.shipGrey,
        colorHover: colors.armadillo,
    },
};
