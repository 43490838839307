import styled from "@emotion/styled";
import { percent, colors, fontSize, boxFrame } from "@/styleguide";
export var Wrapper = styled.div({
    color: colors.grey,
    display: "inline-flex",
    flexDirection: "column",
    fontSize: fontSize.small,
    justifyContent: "center",
    textAlign: "left",
    wordBreak: "break-word",
}, function (props) { return ({
    width: percent(props.columnWidth || 10),
    padding: boxFrame([10, props.horizontalPadding]),
}); });
