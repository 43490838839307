var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useFormik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardSection } from "@/components/atoms/CardSection";
import { CardsRow } from "@/components/atoms/CardsRow";
import { FormField } from "@/components/atoms/FormField";
import { Gap } from "@/components/atoms/Gap";
import { TextInput } from "@/components/atoms/TextInput";
import { WithLabel } from "@/components/atoms/WithLabel";
import { CardsViewButtons } from "@/components/molecules/CardsViewButtons";
import { Checkbox } from "@/components/molecules/Checkbox";
import { FormStatus } from "@/components/molecules/FormStatus";
import { Select } from "@/components/molecules/Select";
import { ViewPath } from "@/constants/routing";
import { provinces } from "@/data/provinces";
import { isTrue } from "@/utils/core";
import { createSelectOptionsFromArray } from "@/utils/misc";
import { abortIf, abortIfOther, addressLineRules, adultDateOfBirthRules, cityRules, companyNameRules, companyRealCodeRules, createValidation, cucRules, emailRules, ibanRules, idPayrollItemRules, payrollFileFormatRules, personFirstNameRules, personLastNameRules, phoneNumberRules, postalCodeRules, requiredValidationRules, taxIdRules, } from "@/utils/validate";
import { FieldCell, FieldsRow, InputContent, SectionHeader } from "./styles";
var validate = createValidation({
    cuc: cucRules,
    companyRealCode: companyRealCodeRules,
    idPayrollItem: idPayrollItemRules,
    payrollFileFormat: payrollFileFormatRules,
    employerAddressCountryId: requiredValidationRules,
    employerAddressLineOne: addressLineRules,
    employerAddressPostCode: postalCodeRules,
    employerAddressPostTown: cityRules,
    employerAddressProvinceCode: __spreadArrays([
        [abortIfOther("employerAddressCountryId", function (v) { return v !== "IT"; })]
    ], requiredValidationRules),
    employerDateOfBirth: adultDateOfBirthRules,
    employerEmail: emailRules,
    employerFirstName: personFirstNameRules,
    employerLastName: personLastNameRules,
    employerMobileNumber: phoneNumberRules,
    iban: ibanRules,
    keyContactEmail: emailRules,
    keyContactFirstName: requiredValidationRules,
    keyContactLastName: requiredValidationRules,
    keyContactTitle: requiredValidationRules,
    name: companyNameRules,
    payDay: requiredValidationRules,
    registeredAddressCountryId: requiredValidationRules,
    registeredAddressLineOne: addressLineRules,
    registeredAddressPostCode: postalCodeRules,
    registeredAddressPostTown: cityRules,
    registeredAddressProvinceCode: __spreadArrays([
        [abortIfOther("registeredAddressCountryId", function (v) { return v !== "IT"; })]
    ], requiredValidationRules),
    sectorId: requiredValidationRules,
    taxId: taxIdRules,
    tradingAddressCountryId: __spreadArrays([
        [abortIfOther("copyRegisteredToTrading", isTrue)]
    ], requiredValidationRules),
    tradingAddressLineOne: __spreadArrays([[abortIfOther("copyRegisteredToTrading", isTrue)]], addressLineRules),
    tradingAddressPostCode: __spreadArrays([[abortIfOther("copyRegisteredToTrading", isTrue)]], postalCodeRules),
    tradingAddressPostTown: __spreadArrays([[abortIfOther("copyRegisteredToTrading", isTrue)]], cityRules),
    tradingAddressProvinceCode: __spreadArrays([
        [
            abortIf(function (_, values) { return values.copyRegisteredToTrading || values.tradingAddressCountryId !== "IT"; }),
        ]
    ], requiredValidationRules),
});
export function CompanyForm(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45;
    var onSubmit = _a.onSubmit, props = __rest(_a, ["onSubmit"]);
    var t = useTranslation("Molecules.CompanyForm").t;
    var sectorT = useTranslation("Sectors");
    var payDayOptions = [
        { value: "0", label: t("selectADay") },
        { value: "27", label: t("currentMonth27") },
        { value: "5", label: t("followingMonth5") },
    ];
    var payrollFileFormatOptions = [
        { value: "standard", label: t("payrollFileFormat-standard") },
        { value: "zucchetti", label: t("payrollFileFormat-zucchetti") },
    ];
    var _46 = useMemo(function () {
        var sectorOptions = createSelectOptionsFromArray(props.sectors.map(function (i) { return (__assign(__assign({}, i), { name: sectorT.t(i.name) })); }), "id", "name");
        return {
            countriesOptions: createSelectOptionsFromArray(props.countries, "code", "name"),
            provincesOptions: createSelectOptionsFromArray(provinces, "code", "name"),
            industryOptions: __spreadArrays([{ value: null, label: t("selectIndustry") }], sectorOptions),
        };
    }, [props.countries, props.sectors, t, sectorT]), countriesOptions = _46.countriesOptions, provincesOptions = _46.provincesOptions, industryOptions = _46.industryOptions;
    var handleOnSubmit = useCallback(function (values) {
        onSubmit(__assign(__assign({}, values), { employerAddressProvinceCode: values.employerAddressCountryId === "IT" ? values.employerAddressProvinceCode : t("na"), registeredAddressProvinceCode: values.registeredAddressCountryId === "IT"
                ? values.registeredAddressProvinceCode
                : t("na"), tradingAddressProvinceCode: values.tradingAddressCountryId === "IT" ? values.tradingAddressProvinceCode : t("na") }));
    }, [onSubmit, t]);
    var homeAddress = (_c = (_b = props.company) === null || _b === void 0 ? void 0 : _b.employer.homeAddress) !== null && _c !== void 0 ? _c : null;
    var _47 = useFormik({
        validate: validate,
        onSubmit: handleOnSubmit,
        initialValues: {
            companyCode: (_e = (_d = props.company) === null || _d === void 0 ? void 0 : _d.companyCode) !== null && _e !== void 0 ? _e : "",
            companyRealCode: (_g = (_f = props.company) === null || _f === void 0 ? void 0 : _f.companyRealCode) !== null && _g !== void 0 ? _g : "",
            idPayrollItem: (_j = (_h = props.company) === null || _h === void 0 ? void 0 : _h.idPayrollItem) !== null && _j !== void 0 ? _j : "",
            payrollFileFormat: (_l = (_k = props.company) === null || _k === void 0 ? void 0 : _k.payrollFileFormat) !== null && _l !== void 0 ? _l : "",
            copyRegisteredToTrading: (_o = (_m = props.company) === null || _m === void 0 ? void 0 : _m.copyRegisteredToTrading) !== null && _o !== void 0 ? _o : true,
            cuc: (_q = (_p = props.company) === null || _p === void 0 ? void 0 : _p.cucCode) !== null && _q !== void 0 ? _q : "",
            employerAddressCountryId: (_r = homeAddress === null || homeAddress === void 0 ? void 0 : homeAddress.country) !== null && _r !== void 0 ? _r : "",
            employerAddressLineOne: (_s = homeAddress === null || homeAddress === void 0 ? void 0 : homeAddress.lineOne) !== null && _s !== void 0 ? _s : "",
            employerAddressPostCode: (_t = homeAddress === null || homeAddress === void 0 ? void 0 : homeAddress.postCode) !== null && _t !== void 0 ? _t : "",
            employerAddressPostTown: (_u = homeAddress === null || homeAddress === void 0 ? void 0 : homeAddress.postTown) !== null && _u !== void 0 ? _u : "",
            employerAddressProvinceCode: (_v = homeAddress === null || homeAddress === void 0 ? void 0 : homeAddress.provinceCode) !== null && _v !== void 0 ? _v : "",
            employerDateOfBirth: (_x = (_w = props.company) === null || _w === void 0 ? void 0 : _w.employer.dateOfBirth) !== null && _x !== void 0 ? _x : "",
            employerEmail: (_z = (_y = props.company) === null || _y === void 0 ? void 0 : _y.employer.email) !== null && _z !== void 0 ? _z : "",
            employerFirstName: (_1 = (_0 = props.company) === null || _0 === void 0 ? void 0 : _0.employer.firstName) !== null && _1 !== void 0 ? _1 : "",
            employerLastName: (_3 = (_2 = props.company) === null || _2 === void 0 ? void 0 : _2.employer.lastName) !== null && _3 !== void 0 ? _3 : "",
            employerMobileNumber: (_5 = (_4 = props.company) === null || _4 === void 0 ? void 0 : _4.employer.mobileNumber) !== null && _5 !== void 0 ? _5 : "",
            iban: (_7 = (_6 = props.company) === null || _6 === void 0 ? void 0 : _6.iban) !== null && _7 !== void 0 ? _7 : "",
            keyContactEmail: (_9 = (_8 = props.company) === null || _8 === void 0 ? void 0 : _8.keyContactEmail) !== null && _9 !== void 0 ? _9 : "",
            keyContactFirstName: (_11 = (_10 = props.company) === null || _10 === void 0 ? void 0 : _10.keyContactFirstName) !== null && _11 !== void 0 ? _11 : "",
            keyContactLastName: (_13 = (_12 = props.company) === null || _12 === void 0 ? void 0 : _12.keyContactLastName) !== null && _13 !== void 0 ? _13 : "",
            keyContactTitle: (_15 = (_14 = props.company) === null || _14 === void 0 ? void 0 : _14.keyContactTitle) !== null && _15 !== void 0 ? _15 : "",
            name: (_17 = (_16 = props.company) === null || _16 === void 0 ? void 0 : _16.name) !== null && _17 !== void 0 ? _17 : "",
            payDay: String((_19 = (_18 = props.company) === null || _18 === void 0 ? void 0 : _18.payDay) !== null && _19 !== void 0 ? _19 : 5),
            registeredAddressCountryId: (_21 = (_20 = props.company) === null || _20 === void 0 ? void 0 : _20.registeredAddress.country) !== null && _21 !== void 0 ? _21 : "",
            registeredAddressLineOne: (_23 = (_22 = props.company) === null || _22 === void 0 ? void 0 : _22.registeredAddress.lineOne) !== null && _23 !== void 0 ? _23 : "",
            registeredAddressPostCode: (_25 = (_24 = props.company) === null || _24 === void 0 ? void 0 : _24.registeredAddress.postCode) !== null && _25 !== void 0 ? _25 : "",
            registeredAddressPostTown: (_27 = (_26 = props.company) === null || _26 === void 0 ? void 0 : _26.registeredAddress.postTown) !== null && _27 !== void 0 ? _27 : "",
            registeredAddressProvinceCode: (_29 = (_28 = props.company) === null || _28 === void 0 ? void 0 : _28.registeredAddress.provinceCode) !== null && _29 !== void 0 ? _29 : "",
            registrationNumber: (_31 = (_30 = props.company) === null || _30 === void 0 ? void 0 : _30.registrationNumber) !== null && _31 !== void 0 ? _31 : "",
            sectorId: ((_32 = props.company) === null || _32 === void 0 ? void 0 : _32.sector) ? (_33 = props.company) === null || _33 === void 0 ? void 0 : _33.sector.id : undefined !== null && undefined !== void 0 ? undefined : "",
            taxId: (_35 = (_34 = props.company) === null || _34 === void 0 ? void 0 : _34.taxId) !== null && _35 !== void 0 ? _35 : "",
            tradingAddressCountryId: (_37 = (_36 = props.company) === null || _36 === void 0 ? void 0 : _36.tradingAddress.country) !== null && _37 !== void 0 ? _37 : "",
            tradingAddressLineOne: (_39 = (_38 = props.company) === null || _38 === void 0 ? void 0 : _38.tradingAddress.lineOne) !== null && _39 !== void 0 ? _39 : "",
            tradingAddressPostCode: (_41 = (_40 = props.company) === null || _40 === void 0 ? void 0 : _40.tradingAddress.postCode) !== null && _41 !== void 0 ? _41 : "",
            tradingAddressPostTown: (_43 = (_42 = props.company) === null || _42 === void 0 ? void 0 : _42.tradingAddress.postTown) !== null && _43 !== void 0 ? _43 : "",
            tradingAddressProvinceCode: (_45 = (_44 = props.company) === null || _44 === void 0 ? void 0 : _44.tradingAddress.provinceCode) !== null && _45 !== void 0 ? _45 : "",
        },
    }), errors = _47.errors, touched = _47.touched, handleBlur = _47.handleBlur, handleChange = _47.handleChange, handleSubmit = _47.handleSubmit, setFieldValue = _47.setFieldValue, values = _47.values;
    var _48 = useMemo(function () {
        var _a, _b;
        if (!values.copyRegisteredToTrading) {
            return {
                tradingAddressCountryName: null,
                tradingAddressProvinceName: null,
            };
        }
        var tradingAddressCountry = props.countries.find(function (country) {
            return country.code === values.registeredAddressCountryId;
        });
        var tradingAddressProvince = provinces.find(function (province) {
            return province.code === values.registeredAddressProvinceCode;
        });
        return {
            tradingAddressCountryName: (_a = tradingAddressCountry === null || tradingAddressCountry === void 0 ? void 0 : tradingAddressCountry.name) !== null && _a !== void 0 ? _a : null,
            tradingAddressProvinceName: (_b = tradingAddressProvince === null || tradingAddressProvince === void 0 ? void 0 : tradingAddressProvince.name) !== null && _b !== void 0 ? _b : null,
        };
    }, [
        props.countries,
        values.registeredAddressCountryId,
        values.registeredAddressProvinceCode,
        values.copyRegisteredToTrading,
    ]), tradingAddressCountryName = _48.tradingAddressCountryName, tradingAddressProvinceName = _48.tradingAddressProvinceName;
    return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(CardsRow, { cardsCount: 2 },
            React.createElement(CardSection, null,
                React.createElement(CardSection.Heading, null, t("companyDetails")),
                React.createElement(CardSection.Subheading, null, t("fieldsAreMandatory")),
                React.createElement(Gap, { className: "mt-25", direction: "y", size: 15 },
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.name ? errors.name : undefined, label: t("companyLabel") },
                                React.createElement(TextInput, { name: "name", onBlur: handleBlur, onChange: handleChange, placeholder: t("companyPlaceholder"), value: values.name }))),
                        props.company && (React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(WithLabel, { label: t("companyIdLabel") },
                                React.createElement(InputContent, null, values.companyCode))))),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.companyRealCode ? errors.companyRealCode : undefined, label: t("companyRealCode") },
                                React.createElement(TextInput, { name: "companyRealCode", onBlur: handleBlur, onChange: handleChange, placeholder: t("companyRealCode"), value: values.companyRealCode }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.idPayrollItem ? errors.idPayrollItem : undefined, label: t("idPayrollItem") },
                                React.createElement(TextInput, { name: "idPayrollItem", onBlur: handleBlur, onChange: handleChange, placeholder: t("idPayrollItem"), value: values.idPayrollItem })))),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.payrollFileFormat ? errors.payrollFileFormat : undefined, label: t("payrollFileFormat") },
                                React.createElement(Select, { name: "payrollFileFormat", onBlur: handleBlur, onChange: setFieldValue, options: payrollFileFormatOptions, placeholder: t("payrollFileFormat"), value: values.payrollFileFormat })))),
                    React.createElement(FormField, { error: touched.sectorId ? errors.sectorId : undefined, label: t("industryLabel") },
                        React.createElement(Select, { name: "sectorId", onBlur: handleBlur, onChange: setFieldValue, options: industryOptions, placeholder: t("industryPlaceholder"), value: values.sectorId })),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.registrationNumber ? errors.registrationNumber : undefined, label: t("vatLabel") },
                                React.createElement(TextInput, { name: "registrationNumber", onBlur: handleBlur, onChange: handleChange, placeholder: t("vatPlaceholder"), value: values.registrationNumber }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.payDay ? errors.payDay : undefined, label: t("paydayDateLabel") },
                                React.createElement(Select, { name: "payDay", onBlur: handleBlur, onChange: setFieldValue, options: payDayOptions, placeholder: t("paydayDatePlaceholder"), value: values.payDay })))),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.cuc ? errors.cuc : undefined, label: t("cucNumberLabel") },
                                React.createElement(TextInput, { name: "cuc", onBlur: handleBlur, onChange: handleChange, placeholder: t("cucNumberPlaceholder"), value: values.cuc }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.taxId ? errors.taxId : undefined, label: t("taxIdLabel") },
                                React.createElement(TextInput, { name: "taxId", onBlur: handleBlur, onChange: handleChange, placeholder: t("taxIdPlaceholder"), value: values.taxId })))),
                    React.createElement(FormField, { error: touched.iban ? errors.iban : undefined, label: t("ibanLabel") },
                        React.createElement(TextInput, { name: "iban", onBlur: handleBlur, onChange: handleChange, placeholder: t("taxIdPlaceholder"), value: values.iban })),
                    React.createElement(SectionHeader, null, t("registeredAddress")),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.registeredAddressCountryId
                                    ? errors.registeredAddressCountryId
                                    : undefined, label: t("countryLabel") },
                                React.createElement(Select, { name: "registeredAddressCountryId", onBlur: handleBlur, onChange: setFieldValue, options: countriesOptions, placeholder: t("countryPlaceholder"), value: values.registeredAddressCountryId }))),
                        values.registeredAddressCountryId === "IT" && (React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.registeredAddressProvinceCode
                                    ? errors.registeredAddressProvinceCode
                                    : undefined, label: t("provinceLabel") },
                                React.createElement(Select, { name: "registeredAddressProvinceCode", onBlur: handleBlur, onChange: setFieldValue, options: provincesOptions, placeholder: t("provincePlaceholder"), value: values.registeredAddressProvinceCode }))))),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.registeredAddressPostTown ? errors.registeredAddressPostTown : undefined, label: t("cityLabel") },
                                React.createElement(TextInput, { name: "registeredAddressPostTown", onBlur: handleBlur, onChange: handleChange, placeholder: t("cityPlaceholder"), value: values.registeredAddressPostTown }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.registeredAddressPostCode ? errors.registeredAddressPostCode : undefined, label: t("postalCodeLabel") },
                                React.createElement(TextInput, { name: "registeredAddressPostCode", onBlur: handleBlur, onChange: handleChange, placeholder: t("postalCodePlaceholder"), value: values.registeredAddressPostCode })))),
                    React.createElement(FormField, { error: touched.registeredAddressLineOne ? errors.registeredAddressLineOne : undefined, label: t("addressLabel") },
                        React.createElement(TextInput, { name: "registeredAddressLineOne", onBlur: handleBlur, onChange: handleChange, placeholder: t("addressPlaceholder"), value: values.registeredAddressLineOne })),
                    React.createElement(Checkbox, { description: t("copyRegisteredToTradingLabel"), id: "copyRegisteredToTrading", name: "copyRegisteredToTrading", onBlur: handleBlur, onChange: handleChange, size: "small", value: values.copyRegisteredToTrading }),
                    React.createElement(SectionHeader, null, t("tradingAddress")),
                    values.copyRegisteredToTrading ? (React.createElement(React.Fragment, null,
                        React.createElement(FieldsRow, null,
                            React.createElement(FieldCell, null,
                                React.createElement(WithLabel, { label: t("countryLabel") },
                                    React.createElement(InputContent, null, tradingAddressCountryName))),
                            values.registeredAddressCountryId === "IT" && (React.createElement(FieldCell, { className: "ml-15" },
                                React.createElement(WithLabel, { label: t("provinceLabel") },
                                    React.createElement(InputContent, null, tradingAddressProvinceName))))),
                        React.createElement(FieldsRow, null,
                            React.createElement(FieldCell, null,
                                React.createElement(WithLabel, { label: t("cityLabel") },
                                    React.createElement(InputContent, null, values.registeredAddressPostTown))),
                            React.createElement(FieldCell, { className: "ml-15" },
                                React.createElement(WithLabel, { label: t("postalCodeLabel") },
                                    React.createElement(InputContent, null, values.registeredAddressPostCode)))),
                        React.createElement(WithLabel, { label: t("addressLabel") },
                            React.createElement(InputContent, null, values.registeredAddressLineOne)))) : (React.createElement(React.Fragment, null,
                        React.createElement(FieldsRow, null,
                            React.createElement(FieldCell, null,
                                React.createElement(FormField, { error: touched.tradingAddressCountryId ? errors.tradingAddressCountryId : undefined, label: t("countryLabel") },
                                    React.createElement(Select, { name: "tradingAddressCountryId", onBlur: handleBlur, onChange: setFieldValue, options: countriesOptions, placeholder: t("countryPlaceholder"), value: values.tradingAddressCountryId }))),
                            values.tradingAddressCountryId === "IT" && (React.createElement(FieldCell, { className: "ml-15" },
                                React.createElement(FormField, { error: touched.tradingAddressProvinceCode
                                        ? errors.tradingAddressProvinceCode
                                        : undefined, label: t("provinceLabel") },
                                    React.createElement(Select, { name: "tradingAddressProvinceCode", onBlur: handleBlur, onChange: setFieldValue, options: provincesOptions, placeholder: t("provincePlaceholder"), value: values.tradingAddressProvinceCode }))))),
                        React.createElement(FieldsRow, null,
                            React.createElement(FieldCell, null,
                                React.createElement(FormField, { error: touched.tradingAddressPostTown ? errors.tradingAddressPostTown : undefined, label: t("cityLabel") },
                                    React.createElement(TextInput, { name: "tradingAddressPostTown", onBlur: handleBlur, onChange: handleChange, placeholder: t("cityPlaceholder"), value: values.tradingAddressPostTown }))),
                            React.createElement(FieldCell, { className: "ml-15" },
                                React.createElement(FormField, { error: touched.tradingAddressPostCode ? errors.tradingAddressPostCode : undefined, label: t("postalCodeLabel") },
                                    React.createElement(TextInput, { name: "tradingAddressPostCode", onBlur: handleBlur, onChange: handleChange, placeholder: t("postalCodePlaceholder"), value: values.tradingAddressPostCode })))),
                        React.createElement(FormField, { error: touched.tradingAddressLineOne ? errors.tradingAddressLineOne : undefined, label: t("addressLabel") },
                            React.createElement(TextInput, { name: "tradingAddressLineOne", onBlur: handleBlur, onChange: handleChange, placeholder: t("addressPlaceholder"), value: values.tradingAddressLineOne })))))),
            React.createElement(CardSection, null,
                React.createElement(CardSection.Heading, null, t("businessOwnerDetails")),
                React.createElement(CardSection.Subheading, null, t("fieldsAreMandatory")),
                React.createElement(Gap, { className: "mt-25", direction: "y", size: 15 },
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.employerFirstName ? errors.employerFirstName : undefined, label: t("firstNameLabel") },
                                React.createElement(TextInput, { name: "employerFirstName", onBlur: handleBlur, onChange: handleChange, placeholder: t("firstNamePlaceholder"), value: values.employerFirstName }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.employerLastName ? errors.employerLastName : undefined, label: t("lastNameLabel") },
                                React.createElement(TextInput, { name: "employerLastName", onBlur: handleBlur, onChange: handleChange, placeholder: t("lastNamePlaceholder"), value: values.employerLastName })))),
                    React.createElement(FormField, { error: touched.employerEmail ? errors.employerEmail : undefined, label: t("emailLabel") },
                        React.createElement(TextInput, { name: "employerEmail", onBlur: handleBlur, onChange: handleChange, placeholder: t("emailPlaceholder"), value: values.employerEmail })),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.employerDateOfBirth ? errors.employerDateOfBirth : undefined, label: t("birthDateLabel") },
                                React.createElement(TextInput, { name: "employerDateOfBirth", onBlur: handleBlur, onChange: handleChange, placeholder: t("birthDatePlaceholder"), value: values.employerDateOfBirth }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.employerMobileNumber ? errors.employerMobileNumber : undefined, label: t("phoneLabel") },
                                React.createElement(TextInput, { name: "employerMobileNumber", onBlur: handleBlur, onChange: handleChange, placeholder: t("phonePlaceholder"), value: values.employerMobileNumber })))),
                    React.createElement(SectionHeader, null, t("businessOwnerAddress")),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.employerAddressCountryId ? errors.employerAddressCountryId : undefined, label: t("countryLabel") },
                                React.createElement(Select, { name: "employerAddressCountryId", onBlur: handleBlur, onChange: setFieldValue, options: countriesOptions, placeholder: t("countryPlaceholder"), value: values.employerAddressCountryId }))),
                        values.employerAddressCountryId === "IT" && (React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.employerAddressProvinceCode
                                    ? errors.employerAddressProvinceCode
                                    : undefined, label: t("provinceLabel") },
                                React.createElement(Select, { name: "employerAddressProvinceCode", onBlur: handleBlur, onChange: setFieldValue, options: provincesOptions, placeholder: t("provincePlaceholder"), value: values.employerAddressProvinceCode }))))),
                    React.createElement(FieldsRow, null,
                        React.createElement(FieldCell, null,
                            React.createElement(FormField, { error: touched.employerAddressPostTown ? errors.employerAddressPostTown : undefined, label: t("cityLabel") },
                                React.createElement(TextInput, { name: "employerAddressPostTown", onBlur: handleBlur, onChange: handleChange, placeholder: t("cityPlaceholder"), value: values.employerAddressPostTown }))),
                        React.createElement(FieldCell, { className: "ml-15" },
                            React.createElement(FormField, { error: touched.employerAddressPostCode ? errors.employerAddressPostCode : undefined, label: t("postalCodeLabel") },
                                React.createElement(TextInput, { name: "employerAddressPostCode", onBlur: handleBlur, onChange: handleChange, placeholder: t("postalCodePlaceholder"), value: values.employerAddressPostCode })))),
                    React.createElement(FormField, { error: touched.employerAddressLineOne ? errors.employerAddressLineOne : undefined, label: t("addressLabel") },
                        React.createElement(TextInput, { name: "employerAddressLineOne", onBlur: handleBlur, onChange: handleChange, placeholder: t("addressPlaceholder"), value: values.employerAddressLineOne })),
                    React.createElement(SectionHeader, null, t("keyContactDetails")),
                    React.createElement(Gap, { className: "mt-25", direction: "y", size: 15 },
                        React.createElement(FieldsRow, null,
                            React.createElement(FieldCell, null,
                                React.createElement(FormField, { error: touched.keyContactFirstName ? errors.keyContactFirstName : undefined, label: t("firstNameLabel") },
                                    React.createElement(TextInput, { name: "keyContactFirstName", onBlur: handleBlur, onChange: handleChange, placeholder: t("firstNamePlaceholder"), value: values.keyContactFirstName }))),
                            React.createElement(FieldCell, { className: "ml-15" },
                                React.createElement(FormField, { error: touched.keyContactLastName ? errors.keyContactLastName : undefined, label: t("lastNameLabel") },
                                    React.createElement(TextInput, { name: "keyContactLastName", onBlur: handleBlur, onChange: handleChange, placeholder: t("lastNamePlaceholder"), value: values.keyContactLastName })))),
                        React.createElement(FieldsRow, null,
                            React.createElement(FieldCell, null,
                                React.createElement(FormField, { error: touched.keyContactTitle ? errors.keyContactTitle : undefined, label: t("jobTitleLabel") },
                                    React.createElement(TextInput, { name: "keyContactTitle", onBlur: handleBlur, onChange: handleChange, placeholder: t("jobTitlePlaceholder"), value: values.keyContactTitle }))),
                            React.createElement(FieldCell, { className: "ml-15" },
                                React.createElement(FormField, { error: touched.keyContactEmail ? errors.keyContactEmail : undefined, label: t("emailLabel") },
                                    React.createElement(TextInput, { name: "keyContactEmail", onBlur: handleBlur, onChange: handleChange, placeholder: t("emailPlaceholder"), value: values.keyContactEmail })))))))),
        React.createElement(CardsViewButtons, { cancelHref: ViewPath.COMPANIES, isFormSubmitting: props.submitting }),
        React.createElement(FormStatus, { message: t(props.statusMessage, values), severity: props.statusSeverity })));
}
