import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Th } from "@/components/molecules/Table";
import { formatMoney, formatTime, formatDate } from "@/utils/format";
import { BoldText, Cell, GreySmallText, Row } from "./styles";
export var ReportsTable = function (props) {
    var t = useTranslation("CompaniesReportsView.ReportsTable").t;
    return (React.createElement(Table, { cols: [22, 22, 22, 22, 10] },
        React.createElement(React.Fragment, null),
        React.createElement(Thead, null,
            React.createElement(Th, null, t("date")),
            React.createElement(Th, null, t("companyName")),
            React.createElement(Th, null, t("employeeName")),
            React.createElement(Th, null, t("transactionId")),
            React.createElement(Th, null, t("amount"))),
        React.createElement(Tbody, null, props.transactions.map(function (transaction, index) { return (React.createElement(Row, { key: index },
            React.createElement(Cell, null,
                React.createElement(BoldText, null, formatDate(transaction.date)),
                React.createElement(GreySmallText, null, formatTime(transaction.date))),
            React.createElement(Cell, null, transaction.companyName),
            React.createElement(Cell, null,
                React.createElement(BoldText, null,
                    transaction.firstName,
                    " ",
                    transaction.lastName)),
            React.createElement(Cell, null, transaction.id),
            React.createElement(Cell, null,
                React.createElement(BoldText, null, formatMoney(transaction.amount))))); }))));
};
