import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { fontSize, fontWeight, rem } from "@/styleguide";
export var OuterContainer = styled.div({});
export var InnerContainer = styled.div({
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    minHeight: "100vh",
    paddingLeft: 30,
    paddingRight: 30,
    width: 1170,
});
export var LogoLink = styled(Link)({
    lineHeight: 0,
});
export var ContentColumn = styled.div({
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    justifyContent: "center",
    paddingBottom: 30,
    paddingTop: 30,
});
export var ContentWrapper = styled.div({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: 45,
});
export var Header = styled.header({
    fontSize: rem(fontSize.large),
    fontWeight: fontWeight.bold,
});
