import styled from "@emotion/styled";
import { transparentize } from "polished";
import { colors, fontSize, fontWeight } from "@/styleguide";
var HORIZONTAL_PADDING = 60;
export var StyledRoot = styled.div({
    padding: "10px " + HORIZONTAL_PADDING + "px 40px",
    ".input-range__slider": {
        appearance: "none",
        background: colors.white,
        borderRadius: "100%",
        boxShadow: "0 0 35px " + transparentize(0.85, colors.shipGrey),
        cursor: "pointer",
        display: "block",
        height: 44,
        marginLeft: -22,
        marginTop: -31,
        outline: "none",
        position: "absolute",
        width: 44,
    },
    ".input-range__label": {
        color: colors.shipGrey,
        fontSize: fontSize.small,
        fontWeight: fontWeight.bold,
        whiteSpace: "nowrap",
    },
    ".input-range__label--min, .input-range__label--max": {
        position: "absolute",
        top: 4,
    },
    ".input-range__label--min": {
        left: -HORIZONTAL_PADDING,
    },
    ".input-range__label--max": {
        right: -HORIZONTAL_PADDING,
    },
    ".input-range__label--value": {
        position: "absolute",
        top: 20,
        transform: "translateX(-50%)",
    },
    ".input-range__label-container": {
        position: "relative",
    },
    ".input-range__track": {
        background: colors.alabaster,
        borderRadius: 9,
        cursor: "pointer",
        display: "block",
        height: 18,
        position: "relative",
    },
    ".input-range__track--background": {
        left: 0,
        marginTop: -4,
        position: "absolute",
        right: 0,
        top: "50%",
    },
    ".input-range__track--active": {
        background: colors.java,
    },
    ".input-range": {
        height: 16,
        position: "relative",
        width: "100%",
    },
});
