import styled from "@emotion/styled";
import { colors } from "@/styleguide";
export var SwitchWrapper = styled.label({
    backgroundColor: colors.alabaster,
    border: "1px solid " + colors.alto,
    borderRadius: 15,
    cursor: "pointer",
    height: 25,
    position: "relative",
    width: 55,
});
export var Input = styled.input({
    display: "none",
});
export var SwitchHandleCircle = styled.div({
    borderRadius: 15,
    display: "inline-block",
    height: 30,
    position: "absolute",
    top: -4,
    width: 30,
}, function (props) { return ({
    backgroundColor: props.isChecked ? colors.java : colors.alto,
    right: props.isChecked ? -1 : "unset",
    left: props.isChecked ? "unset" : -1,
}); });
