import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Th } from "@/components/molecules/Table";
import { formatMoney, formatTime, formatDate } from "@/utils/format";
import { BoldText, Cell, CellAlignRight, GreySmallText, Row } from "./styles";
export var ReportsTable = function (props) {
    var t = useTranslation("EmployeesReportsView.ReportsTable").t;
    return (React.createElement(Table, { cols: [20, 65, 15] },
        React.createElement(React.Fragment, null),
        React.createElement(Thead, null,
            React.createElement(Th, null, t("timeStamp")),
            React.createElement(Th, null, t("transactionId")),
            React.createElement(Th, null, t("amountAdvanced"))),
        React.createElement(Tbody, null, props.transactions.map(function (transaction, index) {
            var parsed = moment.utc(transaction.date, "YYYY-MM-DD HH:mm:ss");
            return (React.createElement(Row, { key: index },
                React.createElement(Cell, null,
                    React.createElement(BoldText, null, formatDate(transaction.date)),
                    React.createElement(GreySmallText, null, formatTime(parsed.toDate()))),
                React.createElement(Cell, null,
                    React.createElement(BoldText, null, transaction.id)),
                React.createElement(CellAlignRight, null,
                    React.createElement(BoldText, null, formatMoney(transaction.amount)))));
        }))));
};
