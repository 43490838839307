export var colors = {
    alabaster: "#F7F7F7",
    alto: "#D8D8D8",
    armadillo: "#4A4A4A",
    buttercup: "#F5A623",
    dustyGrey: "#9B9B9B",
    elm: "#1C8B8A",
    grey: "#888888",
    java: "#26CEBC",
    red: "#F52323",
    shipGrey: "#424149",
    sweetCorn: "#F7E477",
    sweetPink: "#EA9999",
    turquoise: "#30E2CF",
    white: "#FFFFFF",
};
export var fontFamily = {
    Montserrat: "Montserrat",
};
export var fontWeight = {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
};
export var fontSize = {
    giant: 90,
    huge: 70,
    xLarge: 40,
    large: 30,
    big: 24,
    medium: 20,
    regular: 16,
    small: 14,
    tiny: 11,
};
export var ZIndex;
(function (ZIndex) {
    ZIndex[ZIndex["DEFAULT"] = 1] = "DEFAULT";
    ZIndex[ZIndex["MOBILE_MENU"] = 2] = "MOBILE_MENU";
    ZIndex[ZIndex["APP_HEADER"] = 3] = "APP_HEADER";
    ZIndex[ZIndex["HINT"] = 4] = "HINT";
    ZIndex[ZIndex["NOTIFIER"] = 5] = "NOTIFIER";
    ZIndex[ZIndex["MODAL_OVERLAY"] = 6] = "MODAL_OVERLAY";
    ZIndex[ZIndex["MODAL"] = 7] = "MODAL";
})(ZIndex || (ZIndex = {}));
var valueWithUnit = function (value, unit) {
    return !value ? "0" : "" + value + unit;
};
export var px = function (value) {
    return valueWithUnit(value, "px");
};
export var percent = function (value) {
    return valueWithUnit(value, "%");
};
export var boxFrame = function (box) {
    return box.map(px).join(" ");
};
export var pxToRem = function (valueInPx) {
    /**
     * We keep default value of 16px as the base font size.
     * It may vary on different devices, but using rem values
     * will allow use to keep different font sizes scale.
     */
    return valueInPx / 16;
};
export var rem = function (valueInPx) {
    var valueInRem = pxToRem(valueInPx);
    return valueInRem + "rem";
};
