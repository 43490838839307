var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Button } from "@/components/atoms/Button";
import { FileUpload } from "@/components/molecules/FileUpload";
import { Modal } from "@/components/molecules/Modal";
import { EmployeesUpload } from "@/components/organisms/EmployeesUpload";
import { getAuthContext } from "@/utils/auth";
import { storage } from "@/utils/storage";
import { ContentWrapper, FilesList, ReviewButtonWrapper } from "./styles";
export function EmployeesUploadModal(_a) {
    var _this = this;
    var onConfirm = _a.onConfirm, onClose = _a.onClose;
    var t = useTranslation("EmployeesUploadModal").t;
    var uploadsRef = useRef([]);
    var _b = useState([]), uploads = _b[0], setUploads = _b[1];
    var isOrWillBeinTheDOM = useRef(true);
    uploadsRef.current = uploads;
    useEffect(function () {
        return function () {
            isOrWillBeinTheDOM.current = false;
            uploadsRef.current.forEach(function (upload) {
                var _a;
                (_a = upload.cancellable) === null || _a === void 0 ? void 0 : _a.cancel();
            });
        };
    }, []);
    var handleFiles = function (files) { return __awaiter(_this, void 0, void 0, function () {
        var newUploads, authContext, headers, _loop_1, _i, newUploads_1, upload, state_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    newUploads = files.map(function (file) { return ({
                        file: file,
                        id: uuid(),
                        cancellable: Axios.CancelToken.source(),
                        completed: false,
                        data: null,
                        error: null,
                    }); });
                    setUploads(newUploads);
                    authContext = getAuthContext();
                    headers = (_a = authContext === null || authContext === void 0 ? void 0 : authContext.headers) !== null && _a !== void 0 ? _a : {};
                    _loop_1 = function (upload) {
                        var errorMessage, uploadData, formData, res, err_1, res, responseErrorMessage, indexToUpdate;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    errorMessage = null;
                                    uploadData = null;
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    formData = new FormData();
                                    formData.append("file", upload.file);
                                    return [4 /*yield*/, Axios.post(process.env.REST_API_URL + "/users/files/validate", formData, {
                                            headers: headers,
                                            method: "post",
                                            cancelToken: (_b = upload.cancellable) === null || _b === void 0 ? void 0 : _b.token,
                                        })];
                                case 2:
                                    res = _a.sent();
                                    uploadData = res.data.data;
                                    return [3 /*break*/, 4];
                                case 3:
                                    err_1 = _a.sent();
                                    errorMessage = err_1.message;
                                    if (err_1.response) {
                                        res = err_1.response;
                                        responseErrorMessage = res.data ? res.data.error : res.error;
                                        errorMessage = responseErrorMessage;
                                    }
                                    return [3 /*break*/, 4];
                                case 4:
                                    if (!isOrWillBeinTheDOM.current) {
                                        return [2 /*return*/, { value: void 0 }];
                                    }
                                    newUploads = __spreadArrays(newUploads);
                                    indexToUpdate = newUploads.findIndex(function (_a) {
                                        var id = _a.id;
                                        return id === upload.id;
                                    });
                                    newUploads[indexToUpdate] = __assign(__assign({}, newUploads[indexToUpdate]), { cancellable: null, completed: true, data: uploadData, error: errorMessage });
                                    setUploads(newUploads);
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _i = 0, newUploads_1 = newUploads;
                    _c.label = 1;
                case 1:
                    if (!(_i < newUploads_1.length)) return [3 /*break*/, 4];
                    upload = newUploads_1[_i];
                    return [5 /*yield**/, _loop_1(upload)];
                case 2:
                    state_1 = _c.sent();
                    if (typeof state_1 === "object")
                        return [2 /*return*/, state_1.value];
                    _c.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleAbort = function (uploadId) {
        var _a;
        var uploadToAbort = uploads.find(function (upload) { return upload.id === uploadId; });
        (_a = uploadToAbort === null || uploadToAbort === void 0 ? void 0 : uploadToAbort.cancellable) === null || _a === void 0 ? void 0 : _a.cancel();
    };
    var handleConfirm = function () {
        var data = uploads.reduce(function (acc, upload) {
            if (!Array.isArray(upload.data)) {
                return acc;
            }
            return __spreadArrays(acc, upload.data);
        }, []);
        storage.setItem("EmployeesUpload", data);
        onConfirm();
    };
    var allCompleted = uploads.every(function (upload) { return upload.completed; });
    return (React.createElement(Modal, { closable: true, onModalClose: onClose, size: "medium", title: t("uploadFiles") },
        React.createElement(ContentWrapper, null, uploads.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(FilesList, null, uploads.map(function (upload) { return (React.createElement(FileUpload, { error: upload.error, fileName: upload.file.name, key: upload.id, onAbort: handleAbort, progress: upload.completed ? 1 : 0, uploadId: upload.id })); })),
            React.createElement(ReviewButtonWrapper, null,
                React.createElement(Button, { disabled: !allCompleted, onClick: handleConfirm, type: "button", width: 300 }, t("reviewEmployeeData"))))) : (React.createElement(EmployeesUpload, { onFiles: handleFiles })))));
}
