import styled from "@emotion/styled";
export var LinkWrapper = styled.div({
    marginTop: 18,
});
export var ButtonWrapper = styled.div({
    display: "flex",
    flexDirection: "column",
    marginTop: 45,
});
export var FormMessageWrapper = styled.div({
    marginTop: 10,
});
