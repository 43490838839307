import React from "react";
import { Wrapper } from "./styles";
export var Thead = function (props) {
    var cols = props.cols || [];
    return (React.createElement(Wrapper, null, React.Children.map(props.children, function (child, index) {
        if (!child) {
            return null;
        }
        var columnWidth = cols[index];
        if (columnWidth === undefined) {
            columnWidth = 10;
        }
        return React.cloneElement(child, { columnWidth: columnWidth });
    })));
};
