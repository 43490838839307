var InMemoryStorage = /** @class */ (function () {
    function InMemoryStorage() {
        var _this = this;
        this.cache = {};
        this.setItem = function (key, value) {
            _this.cache[key] = value;
        };
        this.getItem = function (key) {
            return _this.cache[key];
        };
        this.removeItem = function (key) {
            delete _this.cache[key];
        };
    }
    return InMemoryStorage;
}());
export var storage = new InMemoryStorage();
