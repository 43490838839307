import styled from "@emotion/styled";
import { colors, fontSize, fontWeight, rem } from "@/styleguide";
export var TextHeader = styled.h2({
    color: colors.armadillo,
    fontSize: rem(fontSize.medium),
    fontWeight: fontWeight.bold,
});
export var ContentText = styled.div({
    display: "flex",
    flex: 1,
    flexDirection: "column",
});
export var Paragraph = styled.p({
    color: colors.grey,
    fontSize: rem(fontSize.regular),
    fontWeight: fontWeight.regular,
    marginTop: 15,
});
export var Bold = styled.span({
    fontWeight: fontWeight.bold,
});
export var ButtonWrapper = styled.div({
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
});
export var Indent = styled.div({
    paddingLeft: 10,
});
