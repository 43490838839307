export var sizes = {
    medium: {
        height: 530,
        width: 620,
    },
    small: {
        height: 220,
        width: 460,
    },
};
