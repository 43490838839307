import React from "react";
import { Avatar } from "@/components/atoms/Avatar";
import { getAvatarPlaceholder } from "@/utils/misc";
import { Profile, UserName } from "./styles";
import { themes } from "./themes";
export var UserProfile = function (props) {
    var theme = themes[props.theme];
    return (React.createElement(Profile, { theme: theme },
        React.createElement(Avatar, { placeholder: getAvatarPlaceholder(props.firstName, props.lastName), size: 100, theme: "primary" }),
        React.createElement(UserName, null,
            props.firstName,
            " ",
            props.lastName)));
};
