import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { useUpdateWithdrawalSettings } from "@/containers/mutations/UpdateWithdrawalSettings";
import { useCompanyQuery, refetchCompanyQuery } from "@/containers/queries/CompanyQuery";
import { useViewer } from "@/hooks/useViewer";
import { formatErrorMessage } from "@/utils/format";
import { WithdrawalSettingsForm } from "./WithdrawalSettingsForm";
export function WithdrawalSettingsView() {
    var _a, _b;
    var t = useTranslation("WithdrawalSettingsView").t;
    var viewer = useViewer().viewer;
    var companyId = (_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
    if (companyId === null) {
        throw new Error(t("cannotRetriveCompanyId"));
    }
    var companyQuery = useCompanyQuery({ companyId: companyId });
    var _c = useUpdateWithdrawalSettings({
        awaitRefetchQueries: true,
        refetchQueries: function () { return [refetchCompanyQuery({ companyId: companyId })]; },
        successMessage: t("configurationsSavedSuccessfully"),
    }), updateWithdrawalSettings = _c[0], updateWithdrawalSettingsMeta = _c[1];
    var onSettingsSave = useCallback(function (values) {
        updateWithdrawalSettings({
            id: companyId,
            transactionFee: Number(values.transactionFee),
            withdrawalLimit: Number(values.withdrawalLimit),
            transactionsLimit: Number(values.transactionsLimit),
        });
    }, [updateWithdrawalSettings, companyId]);
    return (React.createElement(ProtectedView, { pageTitle: t("pageTitle") }, (function () {
        var _a;
        var company = (_a = companyQuery.data) === null || _a === void 0 ? void 0 : _a.company;
        if (companyQuery.loading || !company) {
            return t("loading");
        }
        if (companyQuery.error) {
            return formatErrorMessage(companyQuery.error);
        }
        return (React.createElement(WithdrawalSettingsForm, { company: company, formMessage: updateWithdrawalSettingsMeta.message, formStatus: updateWithdrawalSettingsMeta.severity, loading: updateWithdrawalSettingsMeta.loading, onSubmit: onSettingsSave }));
    })()));
}
