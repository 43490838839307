import styled from "@emotion/styled";
import { colors } from "@/styleguide";
var CONTROL_SIZE = 5;
var Control = styled.span({
    borderLeftColor: "transparent",
    borderLeftWidth: CONTROL_SIZE,
    borderRightColor: "transparent",
    borderRightWidth: CONTROL_SIZE,
    borderStyle: "solid",
});
export var ControlUp = styled(Control)({
    borderBottomColor: colors.java,
    borderBottomWidth: CONTROL_SIZE,
    borderTopColor: "transparent",
    borderTopWidth: 0,
    marginBottom: 2,
});
export var ControlDown = styled(Control)({
    borderBottomColor: "transparent",
    borderBottomWidth: 0,
    borderTopColor: colors.java,
    borderTopWidth: CONTROL_SIZE,
});
