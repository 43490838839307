var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import noop from "lodash/noop";
import React from "react";
var Context = React.createContext({
    message: null,
    notify: noop,
    onClose: noop,
    severity: null,
});
var Provider = /** @class */ (function (_super) {
    __extends(Provider, _super);
    function Provider(props) {
        var _this = _super.call(this, props) || this;
        _this.clearNotification = function () {
            _this.setState({
                message: null,
                severity: null,
            });
        };
        _this.notify = function (message, options) {
            if (options === void 0) { options = {}; }
            var _a = options.severity, severity = _a === void 0 ? "success" : _a, _b = options.timeout, timeout = _b === void 0 ? 5000 : _b;
            if (_this.timeoutId) {
                clearTimeout(_this.timeoutId);
            }
            _this.setState({
                message: message,
                severity: severity,
            });
            _this.timeoutId = setTimeout(_this.clearNotification, timeout);
        };
        _this.onClose = function () {
            if (_this.state.message === null) {
                return;
            }
            _this.setState({
                message: null,
            });
        };
        _this.state = {
            message: null,
            severity: null,
        };
        return _this;
    }
    Provider.prototype.componentWillUnmount = function () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    };
    Provider.prototype.render = function () {
        var providedValue = {
            message: this.state.message,
            notify: this.notify,
            onClose: this.onClose,
            severity: this.state.severity,
        };
        return React.createElement(Context.Provider, __assign({}, this.props, { value: providedValue }));
    };
    return Provider;
}(React.Component));
export var NotifierContext = {
    Context: Context,
    Provider: Provider,
    Consumer: Context.Consumer,
};
