var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, } from "@apollo/client";
import { useCallback } from "react";
import { apolloClient } from "@/apollo";
import { getAuthContext } from "@/utils/auth";
import { formatErrorMessage } from "./format";
export var createMutationHook = function (documentNode) { return function (mutationOptions) {
    var _a;
    var _b = useMutation(documentNode, __assign({ context: getAuthContext() }, mutationOptions)), mutate = _b[0], _c = _b[1], loading = _c.loading, error = _c.error, called = _c.called;
    var runner = useCallback(function (variables) {
        return mutate({ variables: variables });
    }, [mutate]);
    var hasError = typeof error !== "undefined";
    var completed = !loading && !hasError && called;
    var severity = hasError ? "error" : completed ? "success" : null;
    var message = severity === "error"
        ? formatErrorMessage(error)
        : severity === "success"
            ? (_a = mutationOptions === null || mutationOptions === void 0 ? void 0 : mutationOptions.successMessage) !== null && _a !== void 0 ? _a : null : null;
    return [runner, { completed: completed, message: message, loading: loading, severity: severity }];
}; };
export var createMutationRun = function (documentNode) { return function (variables) {
    var mutationOptions = {
        context: getAuthContext(),
        mutation: documentNode,
        variables: variables,
    };
    return apolloClient.mutate(mutationOptions);
}; };
