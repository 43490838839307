import React from "react";
import { Redirect } from "react-router-dom";
import { ViewPath } from "@/constants/routing";
import { useCompanyQuery } from "@/containers/queries/CompanyQuery";
export var CompanyName = function (props) {
    var companyQuery = useCompanyQuery({ companyId: props.companyId });
    if (companyQuery.data && !companyQuery.loading) {
        if (companyQuery.data.company) {
            return React.createElement(React.Fragment, null, companyQuery.data.company.name);
        }
        return React.createElement(Redirect, { to: ViewPath.COMPANIES });
    }
    return null;
};
