import { ApolloError } from "@apollo/client";
import { format as dateFnsFormat, getMonth, getYear } from "date-fns";
import * as enLocale from "date-fns/locale/en";
import * as itLocale from "date-fns/locale/it";
import i18n from "i18next";
import padStart from "lodash/padStart";
import range from "lodash/range";
export var getLanguage = function () {
    var directLangauge = window.localStorage.getItem("DirectLanguage");
    if (directLangauge) {
        return directLangauge;
    }
    return (i18n.language || navigator.language || "it").split("-")[0].toLowerCase();
};
export var getDateLocale = function () {
    var language = getLanguage();
    switch (language) {
        case "it":
            return itLocale;
        default:
            return enLocale;
    }
};
export var toUpperCase = function (value) {
    return value.toUpperCase();
};
export var leaveDigitsOnly = function (value) {
    if (typeof value === "string") {
        return value.replace(/[^\d]/g, "");
    }
    return value;
};
export var formatUserRole = function (role) {
    switch (role) {
        case "admin": {
            return "Administrator";
        }
        case "employee": {
            return "Employee";
        }
        case "employer": {
            return "Employer";
        }
        case "key_contact": {
            return "Key contact";
        }
    }
};
export var formatErrorMessage = function (maybeError, fallbackMessage) {
    if (fallbackMessage === void 0) { fallbackMessage = null; }
    if (maybeError instanceof ApolloError) {
        var errors = maybeError.graphQLErrors;
        var messages = errors.map(function (err) {
            return err.message;
        });
        return messages.join(", ");
    }
    if (maybeError instanceof Error) {
        return maybeError.message;
    }
    return fallbackMessage;
};
export var formatNumber = function (num) {
    var numAsString = Math.floor(num).toString();
    var groups = [];
    var lenModRemainder = numAsString.length % 3;
    if (lenModRemainder > 0) {
        groups.push(numAsString.slice(0, lenModRemainder));
        numAsString = numAsString.slice(lenModRemainder);
    }
    for (var i = 1; i <= numAsString.length / 3; i += 1) {
        groups.push(numAsString.slice(0, 3));
        numAsString = numAsString.slice(3);
    }
    if (numAsString.length > 0) {
        groups.push(numAsString);
    }
    return groups.join(",");
};
export var formatDateForStatementsTable = function (dateOrString, format) {
    var formatString = "DD MMM YYYY";
    switch (format) {
        case "dashSeparated": {
            formatString = "DD-MM-YYYY";
            break;
        }
        case "dayAndMonth": {
            formatString = "DD MMM";
            break;
        }
    }
    return dateFnsFormat(dateOrString, formatString, {
        locale: getDateLocale(),
    });
};
export var formatMoney = function (amount) {
    var amountFromString = Number(amount);
    if (isNaN(amountFromString)) {
        return "" + amount;
    }
    return "\u20AC" + formatNumber(amountFromString);
};
export var padZero = function (amount) {
    return padStart(amount.toString(), 2, "0");
};
export var formatDate = function (dateOrString) {
    return dateFnsFormat(dateOrString, "DD MMM YYYY", {
        locale: getDateLocale(),
    });
};
export var shortFormatDate = function (dateOrString) {
    return dateFnsFormat(dateOrString, "MMM YYYY", {
        locale: getDateLocale(),
    });
};
export var formatTime = function (dateOrString) {
    return dateFnsFormat(dateOrString, "HH:mm", {
        locale: getDateLocale(),
    });
};
export var formatMobileNumber = function (mobileNumber) {
    var normalizedMobileNumber = mobileNumber.replace(/[^\d+]/g, "");
    var match = normalizedMobileNumber.match(/^(.{0,3})(.{0,4})(.{0,6}).*$/);
    var groups = match ? match.slice(1) : [];
    return groups.filter(Boolean).join(" ");
};
export var maskMoney = function (value) {
    var cleanedValue = value.replace(/^0{2,}/, "0");
    var endsWithDot = cleanedValue.endsWith(".");
    var isNegative = cleanedValue.startsWith("-");
    var _a = cleanedValue.split("."), _b = _a[0], rawIntChunk = _b === void 0 ? "" : _b, _c = _a[1], rawDecimalChunk = _c === void 0 ? "" : _c;
    var intChunk = leaveDigitsOnly(rawIntChunk);
    var decimalChunk = leaveDigitsOnly(rawDecimalChunk).slice(0, 2);
    var num = isNegative ? "-" : "";
    if (intChunk.length === 0) {
        return num;
    }
    num = "" + num + intChunk;
    if (endsWithDot) {
        return num + ".";
    }
    if (decimalChunk.length === 0) {
        return num;
    }
    return num + "." + decimalChunk;
};
export var formatDateOfBirth = function (dateOfBirth) {
    var normalizedDateOfBirth = dateOfBirth.replace(/[^\d]/g, "");
    var match = normalizedDateOfBirth.match(/^(.{0,2})(.{0,2})(.{0,4}).*$/);
    var groups = match ? match.slice(1) : [];
    return groups.filter(Boolean).join("-");
};
export var formatAlphanumeric = function (value) {
    return value.replace(/[^a-z\d]/gi, "");
};
export var inclusiveRange = function (start, end) {
    return range(start, end + 1);
};
export var getListOfMonths = function (startDate, endDate, year) {
    var startDateYear = getYear(startDate);
    var startDateMonth = getMonth(startDate) + 1;
    var endDateYear = getYear(endDate);
    var endDateMonth = getMonth(endDate) + 1;
    var startMonth = startDateYear === year ? startDateMonth : 1;
    var endMonth = endDateYear === year ? endDateMonth : 12;
    return inclusiveRange(startMonth, endMonth);
};
export var getListOfYears = function (startDate, endDate) {
    var startYear = getYear(startDate);
    var endYear = getYear(endDate);
    return inclusiveRange(startYear, endYear);
};
export var formatMonthName = function (month) {
    var date = new Date(1970, month - 1, 1);
    return dateFnsFormat(date, "MMMM", {
        locale: getDateLocale(),
    });
};
