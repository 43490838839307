import React from "react";
import { useTranslation } from 'react-i18next';
import { DataRow, ErrorMessage, Label, Root, Value } from "./styles";
export function EmployeeDetail(props) {
    var _a, _b;
    var hasError = Array.isArray(props.errors) && props.errors.length > 0;
    var t = useTranslation("EmployeeUploadReviewDetail").t;
    return (React.createElement(Root, { hasError: hasError },
        React.createElement(DataRow, null,
            React.createElement(Label, null, props.title),
            React.createElement(Value, { hasError: hasError }, (_a = props.children) !== null && _a !== void 0 ? _a : React.createElement(React.Fragment, null, "\u2014"))), (_b = props.errors) === null || _b === void 0 ? void 0 :
        _b.map(function (error, index) { return (React.createElement(ErrorMessage, { key: error + "-" + index },
            "\u2022 ",
            t(error))); })));
}
