import React from "react";
import { Avatar } from "@/components/atoms/Avatar";
import { Caption, StyledRoot, CaptionLink } from "./styles";
import { themes } from "./themes";
export function AvatarWithLabel(props) {
    var theme = themes[props.theme];
    return (React.createElement(StyledRoot, null,
        React.createElement(Avatar, { placeholder: props.placeholder, theme: props.theme }),
        props.url ? (React.createElement(CaptionLink, { theme: theme, to: props.url }, props.label)) : (React.createElement(Caption, { theme: theme }, props.label))));
}
