import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { fontWeight, rem, boxFrame } from "@/styleguide";
var shouldForwardProp = function (prop) {
    return prop !== "singleChild" && prop !== "theme" && prop !== "width";
};
export var StyledButton = styled("button", { shouldForwardProp: shouldForwardProp })({
    alignItems: "center",
    border: "1px solid",
    cursor: "pointer",
    display: "flex",
    fontFamily: "inherit",
    fontWeight: fontWeight.semiBold,
    outline: "none",
    textDecoration: "none",
}, function (props) { return ({
    backgroundColor: props.theme.backgroundColor,
    borderColor: props.theme.borderColor,
    borderRadius: props.theme.borderRadius,
    color: props.theme.color,
    cursor: props.disabled ? "not-allowed" : "pointer",
    fill: props.theme.color,
    fontSize: rem(props.theme.fontSize),
    height: props.theme.height,
    justifyContent: props.singleChild ? "center" : "space-between",
    opacity: props.disabled ? 0.5 : 1,
    padding: boxFrame(props.theme.padding),
    width: props.width ? props.width : "auto",
    ":hover": {
        opacity: props.disabled ? 0.5 : 0.75,
    },
    ":active": {
        backgroundColor: props.theme.backgroundColorActive,
    },
}); });
export var StyledLabel = StyledButton.withComponent("label");
export var StyledLink = StyledButton.withComponent(Link);
