import { colors } from "@/styleguide";
export var themes = {
    error: {
        backgroundColor: colors.shipGrey,
    },
    primary: {
        backgroundColor: colors.shipGrey,
    },
    secondary: {
        backgroundColor: colors.grey,
    },
    inactive: {
        backgroundColor: colors.shipGrey,
    },
};
