var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import qs from "query-string";
import React from "react";
import { useTranslation } from "react-i18next";
import { AvatarWithLabel } from "@/components/molecules/AvatarWithLabel";
import { FormMessage } from "@/components/molecules/FormMessage";
import { PublicView } from "@/components/templates/PublicView";
import { useResetPasswordWithToken } from "@/containers/mutations/ResetPasswordWithToken";
import { useCheckTokenQuery } from "@/containers/queries/CheckTokenQuery";
import { useNotifier } from "@/hooks/useNotifier";
import { useViewer } from "@/hooks/useViewer";
import { formatErrorMessage } from "@/utils/format";
import { getAvatarPlaceholder } from "@/utils/misc";
import { ResetPasswordWithTokenForm } from "./ResetPasswordWithTokenForm";
import { FormWrapper, Paragraph, AvatarWrapper } from "./styles";
export function ResetPasswordWithTokenView(_a) {
    var _b;
    var location = _a.location;
    var t = useTranslation("ResetPasswordWithTokenView").t;
    var onLogIn = useViewer().onLogIn;
    var notify = useNotifier().notify;
    var token = React.useMemo(function () {
        var queryParams = qs.parse(location.search);
        if (typeof queryParams.token === "string") {
            return queryParams.token;
        }
        return null;
    }, [location]);
    var query = useCheckTokenQuery({ token: token || "" });
    var logInWithContext = React.useCallback(function (data) {
        if (data === null) {
            notify("Unexpected error occured, please try again.", { severity: "error" });
            return;
        }
        if (data.authResult.viewer.role === "employee") {
            notify("Employees are not allowed to log in.", { severity: "error" });
            return;
        }
        onLogIn(data.authResult.token, data.authResult.viewer);
    }, [onLogIn, notify]);
    var _c = useResetPasswordWithToken({
        onCompleted: logInWithContext,
        successMessage: t("yourPasswordHasBeenSet"),
    }), resetPassword = _c[0], resetPasswordMeta = _c[1];
    var handleSubmit = React.useCallback(function (values) {
        if (token === null) {
            return;
        }
        resetPassword(__assign(__assign({}, values), { token: token }));
    }, [resetPassword, token]);
    if (token === null) {
        return null;
    }
    var userName = (_b = query.data) === null || _b === void 0 ? void 0 : _b.user.firstName;
    var viewTitle = userName ? t("viewTitle", { value: userName }) : null;
    return (React.createElement(PublicView, { pageTitle: t("pageTitle"), viewTitle: viewTitle }, (function () {
        if (query.loading) {
            return t("validatingToken");
        }
        if (query.error || !query.data) {
            return (React.createElement(FormMessage, { type: "error" }, formatErrorMessage(query.error, t("unexpectedError"))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(AvatarWrapper, null,
                React.createElement(AvatarWithLabel, { label: query.data.user.email, placeholder: getAvatarPlaceholder(query.data.user.firstName, query.data.user.lastName), theme: "primary" })),
            React.createElement(Paragraph, null, t("setUpYourPassword")),
            React.createElement(FormWrapper, null,
                React.createElement(ResetPasswordWithTokenForm, { formMessage: resetPasswordMeta.message, formStatus: resetPasswordMeta.severity, loading: resetPasswordMeta.loading, onSubmit: handleSubmit }))));
    })()));
}
