var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Icon } from "../Icon";
import { IconWrapper, Input, StyledRoot } from "./styles";
export var SearchInput = function (_a) {
    var onSearch = _a.onSearch, width = _a.width, props = __rest(_a, ["onSearch", "width"]);
    var handleKeyPress = React.useCallback(function (evt) {
        if (evt.key === "Enter" && onSearch) {
            onSearch();
        }
    }, [onSearch]);
    return (React.createElement(StyledRoot, { width: width },
        React.createElement(Input, __assign({}, props, { onKeyPress: handleKeyPress, type: "text" })),
        React.createElement(IconWrapper, { onClick: onSearch },
            React.createElement(Icon, { name: "search", size: 20 }))));
};
