import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PublicView } from "@/components/templates/PublicView";
import { useLogIn } from "@/containers/mutations/LogIn";
import { useViewer } from "@/hooks/useViewer";
import { LoginForm } from "./LoginForm";
import { FormWrapper } from "./styles";
export var LoginView = function () {
    var t = useTranslation("LoginView").t;
    var _a = useState(null), errorMessage = _a[0], setErrorMessage = _a[1];
    var onLogIn = useViewer().onLogIn;
    var onCompleted = React.useCallback(function (data) {
        if (data === null) {
            setErrorMessage(t("errorUnexpected"));
            return;
        }
        if (data.logIn.viewer.role === "employee") {
            setErrorMessage(t("error"));
            return;
        }
        onLogIn(data.logIn.token, data.logIn.viewer);
    }, [t, onLogIn]);
    var _b = useLogIn({ onCompleted: onCompleted }), logIn = _b[0], logInMeta = _b[1];
    return (React.createElement(PublicView, { pageTitle: t("pageTitle"), viewTitle: t("viewTitle") },
        React.createElement(FormWrapper, null,
            React.createElement(LoginForm, { errorMessage: logInMeta.message || errorMessage, loading: logInMeta.loading, onSubmit: logIn }))));
};
