var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import { Icon } from "../Icon";
import { DropZone } from "./styles";
var DragAndDropFiles = /** @class */ (function (_super) {
    __extends(DragAndDropFiles, _super);
    function DragAndDropFiles() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onDrop = function (evt) {
            evt.preventDefault();
            var dataTransferItems = evt.dataTransfer.items;
            var dataTransferFiles = evt.dataTransfer.files;
            var files = [];
            if (dataTransferItems) {
                files = Object.values(dataTransferItems).reduce(function (acc, item) {
                    if (item.kind !== "file") {
                        return acc;
                    }
                    var file = item.getAsFile();
                    if (!file) {
                        return acc;
                    }
                    return __spreadArrays(acc, [file]);
                }, []);
            }
            else {
                files = Object.values(dataTransferFiles);
            }
            _this.props.onFilesDrop(files);
        };
        _this.preventDefault = function (ev) {
            ev.preventDefault();
        };
        return _this;
    }
    DragAndDropFiles.prototype.render = function () {
        return (React.createElement(DropZone, { className: this.props.className, draggable: true, onDragStart: this.preventDefault, onDragOver: this.preventDefault, onDrop: this.onDrop },
            React.createElement(Icon, { name: "dragAndDrop", size: 55 }),
            this.props.children));
    };
    return DragAndDropFiles;
}(React.Component));
export { DragAndDropFiles };
