import styled from "@emotion/styled";
import { fontSize, fontWeight, colors } from "@/styleguide";
export var FieldsRow = styled.div({
    display: "flex",
});
export var FieldCell = styled.div({
    width: "calc((100% - 15px) / 2)",
});
export var InputContent = styled.div({
    alignItems: "center",
    backgroundColor: colors.alabaster,
    borderRadius: 6,
    cursor: "not-allowed",
    display: "flex",
    height: 40,
    padding: "0 18px",
});
export var SectionHeader = styled.div({
    color: colors.shipGrey,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize.regular,
    marginTop: 25,
});
