var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import isEmpty from "lodash/isEmpty";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Button } from "@/components/atoms/Button";
import { Gap } from "@/components/atoms/Gap";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { Checkbox } from "@/components/molecules/Checkbox";
import { Table, Tbody, Td } from "@/components/molecules/Table";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { ViewPath } from "@/constants/routing";
import { useImportEmployees } from "@/containers/mutations/ImportEmployees";
import { refetchCompanyEmployeesQuery } from "@/containers/queries/CompanyEmployeesQuery";
import { useNotifier } from "@/hooks/useNotifier";
import { formatMoney, formatErrorMessage } from "@/utils/format";
import { storage } from "@/utils/storage";
import { EmployeeDetail } from "./EmployeeDetail";
import { Buttons, CheckboxLabel, CustomRow, Empty, Header, InvalidRow, Pagination, SelectAllWrapper, TableWrapper, } from "./styles";
var RECORDS_PER_PAGE = 10;
export function EmployeesUploadReviewView() {
    var _a = useState(1), page = _a[0], setPage = _a[1];
    var _b = useState([]), selectedRecordsIds = _b[0], setSelectedRecordsIds = _b[1];
    var notify = useNotifier().notify;
    var t = useTranslation("EmployeeUploadReviewView").t;
    var allEmployeesRecords = useState(function () {
        var _a;
        var uploadedRecords = (_a = storage.getItem("EmployeesUpload")) !== null && _a !== void 0 ? _a : [];
        return uploadedRecords.map(function (record) {
            var _a;
            return (__assign(__assign({}, record), { id: uuid(), errors: (_a = record.errors) !== null && _a !== void 0 ? _a : {}, isValid: isEmpty(record.errors) }));
        });
    })[0];
    var validEmployeesRecords = useMemo(function () {
        return allEmployeesRecords.filter(function (record) { return record.isValid; });
    }, [allEmployeesRecords]);
    var toggleAll = useCallback(function () {
        var newSelectedRecordsIds = [];
        if (selectedRecordsIds.length !== validEmployeesRecords.length) {
            newSelectedRecordsIds = validEmployeesRecords.map(function (record) { return record.id; });
        }
        setSelectedRecordsIds(newSelectedRecordsIds);
    }, [validEmployeesRecords, selectedRecordsIds]);
    var toggleRow = useCallback(function (rowId) {
        var newSelectedRowsIds = selectedRecordsIds;
        if (selectedRecordsIds.includes(rowId)) {
            newSelectedRowsIds = selectedRecordsIds.filter(function (currentRowId) { return currentRowId !== rowId; });
        }
        else {
            newSelectedRowsIds = __spreadArrays(newSelectedRowsIds, [rowId]);
        }
        setSelectedRecordsIds(newSelectedRowsIds);
    }, [selectedRecordsIds]);
    var onCompleted = useCallback(function () {
        notify(t("notifyCreateSuccess"), { severity: "success" });
        location.replace(ViewPath.EMPLOYEES);
    }, [notify, t]);
    var onError = useCallback(function (err) {
        notify(formatErrorMessage(err), { severity: "error" });
    }, [notify]);
    var _c = useImportEmployees({
        onCompleted: onCompleted,
        onError: onError,
        refetchQueries: function () {
            return [
                refetchCompanyEmployeesQuery({ order: { key: "createdAt", orderDirection: "desc" } }),
            ];
        },
    }), importEmployees = _c[0], importEmployeesMeta = _c[1];
    var handleImport = useCallback(function () {
        var selectedUploadedRecords = [];
        for (var _i = 0, validEmployeesRecords_1 = validEmployeesRecords; _i < validEmployeesRecords_1.length; _i++) {
            var employeeRecord = validEmployeesRecords_1[_i];
            if (selectedRecordsIds.includes(employeeRecord.id)) {
                selectedUploadedRecords.push(employeeRecord);
            }
        }
        storage.removeItem("EmployeesUpload");
        importEmployees({
            employees: selectedUploadedRecords.map(function (row) { return ({
                accountName: row.accountName,
                country: row.country,
                dateOfBirth: row.dateOfBirth,
                department: row.department,
                employeeEmail: row.employeeEmail,
                employeeFirstName: row.employeeFirstName,
                employeeLastName: row.employeeLastName,
                employeeTaxId: row.employeeTaxId,
                externalEmployeeId: row.externalEmployeeId,
                grossSalary: Number(row.grossSalary),
                iban: row.iban,
                jobTitle: row.jobTitle,
                lineOne: row.lineOne,
                mobileNumber: row.mobileNumber,
                partTime: Number(row.partTime),
                postCode: row.postCode,
                postTown: row.postTown,
                provinceCode: row.provinceCode,
            }); }),
        });
    }, [importEmployees, selectedRecordsIds, validEmployeesRecords]);
    var loadMore = useCallback(function () {
        setPage(page + 1);
    }, [page]);
    var hasPagination = RECORDS_PER_PAGE * page < allEmployeesRecords.length;
    var paginatedRecords = allEmployeesRecords.slice(0, RECORDS_PER_PAGE * page);
    return (React.createElement(ProtectedView, { pageTitle: t("pageTitle"), theme: "darken" },
        React.createElement(InnerContainer, null,
            React.createElement(Header, null, "Upload Multiple Employees"),
            allEmployeesRecords.length === 0 ? (React.createElement(React.Fragment, null,
                React.createElement(Empty, null, t("employeeUploadedForReview")),
                React.createElement(Buttons, null,
                    React.createElement(Button, { to: ViewPath.EMPLOYEES, theme: "secondary", type: "button", width: 225 }, t("cancel"))))) : (React.createElement(React.Fragment, null,
                React.createElement(SelectAllWrapper, null,
                    React.createElement(Checkbox, { id: "allRowsSelected", name: "allRowsSelected", onChange: toggleAll, value: selectedRecordsIds.length === validEmployeesRecords.length, disabled: validEmployeesRecords.length === 0 }),
                    React.createElement(CheckboxLabel, null, t("selectAllCorrectRecords"))),
                React.createElement(TableWrapper, null,
                    React.createElement(Table, { cols: [25, 25, 25, 25] },
                        React.createElement(React.Fragment, null),
                        React.createElement(Tbody, null, paginatedRecords.map(function (row) {
                            var RowComponent = row.isValid ? CustomRow : InvalidRow;
                            return (React.createElement(RowComponent, { key: row.id, onSelect: toggleRow, rowId: row.id, selectable: row.isValid, selected: row.isValid && selectedRecordsIds.includes(row.id) },
                                React.createElement(Td, null,
                                    React.createElement(Gap, { direction: "y", size: 10 },
                                        React.createElement(EmployeeDetail, { errors: row.errors.employeeFirstName, title: t("firstName") }, row.employeeFirstName),
                                        React.createElement(EmployeeDetail, { errors: row.errors.employeeLastName, title: t("lastName") }, row.employeeLastName),
                                        React.createElement(EmployeeDetail, { errors: row.errors.dateOfBirth, title: t("birthDate") }, row.dateOfBirth),
                                        React.createElement(EmployeeDetail, { errors: row.errors.employeeEmail, title: t("email") }, row.employeeEmail),
                                        React.createElement(EmployeeDetail, { errors: row.errors.mobileNumber, title: t("phone") }, row.mobileNumber))),
                                React.createElement(Td, null,
                                    React.createElement(Gap, { direction: "y", size: 10 },
                                        React.createElement(EmployeeDetail, { errors: row.errors.jobTitle, title: t("jobTitle") }, row.jobTitle),
                                        React.createElement(EmployeeDetail, { errors: row.errors.department, title: t("department") }, row.department),
                                        React.createElement(EmployeeDetail, { errors: row.errors.externalEmployeeId, title: t("employeeId") }, row.externalEmployeeId),
                                        React.createElement(EmployeeDetail, { errors: row.errors.grossSalary, title: t("grossMonthSalary") }, formatMoney(row.grossSalary)),
                                        React.createElement(EmployeeDetail, { errors: row.errors.partTime, title: t("partTime") },
                                            row.partTime,
                                            "%"))),
                                React.createElement(Td, null,
                                    React.createElement(Gap, { direction: "y", size: 10 },
                                        React.createElement(EmployeeDetail, { errors: row.errors.accountName, title: t("accountName") }, row.accountName),
                                        React.createElement(EmployeeDetail, { errors: row.errors.iban, title: t("iban") }, row.iban),
                                        React.createElement(EmployeeDetail, { errors: row.errors.employeeTaxId, title: t("taxId") }, row.employeeTaxId))),
                                React.createElement(Td, null,
                                    React.createElement(Gap, { direction: "y", size: 10 },
                                        React.createElement(EmployeeDetail, { errors: row.errors.country, title: t("country") }, row.country),
                                        row.country === "IT" && (React.createElement(EmployeeDetail, { errors: row.errors.provinceCode, title: t("province") }, row.provinceCode)),
                                        React.createElement(EmployeeDetail, { errors: row.errors.postTown, title: t("city") }, row.postTown),
                                        React.createElement(EmployeeDetail, { errors: row.errors.postCode, title: t("postCode") }, row.postCode),
                                        React.createElement(EmployeeDetail, { errors: row.errors.lineOne, title: t("address") }, row.lineOne)))));
                        })))),
                hasPagination && (React.createElement(Pagination, null,
                    React.createElement(Button, { onClick: loadMore, theme: "dark", type: "button" }, t("loadMoreRows")))),
                React.createElement(Buttons, null,
                    React.createElement(Button, { to: ViewPath.EMPLOYEES, theme: "secondary", type: "button", width: 225 }, "Cancel"),
                    React.createElement(Button, { disabled: importEmployeesMeta.loading || selectedRecordsIds.length === 0, onClick: handleImport, type: "submit", width: 225 }, selectedRecordsIds.length
                        ? t("saveCount", { value: selectedRecordsIds.length })
                        : t("save"))))))));
}
