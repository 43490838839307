var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { colors } from "@/styleguide";
import { Icon } from "../Icon";
import { Content, IconWrapper, StyledRoot } from "./styles";
export var Hint = function (_a) {
    var onClickOutside = _a.onClickOutside, props = __rest(_a, ["onClickOutside"]);
    var _b = props.x, x = _b === void 0 ? 0 : _b, _c = props.y, y = _c === void 0 ? 0 : _c;
    var ref = React.useRef(null);
    var onClick = React.useCallback(function (evt) {
        var node = ref.current;
        // @ts-ignore
        // EventTarget is not assignable to Node
        if (node && node.contains(evt.target)) {
            return;
        }
        onClickOutside === null || onClickOutside === void 0 ? void 0 : onClickOutside();
    }, [onClickOutside]);
    React.useEffect(function () {
        document.addEventListener("click", onClick);
        return function () {
            document.removeEventListener("click", onClick);
        };
    }, [onClick]);
    return (React.createElement(StyledRoot, { ref: ref },
        React.createElement(IconWrapper, { onClick: props.onToggle },
            React.createElement(Icon, { color: colors.java, name: "information", size: 16 })),
        props.isOpen && (React.createElement(Content, { padding: props.padding, width: props.width, x: x, y: y }, props.children))));
};
