var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fontSize, colors, rem, boxFrame, fontWeight } from "@/styleguide";
export var styles = {
    control: function (defaultStyles, state) { return (__assign(__assign({}, defaultStyles), { borderColor: state.isFocused ? colors.shipGrey : colors.alto, borderRadius: 6, borderSize: 1, borderStyle: "solid", boxShadow: "none", cursor: "pointer", height: 40, "&:hover": {} })); },
    indicatorsContainer: function (defaultStyles) { return (__assign(__assign({}, defaultStyles), { alignItems: "center", cursor: "pointer", padding: boxFrame([8, 18]) })); },
    menu: function (defaultStyles) { return (__assign(__assign({}, defaultStyles), { border: "1px solid " + colors.alto, borderRadius: 6, boxShadow: "none", padding: boxFrame([6, 0]) })); },
    option: function (defaultStyles, state) { return (__assign(__assign({}, defaultStyles), { backgroundColor: colors.white, color: state.isSelected ? colors.elm : colors.shipGrey, cursor: "pointer", fontSize: rem(fontSize.small), fontWeight: state.isSelected ? fontWeight.semiBold : fontWeight.regular, padding: boxFrame([8, 18]), "&:active": {}, "&:hover": {
            backgroundColor: colors.turquoise,
        } })); },
    placeholder: function (defaultStyles) { return (__assign(__assign({}, defaultStyles), { color: colors.grey, fontSize: fontSize.small })); },
    singleValue: function (defaultStyles) { return (__assign(__assign({}, defaultStyles), { fontSize: rem(fontSize.small), fontWeight: fontWeight.regular, padding: boxFrame([0]) })); },
};
