import React from "react";
import { useTranslation } from "react-i18next";
import { CardSection } from "@/components/atoms/CardSection";
import { CompanyDetails as CompanyDetailsList } from "@/components/organisms/CompanyDetails";
export var CompanyDetails = function (props) {
    var t = useTranslation("UserSettingsView.CompanyDetails").t;
    return (React.createElement(CardSection, null,
        React.createElement(CardSection.Heading, null, t("header")),
        React.createElement(CompanyDetailsList, { className: "mt-40", name: props.name, sector: props.sector })));
};
