var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { electronicFormatIBAN, isValidIBAN } from "ibantools";
import { hasLength, hasOneDigit, isEmail, isFTE, isNonBlankString, isSalary, isTrue, matchesRegex, rangeInYears, startsWithCountryCode, } from "@/utils/core";
import { formatNumber } from "@/utils/format";
var ADDRESS_LINE_REGEX = /^[\w\s\-.']+$/;
var ALPHANUMERIC = /^\w[\w\s]*\w$/;
var CITY_REGEX = /^[\w\s-]{2,}$/;
var DATE_REGEX = /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-(1\d|20)\d{2}$/;
var EMPLOYEE_ID_REGEX = /^\w+$/;
var HAS_ONE_SPECIAL = /[§£!@#$%^&*()_+=[\]{};':"\\|,./<>?`~-]/;
var HAS_ONE_UPPER_CASE = /[A-Z]/;
var JOB_TITLE_REGEX = /^\w+([\s-]\w+)*$/;
var MOBILE_NUMBER = /^\+\d{11,16}$/;
var PERSON_FIRST_NAME = /^[a-z]{2,}(\s[a-z]{2,}){0,2}$/i;
var PERSON_LAST_NAME = /^['a-z]{2,}([\s-]['a-z]{2,})?$/i;
var POSTAL_CODE_REGEX = /^[\w\s-]{4,}$/;
var COMPANY_REAL_CODE_REGEX = /^[\w\s-]{1,10}$/;
var ID_PAYROLL_ITEM_REGEX = /^[\w\s-]{1,10}$/;
var REGISTRATION_NUMBER_REGEX = /^[a-z\d]{1,11}$/i;
var CUC_CODE = /^[A-Z0-9]{8}$/i;
export var requiredValidationRules = [
    [isNonBlankString, "This field is required"],
];
export var requiredBoolValidationRules = [[isTrue, "This field is required"]];
export var accountNameRules = __spreadArrays(requiredValidationRules, [
    [hasLength(0, 70), "Account name is too long (max 70 characters)"],
]);
export var addressLineRules = __spreadArrays(requiredValidationRules, [
    [matchesRegex(ADDRESS_LINE_REGEX), "Incorrect address line"],
]);
export var adultDateOfBirthRules = __spreadArrays(requiredValidationRules, [
    [matchesRegex(DATE_REGEX), "Incorrect date (DD-MM-YYYY)"],
    [rangeInYears(18), "Person has to be at least 18 years old"],
    [rangeInYears(0, 100), "Person cannot be older than 100 years"],
]);
export var cityRules = __spreadArrays(requiredValidationRules, [
    [matchesRegex(CITY_REGEX), "Incorrect city name"],
]);
export var companyNameRules = __spreadArrays(requiredValidationRules, [
    [matchesRegex(ALPHANUMERIC), "Incorrect company name"],
    [hasLength(2, 70), "Company name can be max 70 characters long"],
]);
export var companyRegistrationNumberRules = __spreadArrays(requiredValidationRules, [
    [
        matchesRegex(REGISTRATION_NUMBER_REGEX),
        "Incorrect company registration ID (max 11 characters)",
    ],
]);
export var cucRules = __spreadArrays(requiredValidationRules, [
    [matchesRegex(CUC_CODE), "Invalid CUC number (8 characters)"],
]);
export var emailRules = __spreadArrays(requiredValidationRules, [
    [isEmail, "Incorrect e-mail address"],
]);
export var employeeIdRules = __spreadArrays(requiredValidationRules, [
    [hasLength(1, 32), "Incorrect employee ID"],
    [matchesRegex(EMPLOYEE_ID_REGEX), "Must include only A-Z and 0-9"],
]);
export var ibanRules = __spreadArrays(requiredValidationRules, [
    [function (v) { return typeof v === "string" && isValidIBAN(electronicFormatIBAN(v)); }, "Invalid IBAN"],
]);
export var jobTitleRules = __spreadArrays(requiredValidationRules, [
    [hasLength(1, 32), "Incorrect job title"],
    [matchesRegex(JOB_TITLE_REGEX), "Must include only A-Z, 0-9, spaces and -"],
]);
export var partTimeRules = __spreadArrays(requiredValidationRules, [
    [isFTE, "Part-time must be in range 1% - 100%"],
]);
export var passwordRules = __spreadArrays(requiredValidationRules, [
    [hasLength(8), "At least 8 characters required"],
    [hasOneDigit, "One digit required"],
    [matchesRegex(HAS_ONE_UPPER_CASE), "One upper case letter required"],
    [matchesRegex(HAS_ONE_SPECIAL), "One special character required"],
]);
export var personFirstNameRules = __spreadArrays(requiredValidationRules, [
    [matchesRegex(PERSON_FIRST_NAME), "Incorrect first name"],
]);
export var personLastNameRules = __spreadArrays(requiredValidationRules, [
    [matchesRegex(PERSON_LAST_NAME), "Incorrect last name"],
]);
export var phoneNumberRules = __spreadArrays(requiredValidationRules, [
    [startsWithCountryCode, "Has to start with country code (+XX)"],
    [
        function (v) { return typeof v === "string" && matchesRegex(MOBILE_NUMBER)(v.replace(/\s+/g, "")); },
        "Incorrect phone number",
    ],
]);
export var postalCodeRules = __spreadArrays(requiredValidationRules, [
    [matchesRegex(POSTAL_CODE_REGEX), "Incorrect postal code"],
]);
export var companyRealCodeRules = __spreadArrays(requiredValidationRules, [
    [matchesRegex(COMPANY_REAL_CODE_REGEX), "Incorrect company code"],
]);
export var idPayrollItemRules = __spreadArrays(requiredValidationRules, [
    [matchesRegex(ID_PAYROLL_ITEM_REGEX), "Incorrect Id Payroll Item"],
]);
export var payrollFileFormatRules = __spreadArrays(requiredValidationRules);
export var salaryRules = __spreadArrays(requiredValidationRules, [
    [isSalary, "Salary must be in range 1 - " + formatNumber(50000000)],
]);
// Tax ID has to be either 11 digits or 16 alphanumeric.
export var taxIdRules = __spreadArrays(requiredValidationRules, [
    [
        function (value) {
            if (typeof value !== "string") {
                return false;
            }
            if (value.length === 11) {
                // return /^\d{11}$/.test(value);
            }
            if (value.length === 16) {
                // return /^[a-z\d]{16}$/i.test(value);
            }
            return /^([0-9A-Z]{11}|[0-9A-Z]{16})$/i.test(value);
        },
        "Incorrect tax ID",
    ],
]);
