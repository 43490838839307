export var enTranslation = {
    General: {
        manageCompanies: "Manage Companies",
        reports: "Reports",
        employees: "Employees",
        fundTransfer: "Fund Transfer",
    },
    "Atoms.ActiveStatus": {
        active: "Active",
        inactive: "Inactive",
    },
    "Atoms.Logo": {
        alt: "Consono Logo",
        src: "/assets/images/consonoLogo.svg",
    },
    "Molecules.ChangeLanguageBlock": {
        language: "Language",
    },
    "Molecules.CompanyDetails": {
        companyName: "Company Name",
        na: "N/A",
        industry: "Industry",
    },
    "Molecules.CompanyForm": {
        companyDetails: "Company Details",
        businessOwnerDetails: "Business Owner Details",
        fieldsAreMandatory: "* marked fields are mandatory",
        selectIndustry: "Select Industry",
        na: "N/A",
        companyLabel: "Company Name",
        companyPlaceholder: "E.g. Tech Industries",
        companyIdLabel: "Company ID",
        companyIdPlaceholder: "Enter Company ID",
        industryLabel: "Industry*",
        industryPlaceholder: "Select an Industry",
        vatLabel: "VAT number",
        vatPlaceholder: "Enter VAT number",
        paydayDateLabel: "Payday Date*",
        companyRealCode: "Company Code",
        idPayrollItem: "ID Payroll Item",
        payrollFileFormat: "Payroll File Format",
        paydayDatePlaceholder: "Select Payday Date",
        cucNumberLabel: "CUC Number*",
        cucNumberPlaceholder: "8 characters",
        taxIdLabel: "Tax ID*",
        taxIdPlaceholder: "Enter Tax ID",
        ibanLabel: "IBAN*",
        registeredAddress: "Enter IBAN",
        countryLabel: "Country*",
        countryPlaceholder: "Select Country",
        provinceLabel: "Province*",
        provincePlaceholder: "Enter Province",
        cityLabel: "City*",
        cityPlaceholder: "E.g. Roma",
        postalCodeLabel: "Postal Code*",
        postalCodePlaceholder: "E.g. 00199",
        addressLabel: "Address*",
        addressPlaceholder: "E.g. Via Sacra 1",
        copyRegisteredToTradingLabel: "Tranding address is the same as the registered address",
        tradingAddress: "Trading Address",
        firstNameLabel: "First Name*",
        firstNamePlaceholder: "E.g. Marco",
        lastNameLabel: "Last Name*",
        lastNamePlaceholder: "E.g. Rossi",
        emailLabel: "Email*",
        emailPlaceholder: "E.g. marco.rossi@example.it",
        birthDateLabel: "Date of Birt*",
        birthDatePlaceholder: "E.g. 22-06-1987",
        phoneLabel: "Mobile Number*",
        phonePlaceholder: "E.g. +39 0000 000000",
        businessOwnerAddress: "Business Owner Address",
        keyContactDetails: "Key Contact Details",
        jobTitleLabel: "Job Title*",
        jobTitlePlaceholder: "E.g. Developer",
        selectADay: "(Select a Day)",
        currentMonth27: "27 (Current Month)",
        followingMonth5: "5 (Following Month)",
        "payrollFileFormat-standard": "Standard",
        "payrollFileFormat-zucchetti": "Zucchetti",
        "KeyContact with email already exist": "User with email {{ keyContactEmail }} already exist",
        "Employer with email already exist": "User with email {{ employerEmail }} already exist",
        "User with mobile number already exist": "User with mobile number {{ employerMobileNumber }} already exist",
    },
    "Molecules.ConfirmModal": {
        cancel: "Cancel",
    },
    "Molecules.EmployeeForm": {
        na: "N/A",
        employeeAddress: "Employee Address",
        employeeDetails: "Employee Details",
        firstNameLabel: "First Name*",
        firstNamePlaceholder: "E.g. Marco",
        lastNameLabel: "Last Name*",
        lastNamePlaceholder: "E.g. Rossi",
        emailLabel: "Email*",
        emailPlaceholder: "E.g. marco.rossi@exaple.it",
        phoneLabel: "Mobile Number*",
        phonePlaceholder: "E.g. +39 000 000000",
        birthDateLabel: "Date of Birth*",
        birthDatePlaceholder: "DD-MM-YYYY",
        jobTitleLabel: "Job Title",
        jobTitlePlaceholder: "E.g. Developer",
        departmentLabel: "Department",
        departmentPlaceholder: "Enter Department",
        employeeIdLabel: "Employee ID",
        employeeIdPlaceholder: "E.g. IE132",
        countryLabel: "Country*",
        countryPlaceholder: "Select Country",
        provinceLabel: "Province*",
        provincePlaceholder: "Enter Province",
        cityLabel: "City*",
        cityPlaceholder: "E.g. Roma",
        postalCodeLabel: "Postal Code*",
        postalCodePlaceholder: "E.g. 00199",
        addressLabel: "Address",
        addressPlaceholder: "E.g. Via Sacra 1",
        salaryAndBankDetails: "Salary and Bank Details",
        markedFieldsAreMandatory: "* marked fields are mandatory",
        accruedAmountInactive: "Accrued Amount (Inactive)",
        accruedAmountInactiveDetails: "Indicative figure that an employee has accrued in the current pay cycle. Based on this, the available amount that an employee can withdraw is calculated.",
        grossMonthlySalaryLabel: "Gross Monthly Salary*",
        grossMonthlySalaryPlaceholder: "E.g. 3500.23",
        partTimeLabel: "Part-time* (%)",
        partTimePlaceholder: "E.g. 50",
        insert100ForFullTime: "Insert 100 for full time",
        accountNameLabel: "Account name*",
        accountNamePlaceholder: "E.g. Marco Rossi",
        accountNameSameAsEmployeeLabel: "Account name is same as the employee name",
        ibanLabel: "IBAN*",
        ibanPlaceholder: "Enter IBAN",
        taxIdLabel: "Tax ID*",
        taxIdPlaceholder: "Enter tax ID",
        "Employee with email already exist": "User with email {{ employeeEmail }} already exist",
        "User with mobile number already exist": "User with mobile number {{ mobileNumber }} already exist",
    },
    "Molecules.EmployeesUpload": {
        chooseFiles: "Choose Files",
        downloadSample: "Download Sample",
        dragAndDropFilesHere: "Drag & Drop Here",
    },
    "Molecules.PersonalDetails": {
        phone: "Phone",
        email: "Email",
        na: "N/A",
    },
    "Molecules.CardViewButtons": {
        cancel: "Cancel",
        save: "Save",
    },
    AppHeader: {
        withdrawalSettings: "Withdrawal Settings",
        manageProfile: "Manage Profile",
    },
    CompaniesReportsView: {
        currentYear: "(anno in corso)",
        currentMonth: "(corrente mese)",
        Daily: "Daily",
        Monthly: "Monthly",
        pageTitle: "Reports",
        theme: "darken",
        transactionHistory: "Transactions History",
        transactionFeeNotice: "Transaction fees is excluded from the amounts mentioned below",
        fetchingTransactionList: "Loading...",
        transactionListEmpty: "No transactions in specified time period.",
        statCards: {
            totalPayAdvances: {
                icon: "wallet",
                title1: "Total pay advances",
                title2: "{{ value }}",
                value: "{{ value }}",
            },
            totalTransactions: {
                icon: "transactions",
                title1: "Total transactions",
                title2: "{{ value }}",
                value: "{{ value }}",
            },
        },
    },
    "CompaniesReportsView.ReportsTable": {
        date: "Date",
        companyName: "Company Name",
        employeeName: "Employee Name",
        transactionId: "Transaction ID",
        amount: "Amount",
    },
    "CompaniesReportsView.CompanyName": {},
    "CompaniesReportsView.ChartPanel": {
        trendOfPayAdvances: "Trend of Pay Advances",
    },
    CompaniesView: {
        pageTitle: "Companies",
        loadingComponents: "Loading...",
        errorOccurred: "Something went wrong",
        createCompany: "Create Company",
        companyName: "Company Name",
        keyContact: "Key Contact",
        totalEmployees: "Total Employees",
        activeEmployees: "Active Employees",
        totalTransactions: "Total Transactions",
        totalPayAdvanced: "Total Pay Advanced",
        addCompany: "Add Company",
    },
    "CompaniesView.CompanyActions": {
        notifyProblemMonthlyReport: "There was some problem with downloading your monthly report",
        downloadResponsesReport: "Download Responses Report",
        viewEmployees: "View Employees",
        viewReport: "View Report",
        inviteSent: "Invite sent",
        sendInvite: "Send invite",
    },
    CreateCompanyView: {
        notifyCompanyProfileAdded: "Company profile added successfully",
        addCompany: "Add Company",
        createCompany: "Create Company",
        loading: "Loading...",
        errorFetchSectorList: "Couldn't fetch sectors list",
        errorFetchCountryList: "Couldn't fetch countries list",
        activeEmployees: "Active Employees",
        totalTransactions: "Total Transactions",
        totalPayAdvanced: "Total Pay Advanced",
    },
    CreateEmployeeView: {
        notifyEmployeeAdded: "Employee successfully added",
        addNewEmployee: "Add new Employee",
        newEmployee: "New Employee",
        loading: "Loading...",
    },
    EmployeesReportsView: {
        currentYear: "(anno in corso)",
        currentMonth: "(corrente mese)",
        Daily: "Daily",
        Monthly: "Monthly",
        pageTitle: "Reports",
        theme: "darken",
        downloadReport: "Download Report",
        downloadReportSmart: "Export Payroll",
        transactionHistory: "Transactions History",
        transactionFeeNotice: "Transaction fees is excluded from the amounts mentioned below\n",
        fetchingTransactionList: "Loading...",
        transactionListEmpty: "No transactions in specified time period.",
        statCards: {
            totalPayAdvances: {
                icon: "wallet",
                title1: "Total pay advances",
                title2: "{{ value }}",
                value: "{{ value }}",
            },
            totalTransactions: {
                icon: "transactions",
                title1: "Total transactions",
                title2: "{{ title2 }}",
                value: "{{ value }}",
            },
        },
    },
    "EmployeesReportsView.ReportsTable": {
        timeStamp: "Timestamp",
        transactionId: "Transaction ID",
        amountAdvanced: "Amount Advanced",
    },
    "EmployeesReportsView.ChartPanel": {
        trendOfPayAdvances: "Trend of pay advances",
    },
    EmployeeUploadReviewView: {
        notifyCreateSuccess: "Employee successfully created",
        pageTitle: "Employees Review",
        employeeUploadedForReview: "Employee uploaded for review",
        cancel: "Cancel",
        selectAllCorrectRecords: "Select all correct records",
        firstName: "First Name",
        lastName: "Last Name",
        birthDate: "Date of Birth",
        email: "Email",
        phone: "Mobile Phone",
        jobTitle: "Job Title",
        grossMonthSalary: "Monthly Gross Salary",
        partTime: "Part Time",
        accountName: "Account Name",
        iban: "IBAN",
        taxId: "Tax ID",
        country: "Country",
        province: "Province",
        city: "City",
        postCode: "Postal Code",
        address: "Address",
        loadMoreRows: "Load More Rows",
        save: "Save",
        saveCount: "Save ({{value}})",
    },
    "EmployeeUploadReviewView.EmployeeDetail": {},
    EmployeeView: {
        notificationForAction: {
            blockTransaction: "Employee's transaction status is blocked successfully",
            unblockTransaction: "Employee's transaction status is unblocked successfully",
            employeeDisabled: "Employee disabled successfully",
            employeeEnabled: "Employee activated successfully",
        },
        messageForActions: {
            blockTransaction: "Please confirm you want to block transactions for this employee",
            unblockTransaction: "Please confirm you want to unblock transaction status of this employee",
            employeeDisabled: "Please confirm you want to disable this employee",
            employeeEnabled: "Please confirm you want to activate this employee",
        },
        pageTitle: "Employee",
        confirm: "Confirm",
        noEmployees: "No employees in specified request",
        noEmployeesAdded: "No employees added",
        clickHere: "Click here",
        toAdd: "To add",
        name: "Name",
        id: "ID",
        email: "Email",
        status: "Status",
        added: "Added",
        deactivate: "Deactivate",
        activate: "Activate",
        block_transactions: "Block Transactions",
        unblock_transactions: "Unblock transactions",
    },
    "EmployeeView.EmployeeViewToolbar": {
        searchPlaceholder: "Search employee",
        clearFilters: "Clear filters",
    },
    "EmployeeView.EmployeeViewHeader": {
        totalEmployees: "Total Employees",
        activeEmployees: "Active Employees",
        addEmployee: "Add employee",
        addMultipleEmployees: "Add multiple Employees",
    },
    "EmployeeView.EmployeeActions": {
        inviteResent: "Invite Resent",
        resendInvite: "Resend Invite",
    },
    ForgotPasswordView: {
        pageTitle: "Forgot Password",
        viewTitle: "Forgot Password",
        successMessage: "You will receive an e-mail soon, if your address is recognized",
        enterYourEmail: "Enter you email",
    },
    "ForgotPasswordView.ForgotPasswordForm": {
        labelEmail: "Email",
        emailPlaceholder: "Enter your email",
        submit: "Submit",
    },
    FundsView: {
        selected: "Selected",
        totalSelected: "Total Selected",
        totalApproved: "Total Approved",
        pageTitle: "Funds Transfer",
        viewTitle: "Fund Transfer",
        successMessage: "Success",
        enterYourEmail: "Enter your Email",
        transactionSuccessful: "Transactions was {{value}} successfully",
        noFundsRequestedForCycle: "No founds requested for cycle",
        unableToDownloadReports: "Unable to download reports",
        actionModalMessageApprove: "Please confirm you want to approve this transactions",
        actionModalMessageDecline: "Please confirm you want to decline this transactions",
        actionModalMessageReverse: "Did you do the payment? Are you sure you want to reverse the action?",
        confirm: "Confirm",
        loading: "Loading...",
        empty: "No Funds transfer in specified time period.",
        select: "Select",
        employeeName: "Employee Name",
        requestDate: "Request Date",
        iban: "IBAN",
        requestAmount: "Requested Amount",
        requestStatus: "Request Status",
        transactionStatus: "Transaction Status",
        decline: "Decline",
        approve: "Approve",
        reverse: "Reverse",
        declined: "Declined",
        approved: "Approved",
        reversed: "Reversed",
        pending: "Pending",
        __decline: "Decline",
        __approve: "Approve",
        __reverse: "Reverse",
        ___approved: "approved",
        ___declined: "declined",
        ___reversed: "reversed",
        areYouSureToSendBankTransfer: "Are you sure to send Bank Transfer?",
    },
    "FundsView.FundsViewHeader": {
        bankTransfer: "Bank Transfer",
        totalCompleted: "Total Completed: {{value}}",
    },
    LoginView: {
        pageTitle: "Login",
        viewTitle: "Login",
        errorUnexpected: "Something went wrong",
        error: "Something went wrong",
    },
    "LoginView.LoginForm": {
        form: {
            email: {
                label: "Email",
                placeholder: "Enter email",
            },
            password: {
                label: "Password",
                placeholder: "Enter password",
            },
        },
        resetPasswordActionCallTitle: "",
        resetPasswordActionCallContent: "Forgot password?",
        submit: "Login",
    },
    ResetPasswordWithTokenView: {
        viewTitle: "Hi, {{value}}!",
        yourPasswordHasBeenSet: "You password has been set",
        pageTitle: "Reset Password",
        validatingToken: "Validating Token...",
        unexpectedError: "Something went wrong",
        setUpYourPassword: "Lets set up your password",
    },
    "ResetPasswordWithTokenView.ResetPasswordWithTokenForm": {
        passwordLabel: "Password",
        passwordPlaceholder: "Enter password",
        confirmPasswordLabel: "Confirm Password",
        confirmPasswordPlaceholder: "Enter password",
        save: "Save",
    },
    SetPasswordView: {
        viewTitle: "Hi, {{value}}!",
        yourPasswordHasBeenSet: "You password has been set",
        pageTitle: "Reset Password",
        validatingToken: "Validating Token...",
        unexpectedError: "Something went wrong",
        setUpYourPassword: "Lets set up your password",
    },
    "SetPasswordView.SetPasswordForm": {
        passwordLabel: "Password",
        passwordPlaceholder: "Enter password",
        confirmPasswordLabel: "Confirm Password",
        confirmPasswordPlaceholder: "Enter password",
        save: "Save",
    },
    StatementsDetailedView: {
        notifyDownloadReportError: "Unable to download report",
        pageTitle: "Statements",
        statementDetails: "Statement Details",
        loading: "Loading...",
        errorFetchCompany: "Unable to fetch Company",
        goBack: "Go Back",
        downloadReport: "Download Report",
    },
    "StatementsDetailedView.StatementsTable": {
        totalPayAdvances: "Total Pay Advances",
        totalTransactionFees: "Total Transaction Fees",
        balancePayments: "Balance Payments",
        employerPayment: "Employer Payment",
        payAdvances: "Pay Advances",
        date: "Date",
        amount: "Amount",
        grandTotalPayAdvances: "Grand Total Pay Advances",
        transactionFees: "Transaction Fees",
        balancePayment: "Balance Payment",
        type: "Type",
    },
    StatementView: {
        pageTitle: "Statement",
        pageHeader: "Statement",
        loading: "Loading...",
        errorFetchEmployee: "Unable to fetch Employees",
    },
    "StatementView.StatementTimePeriodForm": {
        statementMonthLabel: "Statement Monthly",
        statementMonthPlaceholder: "Statement Monthly",
        cannotSelectAnyMonth: "Can not select any month",
        statementYearLabel: "Statement year",
        statementYearPlaceholder: "Statement Year",
        submit: "Submit",
        monthlyStatementNotGenerated: "Monthly statement not generated",
        goBack: "Go Back",
    },
    TermsAndConditionsView: {
        pageTitle: "Terms and Conditions",
        viewTitle: "Terms and Conditions",
        goBack: "Go Back",
    },
    UpdateCompanyView: {
        pleaseConfirmCompany: "Please confirm you want to {{statusActionName}} this Company",
        notifyCompanyUpdated: "Company successfully updated",
        notifyCompanyStatusUpdated: "Company status successfully updated",
        errorGetCompanyId: "Unable to get Company ID",
        pageTitle: "Update Company",
        loading: "Loading...",
        errorFetchCountries: "Unable to fetch Countries",
        errorFetchCompany: "Unable to fetch Company",
        errorCompanyNotFound: "Company not found",
        errorFetchSectors: "Unable to fetch selectors",
        toggleCompany: "Toggle Company",
        kybStatus: "KYB Status",
        enabled: "Enabled",
        disabled: "Disabled",
        active: "Active",
        "not active": "Not active",
    },
    UpdateEmployeeView: {
        disable: "disable",
        enable: "enable",
        pleaseConfirmEmployee: "Please confirm you want to {{value}} this Employee",
        id: "ID: {{value}}",
        notifyEmployeeUpdated: "Employee successfully updated",
        notifyEmployeeStatusUpdated: "Employee ID successfully updated",
        pageTitle: "Employee",
        loading: "Loading...",
        errorFetchEmployee: "Unable to fetch employee",
        errorEmployeeNotFound: "Employee not found",
        toggleEmployee: "Toggle Employee",
    },
    UserSettingsView: {
        pageTitle: "Manage Profile",
        headerTitle: "Manage Profile",
        changePassword: "Change Password",
    },
    "UserSettingsView.ChangePassword": {
        successMessage: "Password has been changed",
    },
    "UserSettingsView.ChangePasswordForm": {
        oldPasswordLabel: "Old Password",
        oldPasswordPlaceholder: "Enter old password",
        newPasswordLabel: "New Password",
        newPasswordPlaceholder: "Enter new password",
        confirmNewPasswordLabel: "Confirm new password",
        confirmNewPasswordPlaceholder: "Enter new password",
        save: "Save",
    },
    "UserSettingsView.CompanyDetails": {
        header: "Company Details",
    },
    "UserSettingsView.PersonalDetails": {
        header: "Personal Details",
    },
    WithdrawalSettingsView: {
        cannotRetriveCompanyId: "Unable to retrive Company ID",
        configurationsSavedSuccessfully: "Configuration successfully saved",
        pageTitle: "pageTitle",
        loading: "loading",
    },
    "WithdrawalSettingsView.WithdrawalSettingsForm": {
        withdrawalInformation: "Example: if you set 40% as a limit of Pay Advance, that means the available amount to Pay Advance for an employee is calculated as 40% of his/her total accumulated amount.",
        withdrawalSettings: "Withdrawal Settings",
        withdrawalLimit: "Withdrawal Limit",
        transactionMonthlyLimit: "Transaction Monthly Limit",
        transactionFeeEur: "Transaction Fee (EUR)",
        setPercentage: "Set a percentage (max 50%)",
    },
    ValidationErrors: {
        "Incorrect Id Payroll Item": "Incorrect ID Payroll Item",
        "Incorrect company code": "Incorrect Company Code",
        "This field is required": "This field is required",
        "Account name is too long (max 70 characters)": "Account name is too long (max 70 characters)",
        "Incorrect address line": "Incorrect address line",
        "Incorrect date (DD-MM-YYYY)": "Incorrect date (DD-MM-YYYY)",
        "Person has to be at least 18 years old": "Person has to be at least 18 years old",
        "Person cannot be older than 100 years": "Person cannot be older than 100 years",
        "Incorrect city name": "Incorrect city name",
        "Incorrect company name": "Incorrect company name",
        "Company name can be max 70 characters long": "Company name can be max 70 characters long",
        "Incorrect company registration ID (max 11 characters)": "Incorrect company registration ID (max 11 characters)",
        "Invalid CUC number (8 characters)": "Invalid CUC number (8 characters)",
        "Incorrect e-mail address": "Incorrect e-mail address",
        "Incorrect employee ID": "Incorrect employee ID",
        "Invalid IBAN": "Invalid IBAN",
        "Incorrect job title": "Incorrect job title",
        "Must include only A-Z, 0-9, spaces and -": "Must include only A-Z, 0-9, spaces and -",
        "Part-time must be in range 1% - 100%": "Part-time must be in range 1% - 100%",
        "At least 8 characters required": "At least 8 characters required",
        "One digit required": "One digit required",
        "One upper case letter required": "One upper case letter required",
        "One special character required": "One special character required",
        "Incorrect first name": "Incorrect first name",
        "Incorrect last name": "Incorrect last name",
        "Has to start with country code (+XX)": "Has to start with country code (+XX)",
        "Incorrect phone number": "Incorrect phone number",
        "Incorrect postal code": "Incorrect postal code",
        "Incorrect tax ID": "Incorrect tax ID (11 digits or 16 characters: 0-9 A-Z)",
    },
    Chart: {
        Jan: "Jan",
        Feb: "Feb",
        Mar: "Mar",
        Apr: "Apr",
        May: "May",
        Jun: "Jun",
        Jul: "Jul",
        Aug: "Aug",
        Sep: "Sep",
        Oct: "Oct",
        Nov: "Nov",
        Dec: "Dec",
    },
    XRHResponse: {},
    Sectors: {
        "Wholesale and retail trade": "Wholesale and retail trade",
        "Water supply, sewerage and waste management": "Water supply, sewerage and waste management",
        "Transport and logistics": "Transport and logistics",
        "Real estate": "Real estate",
        "Public administration and defence Professional": "Public administration and defence Professional",
        "Scientific and technical services": "Scientific and technical services",
        "Retail and other services": "Retail and other services",
        "Online gambling": "Online gambling",
        "Mines, quarries and raw materials": "Mines, quarries and raw materials",
        Manufacturing: "Manufacturing",
        "Information and communication": "Information and communication",
        "Human health, pharma and social work": "Human health, pharma and social work",
        "Financial and insurance services": "Financial and insurance services",
        "Electricity, gas, steam and air conditioning": "Electricity, gas, steam and air conditioning",
        Education: "Education",
        Automotive: "Automotive",
        "Construction Arts, entertainment and recreation": "Construction Arts, entertainment and recreation",
        "Agriculture, forestry and fishing": "Agriculture, forestry and fishing",
        Consulting: "Consulting",
        "Accommodation and food services": "Accommodation and food services",
        "Chemical and plastic": "Chemical and plastic",
        Construction: "Construction",
        "Luxury and fashion": "Luxury and fashion",
        Forniture: "Forniture",
    },
    EmployeesUploadModal: {
        uploadFiles: "Upload file",
        reviewEmployeeData: "Verify data"
    },
    EmployeeUploadReviewDetail: {
        "already exist": "Already exists",
        "must be filled": "Must be filled",
        "is in invalid format": "Invalid format",
        "defined multiple times": "Defined multiple times"
    }
};
