import styled from "@emotion/styled";
import { transparentize } from "polished";
import { fontSize, fontWeight, colors } from "@/styleguide";
export var StyledRoot = styled.div({
    backgroundColor: colors.white,
    borderRadius: 12,
    boxShadow: "0 0 10px " + transparentize(0.8, colors.shipGrey),
    display: "flex",
    flexDirection: "column",
    minHeight: 110,
    justifyContent: "space-between",
    padding: 20,
});
export var Title = styled.div({
    color: colors.grey,
    fontSize: fontSize.small,
    fontWeight: fontWeight.semiBold,
    textAlign: "right",
});
export var BottomRow = styled.div({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
});
export var Value = styled.span({
    fontSize: fontSize.xLarge,
    fontWeight: fontWeight.bold,
});
