var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useTranslation } from 'react-i18next';
import { Error, Label, StyledRoot } from "./styles";
export function FormField(_a) {
    var children = _a.children, error = _a.error, label = _a.label, props = __rest(_a, ["children", "error", "label"]);
    var t = useTranslation('ValidationErrors').t;
    return (React.createElement(StyledRoot, __assign({}, props),
        label && React.createElement(Label, null, label),
        children,
        error && React.createElement(Error, null, t(error))));
}
