import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { Modal } from "@/components/molecules/Modal";
import { Paragraph, Buttons } from "./styles";
export var ConfirmModal = function (props) {
    var t = useTranslation("Molecules.ConfirmModal").t;
    return (React.createElement(Modal, { closable: true, onModalClose: props.onCancel, size: "small" },
        React.createElement(Paragraph, null, props.children),
        React.createElement(Buttons, null,
            React.createElement(Button, { onClick: props.onCancel, theme: "secondary", type: "button", width: 180 }, t("cancel")),
            React.createElement(Button, { onClick: props.onConfirm, type: "submit", width: 180 }, props.confirmButtonText))));
};
ConfirmModal.defaultProps = {
    confirmButtonText: "OK",
};
