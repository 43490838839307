import styled from "@emotion/styled";
import { transparentize } from "polished";
import { colors, ZIndex, fontSize, fontWeight } from "@/styleguide";
export var Overlay = styled.div({
    backgroundColor: transparentize(0.88, colors.shipGrey),
    bottom: "-100%",
    left: "-100%",
    position: "fixed",
    right: "-100%",
    top: "-100%",
    zIndex: ZIndex.MODAL_OVERLAY,
});
export var ModalWrapper = styled.div({
    alignItems: "center",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    maxHeight: "100vh",
    overflow: "auto",
    position: "fixed",
    right: 0,
    textAlign: "center",
    top: 0,
    width: "100vw",
    zIndex: ZIndex.MODAL,
}, function (props) { return ({
    cursor: props.closable ? "pointer" : "default",
}); });
export var ModalContent = styled.div({
    backgroundColor: colors.white,
    borderRadius: 12,
    cursor: "default",
    display: "inline-block",
    maxHeight: "100%",
    maxWidth: "100%",
    overflow: "auto",
    padding: "30px 40px",
    textAlign: "initial",
}, function (props) { return ({
    height: props.theme.height,
    width: props.theme.width,
}); });
export var ModalHeader = styled.header({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
});
export var ModalTitle = styled.h3({
    fontSize: fontSize.medium,
    fontWeight: fontWeight.medium,
    margin: 0,
});
export var ModalClose = styled.span({
    cursor: "pointer",
    fill: colors.java,
});
