import styled from "@emotion/styled";
import { colors, fontSize, fontWeight, rem } from "@/styleguide";
export var AvatarPlaceholder = styled.span({
    alignItems: "center",
    borderRadius: "50%",
    color: colors.white,
    display: "inline-flex",
    fontSize: rem(fontSize.tiny),
    fontWeight: fontWeight.semiBold,
    justifyContent: "center",
}, function (props) {
    var avatarSize = props.avatarSize || 30;
    return {
        backgroundColor: props.theme.backgroundColor,
        height: avatarSize,
        width: avatarSize,
        fontSize: rem(avatarSize / 3),
    };
});
