import styled from "@emotion/styled";
export var Wrapper = styled.div({
    alignItems: "center",
    display: "flex",
});
export var Name = styled.span({
    cursor: "pointer",
});
export var SorterWrapper = styled.span({
    display: "inline-flex",
    marginLeft: 10,
});
