import { getDateLocale } from '@/utils/format';
import { format } from "date-fns";
export var createDailySeries = function (payAdvances) {
    return payAdvances.map(function (payAdvance) { return ({
        value: payAdvance.amount,
        label: format(payAdvance.date, "DD", {
            locale: getDateLocale()
        }),
    }); });
};
export var createMonthlySeries = function (payAdvances) {
    return payAdvances.map(function (payAdvance) { return ({
        value: payAdvance.amount,
        label: format(payAdvance.date, "MMM"),
    }); });
};
