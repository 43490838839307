import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { FormField } from "@/components/atoms/FormField";
import { TextInput } from "@/components/atoms/TextInput";
import { FormMessage } from "@/components/molecules/FormMessage";
import { createValidation, emailRules } from "@/utils/validate";
import { ButtonWrapper, FormMessageWrapper } from "./styles";
var validate = createValidation({
    email: emailRules,
});
export function ForgotPasswordForm(props) {
    var _a;
    var t = useTranslation("ForgotPasswordView.ForgotPasswordForm").t;
    var _b = useFormik({
        validate: validate,
        initialValues: {
            email: (_a = process.env.DEFAULT_USER_EMAIL) !== null && _a !== void 0 ? _a : "",
        },
        onSubmit: props.onSubmit,
    }), errors = _b.errors, touched = _b.touched, handleBlur = _b.handleBlur, handleChange = _b.handleChange, handleSubmit = _b.handleSubmit, values = _b.values;
    return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(FormField, { error: touched.email ? errors.email : undefined, label: t("labelEmail") },
            React.createElement(TextInput, { name: "email", onChange: handleChange, placeholder: t("emailPlaceholder"), onBlur: handleBlur, value: values.email })),
        React.createElement(ButtonWrapper, null,
            React.createElement(Button, { disabled: props.loading, type: "submit" }, t("submit"))),
        props.formMessage && props.formStatus && (React.createElement(FormMessageWrapper, null,
            React.createElement(FormMessage, { type: props.formStatus }, props.formMessage)))));
}
