var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery, } from "@apollo/client";
import { apolloClient } from "@/apollo";
import { getAuthContext } from "./auth";
export var createQueryHook = function (documentNode) { return function (variables, queryOptions) {
    return useQuery(documentNode, __assign({ context: getAuthContext(), variables: variables }, queryOptions));
}; };
export var createQueryRun = function (documentNode) { return function (variables, options) {
    if (options === void 0) { options = {}; }
    var queryOptions = __assign(__assign({}, options), { context: getAuthContext(), query: documentNode, variables: variables });
    return apolloClient.query(queryOptions);
}; };
export var createQueryRefetch = function (documentNode) { return function (variables) {
    return {
        context: getAuthContext(),
        query: documentNode,
        variables: variables,
    };
}; };
