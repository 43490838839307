var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import noop from "lodash/noop";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { runLogOut } from "@/containers/mutations/LogOut";
import { runViewerQuery } from "@/containers/queries/ViewerQuery";
import { getAuthToken, removeAuthToken, removeDirectLanguage, setAuthToken } from "@/utils/auth";
export var ViewerContext = React.createContext({
    isAdmin: false,
    isEmployee: false,
    isEmployer: false,
    loading: true,
    onLogIn: noop,
    onLogOut: noop,
    refetching: false,
    refetchViewer: noop,
    viewer: null,
});
export var ViewerContextProvider = function (props) {
    var isMountRef = useRef(true);
    var _a = useState(function () {
        var token = getAuthToken();
        return {
            loading: typeof token === "string",
            refetching: false,
            viewer: null,
        };
    }), state = _a[0], setState = _a[1];
    var shouldFetchOnMountRef = useRef(state.loading);
    var viewerRole = state.viewer && state.viewer.role;
    var handleLogIn = useCallback(function (authToken, viewer) {
        setAuthToken(authToken);
        setState({
            viewer: viewer,
            loading: false,
            refetching: false,
        });
    }, []);
    var handleLogOut = useCallback(function () {
        setState({
            loading: false,
            refetching: false,
            viewer: null,
        });
        runLogOut({}).finally(function () {
            removeAuthToken();
            removeDirectLanguage();
        });
    }, []);
    var fetchViewer = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var newState, res, viewer, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    newState = {
                        loading: false,
                        refetching: false,
                        viewer: null,
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, runViewerQuery({ fetchPolicy: "no-cache" })];
                case 2:
                    res = _b.sent();
                    viewer = res.data.viewer;
                    // If there's logged in user, add it to component's state.
                    if (viewer !== null) {
                        newState = __assign(__assign({}, newState), { viewer: viewer });
                    }
                    return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 4:
                    // Don't update after unmount.
                    if (!isMountRef.current) {
                        return [2 /*return*/];
                    }
                    // If we attempted to fetch viewer, but we failed,
                    // that means that if we have a token, it's invalid.
                    // Remove it.
                    if (newState.viewer === null) {
                        removeAuthToken();
                        removeDirectLanguage();
                    }
                    setState(newState);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var refetchViewer = useCallback(function () {
        setState(__assign(__assign({}, state), { refetching: true }));
        fetchViewer();
    }, [fetchViewer, state]);
    // Fetch viewer if we have a token on mount.
    // This is when user is logged in and refreshes the page.
    useEffect(function () {
        if (shouldFetchOnMountRef.current) {
            fetchViewer();
        }
    }, [fetchViewer]);
    // Set mount flag.
    useEffect(function () {
        return function () {
            isMountRef.current = false;
        };
    }, []);
    var providedValue = {
        refetchViewer: refetchViewer,
        isAdmin: viewerRole === "admin",
        isEmployee: viewerRole === "employee",
        isEmployer: viewerRole === "employer" || viewerRole === "key_contact",
        loading: state.loading,
        onLogIn: handleLogIn,
        onLogOut: handleLogOut,
        refetching: state.refetching,
        viewer: state.viewer,
    };
    return React.createElement(ViewerContext.Provider, __assign({}, props, { value: providedValue }));
};
