import React from "react";
import { useTranslation } from "react-i18next";
import { PublicView } from "@/components/templates/PublicView";
import { useResetPassword } from "@/containers/mutations/ResetPassword";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { ContentWrapper, Paragraph } from "./styles";
export var ForgotPasswordView = function () {
    var t = useTranslation("ForgotPasswordView").t;
    var _a = useResetPassword({
        successMessage: t("successMessage"),
    }), resetPassword = _a[0], status = _a[1];
    return (React.createElement(PublicView, { pageTitle: t("pageTitle"), viewTitle: t("viewTitle") },
        React.createElement(ContentWrapper, null,
            React.createElement(Paragraph, null, t("enterYourEmail")),
            React.createElement(ForgotPasswordForm, { formMessage: status.message, formStatus: status.severity, loading: status.loading, onSubmit: resetPassword }))));
};
