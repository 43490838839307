var Signal;
(function (Signal) {
    Signal[Signal["ABORT"] = 0] = "ABORT";
    Signal[Signal["SKIP"] = 1] = "SKIP";
    Signal[Signal["CONTINUE"] = 2] = "CONTINUE";
})(Signal || (Signal = {}));
function identity(arg) {
    return arg;
}
export function createValidation(schema) {
    return function validation(vals) {
        var _a;
        var errors = {};
        for (var fieldId in schema) {
            if (Object.prototype.hasOwnProperty.call(schema, fieldId)) {
                var validations = (_a = schema[fieldId]) !== null && _a !== void 0 ? _a : [];
                for (var _i = 0, validations_1 = validations; _i < validations_1.length; _i++) {
                    var validationRecord = validations_1[_i];
                    var validator = validationRecord[0], message = validationRecord[1], _b = validationRecord[2], transform = _b === void 0 ? identity : _b;
                    var result = validator(transform(vals[fieldId]), vals);
                    // If current validation should be skipped just go to the next one.
                    if (result === Signal.SKIP) {
                        // eslint-disable-next-line no-continue
                        continue;
                    }
                    // Don't perform further validation.
                    if (result === Signal.ABORT) {
                        break;
                    }
                    // Here we ignore Signal.CONTINUE.
                    if (result === false) {
                        errors[fieldId] = message;
                        // Stop after first error.
                        break;
                    }
                }
            }
        }
        return errors;
    };
}
// Apply `validator` to another field specified by `fieldId`.
export function ifOther(fieldId, validator) {
    return function (_val, vals) {
        return validator(vals[fieldId], vals);
    };
}
// Abort validation process if `validator` returns true for current field value.
export function abortIf(validator) {
    return function (val, vals) {
        if (validator(val, vals)) {
            return Signal.ABORT;
        }
        return Signal.CONTINUE;
    };
}
// Abort validation process if `validator` returns true for field specified by `fieldId`.
export function abortIfOther(fieldId, validator) {
    return function (_val, vals) {
        if (validator(vals[fieldId], vals)) {
            return Signal.ABORT;
        }
        return Signal.CONTINUE;
    };
}
// Executes `validator` only if `predicate` returns true.
// Otherwise it informs validation runner to skip to the next validation.
export function execIf(validator, predicate) {
    return function (val, vals) {
        if (predicate(val, vals)) {
            return validator(val, vals);
        }
        return Signal.SKIP;
    };
}
