var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionLink } from "@/components/atoms/ActionLink";
import { ActivityStatus } from "@/components/atoms/ActivityStatus";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { Link } from "@/components/atoms/Link";
import { AvatarWithLabel } from "@/components/molecules/AvatarWithLabel";
import { Pagination } from "@/components/molecules/Pagination";
import { SortingTrigger } from "@/components/molecules/SortingTrigger";
import { Table, Thead, Tbody, Th, Td, Tr } from "@/components/molecules/Table";
import { ConfirmModal } from "@/components/organisms/ConfirmModal";
import { EmployeesUploadModal } from "@/components/organisms/EmployeesUploadModal";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { PER_PAGE, DEFAULT_SEARCH_TEXT, DEFAULT_SORT_DIR, DEFAULT_SORT_OPTION, DEFAULT_PAGE, } from "@/constants/queries";
import { ViewPath } from "@/constants/routing";
import { useUpdateEmployeeStatus } from "@/containers/mutations/UpdateEmployeeStatus";
import { useUpdateWithdrawalStatus } from "@/containers/mutations/UpdateWithdrawalStatus";
import { useCompanyEmployeesQuery, refetchCompanyEmployeesQuery, } from "@/containers/queries/CompanyEmployeesQuery";
import { useEmployeesStatsQuery } from "@/containers/queries/EmployeesStatsQuery";
import { useNotifier } from "@/hooks/useNotifier";
import { useToggle } from "@/hooks/useToggle";
import { useViewer } from "@/hooks/useViewer";
import { formatErrorMessage, formatDate } from "@/utils/format";
import { getAvatarPlaceholder } from "@/utils/misc";
import { applyUrlParams } from "@/utils/routing";
import { EmployeeActions } from "./EmployeeActions";
import { EmployeesViewHeader } from "./EmployeesViewHeader";
import { EmployeesViewToolbar } from "./EmployeesViewToolbar";
import { BackgroundProvider, ErrorMessage, TableWrapper, Plug, ToRight, PaginationWrapper, } from "./styles";
var ConfirmAction;
(function (ConfirmAction) {
    ConfirmAction[ConfirmAction["BLOCK_TRANSACTIONS"] = 0] = "BLOCK_TRANSACTIONS";
    ConfirmAction[ConfirmAction["UNBLOCK_TRANSACTIONS"] = 1] = "UNBLOCK_TRANSACTIONS";
    ConfirmAction[ConfirmAction["DISABLE"] = 2] = "DISABLE";
    ConfirmAction[ConfirmAction["ENABLE"] = 3] = "ENABLE";
})(ConfirmAction || (ConfirmAction = {}));
var getNotificationForAction = function (action, t) {
    switch (action) {
        case ConfirmAction.BLOCK_TRANSACTIONS:
            return t("notificationForAction.blockTransaction");
        case ConfirmAction.UNBLOCK_TRANSACTIONS:
            return t("notificationForAction.unblockTransaction");
        case ConfirmAction.DISABLE:
            return t("notificationForAction.employeeDisabled");
        case ConfirmAction.ENABLE:
            return t("notificationForAction.employeeEnabled");
    }
};
var getMessageForAction = function (action, t) {
    switch (action) {
        case ConfirmAction.BLOCK_TRANSACTIONS:
            return t("messageForActions.blockTransaction");
        case ConfirmAction.UNBLOCK_TRANSACTIONS:
            return t("messageForActions.unblockTransaction");
        case ConfirmAction.DISABLE:
            return t("messageForActions.employeeDisabled");
        case ConfirmAction.ENABLE:
            return t("messageForActions.employeeEnabled");
    }
};
export var EmployeesView = function (props) {
    var _a, _b, _c, _d;
    var t = useTranslation("EmployeeView").t;
    var tXHR = useTranslation("XRHResponse");
    var notify = useNotifier().notify;
    var _e = React.useState(DEFAULT_SEARCH_TEXT), tmpSearchText = _e[0], setTmpSearchText = _e[1];
    var _f = React.useState(DEFAULT_SEARCH_TEXT), searchText = _f[0], setSearchText = _f[1];
    var _g = React.useState(DEFAULT_SORT_DIR), sortDir = _g[0], setSortDir = _g[1];
    var _h = React.useState(DEFAULT_SORT_OPTION), sortOption = _h[0], setSortOption = _h[1];
    var _j = useToggle(), isModalOpen = _j[0], openModal = _j[1], closeModal = _j[2];
    var _k = useState(null), confirmForUser = _k[0], setConfirmForUser = _k[1];
    var _l = React.useState(DEFAULT_PAGE), page = _l[0], setPage = _l[1];
    var companyId = props.match.params.companyId;
    var viewer = useViewer().viewer;
    var isAdmin = viewer.role === "admin";
    var onColumnSort = React.useCallback(function (name, direction) {
        setSortOption(name);
        setSortDir(direction);
    }, []);
    var onSearchTextUpdate = React.useCallback(function (newSearchText) {
        setTmpSearchText(newSearchText);
    }, []);
    var onSearch = React.useCallback(function () {
        setSearchText(tmpSearchText);
        setPage(DEFAULT_PAGE);
    }, [tmpSearchText]);
    var onReset = React.useCallback(function () {
        setTmpSearchText(DEFAULT_SEARCH_TEXT);
        setSearchText(DEFAULT_SEARCH_TEXT);
        setSortOption(DEFAULT_SORT_OPTION);
        setSortDir(DEFAULT_SORT_DIR);
        setPage(DEFAULT_PAGE);
    }, []);
    var onBulkUploadConfirm = React.useCallback(function () {
        props.history.push(ViewPath.EMPLOPYEES_REVIEW_UPLOAD);
    }, [props.history]);
    var order = React.useMemo(function () {
        return {
            key: sortOption,
            orderDirection: sortDir,
        };
    }, [sortDir, sortOption]);
    var canReset = sortOption !== DEFAULT_SORT_OPTION ||
        sortDir !== DEFAULT_SORT_DIR ||
        searchText !== DEFAULT_SEARCH_TEXT;
    var employeeQueryData = {
        order: order,
        page: page,
        perPage: PER_PAGE,
        search: searchText,
        companyId: companyId,
    };
    var employeesQuery = useCompanyEmployeesQuery(employeeQueryData, {
        fetchPolicy: "cache-and-network",
    });
    var cancelUserAction = function () { return setConfirmForUser(null); };
    var onUserActionSuccess = function () {
        if (confirmForUser) {
            notify(getNotificationForAction(confirmForUser.action, t));
        }
        cancelUserAction();
    };
    var updateEmployeeStatus = useUpdateEmployeeStatus({
        onCompleted: onUserActionSuccess,
        awaitRefetchQueries: true,
        refetchQueries: function () { return [refetchCompanyEmployeesQuery(employeeQueryData)]; },
    })[0];
    var updateWithdrawalStatus = useUpdateWithdrawalStatus({
        onCompleted: onUserActionSuccess,
        awaitRefetchQueries: true,
        refetchQueries: function () { return [refetchCompanyEmployeesQuery(employeeQueryData)]; },
    })[0];
    var changeUserStatus = function () {
        if (!confirmForUser) {
            return;
        }
        var action = confirmForUser.action, id = confirmForUser.id;
        if (action === ConfirmAction.DISABLE || action === ConfirmAction.ENABLE) {
            updateEmployeeStatus({
                id: id,
                active: action === ConfirmAction.ENABLE,
            });
        }
        else {
            updateWithdrawalStatus({
                id: id,
                withdrawalActive: action === ConfirmAction.UNBLOCK_TRANSACTIONS,
            });
        }
    };
    var statsQuery = useEmployeesStatsQuery({ companyId: companyId }, { fetchPolicy: "cache-and-network" });
    var activeUsersCount = (_b = (_a = statsQuery.data) === null || _a === void 0 ? void 0 : _a.stats.activeCount) !== null && _b !== void 0 ? _b : 0;
    var totalUsersCount = (_d = (_c = statsQuery.data) === null || _c === void 0 ? void 0 : _c.stats.totalCount) !== null && _d !== void 0 ? _d : 0;
    var employees = [];
    var totalPages = 1;
    var sorterProps = {
        activeDirection: sortDir,
        activeName: sortOption,
        onSort: onColumnSort,
    };
    return (React.createElement(ProtectedView, { pageTitle: t("pageTitle"), theme: "darken" },
        React.createElement(BackgroundProvider, null,
            React.createElement(InnerContainer, null,
                React.createElement(EmployeesViewHeader, { activeUsersCount: activeUsersCount, onBulkUploadClick: openModal, totalUsersCount: totalUsersCount, isAdmin: isAdmin }),
                isModalOpen && (React.createElement(EmployeesUploadModal, { onClose: closeModal, onConfirm: onBulkUploadConfirm })))),
        React.createElement(InnerContainer, null,
            confirmForUser && (React.createElement(ConfirmModal, { onCancel: cancelUserAction, onConfirm: changeUserStatus, confirmButtonText: t("confirm") }, getMessageForAction(confirmForUser.action, t))),
            React.createElement(EmployeesViewToolbar, { canReset: canReset, onChange: onSearchTextUpdate, onReset: onReset, onSearch: onSearch, searchText: tmpSearchText }),
            React.createElement(TableWrapper, null, (function () {
                if (employeesQuery.error) {
                    return (React.createElement(ErrorMessage, null, tXHR.t(formatErrorMessage(employeesQuery.error))));
                }
                if (employeesQuery.data && employeesQuery.data.employees) {
                    var _a = employeesQuery.data.employees, totalCount = _a.totalCount, perPage = _a.perPage, employeesList = _a.data;
                    employees = employeesList;
                    totalPages = Math.ceil(totalCount / perPage);
                }
                if (employees.length === 0) {
                    if (employeesQuery.loading) {
                        return React.createElement(Plug, null, "Fetching employees list...");
                    }
                    if (searchText.trim() !== "") {
                        return React.createElement(Plug, null, t("noEmployees"));
                    }
                    return (React.createElement(Plug, null,
                        t("noEmployeesAdded"),
                        !isAdmin && (React.createElement(React.Fragment, null,
                            React.createElement("br", null),
                            React.createElement(Link, { to: ViewPath.NEW_EMPLOYEE }, t("clickHere")),
                            " ",
                            t("toAdd")))));
                }
                return (React.createElement(React.Fragment, null,
                    React.createElement(Table, { cols: [20, 10, 30, 10, 10, 10, 10] },
                        React.createElement(Thead, null,
                            React.createElement(Th, null,
                                React.createElement(SortingTrigger, __assign({}, sorterProps, { name: "name" }), t("name"))),
                            React.createElement(Th, null,
                                React.createElement(SortingTrigger, __assign({}, sorterProps, { name: "externalEmployeeId" }), t("id"))),
                            React.createElement(Th, null, t("email")),
                            React.createElement(Th, null,
                                React.createElement(SortingTrigger, __assign({}, sorterProps, { name: "active" }), t("status"))),
                            React.createElement(Th, null,
                                React.createElement(SortingTrigger, __assign({}, sorterProps, { name: "createdAt" }), t("added"))),
                            React.createElement(Th, null),
                            React.createElement(Th, null)),
                        React.createElement(Tbody, null, employees.map(function (employee) { return (React.createElement(Tr, { key: employee.id },
                            React.createElement(Td, null,
                                React.createElement(AvatarWithLabel, { label: employee.firstName + " " + employee.lastName, placeholder: getAvatarPlaceholder(employee.firstName, employee.lastName), theme: employee.active ? "primary" : "inactive", url: isAdmin
                                        ? null
                                        : applyUrlParams(ViewPath.UPDATE_EMPLOYEE, {
                                            employeeId: employee.id,
                                        }) })),
                            React.createElement(Td, null, employee.employeeId),
                            React.createElement(Td, null, employee.email),
                            React.createElement(Td, null,
                                React.createElement(ActivityStatus, { active: employee.active })),
                            React.createElement(Td, null, formatDate(employee.createdAt)),
                            React.createElement(Td, null,
                                React.createElement(ToRight, null,
                                    React.createElement(EmployeeActions, { email: employee.email, isConfirmed: employee.confirmed }))),
                            React.createElement(Td, null, isAdmin && (React.createElement(React.Fragment, null,
                                React.createElement(ActionLink, { theme: "red", onClick: function () {
                                        return setConfirmForUser({
                                            id: employee.id,
                                            action: employee.active
                                                ? ConfirmAction.DISABLE
                                                : ConfirmAction.ENABLE,
                                        });
                                    } }, employee.active ? t("deactivate") : t("activate")),
                                React.createElement("br", null),
                                React.createElement(ActionLink, { theme: "red", onClick: function () {
                                        return setConfirmForUser({
                                            id: employee.id,
                                            action: employee.withdrawalActive
                                                ? ConfirmAction.BLOCK_TRANSACTIONS
                                                : ConfirmAction.UNBLOCK_TRANSACTIONS,
                                        });
                                    } }, employee.withdrawalActive
                                    ? t("block_transactions")
                                    : t("unblock_transactions"))))))); }))),
                    totalPages > 1 && (React.createElement(PaginationWrapper, null,
                        React.createElement(Pagination, { currentPage: page, onPageChange: setPage, totalPages: totalPages })))));
            })()))));
};
