import { ChangeLanguageBlock } from '@/components/organisms/ChangeLanguageBlock/ChangeLanguageBlock';
import { useChangeLanguage } from '@/containers/mutations/ChangeLangauge';
import React from "react";
import { useTranslation } from "react-i18next";
import { formClassName } from "./styles";
export var ChangeLanguage = function (props) {
    var t = useTranslation("UserSettingsView.ChangeLanguage").t;
    var _a = useChangeLanguage({
        successMessage: t("successMessage"),
    }), changeLanguage = _a[0], changeLanguageMeta = _a[1];
    return (React.createElement(ChangeLanguageBlock, { className: formClassName, formMessage: changeLanguageMeta.message, formStatus: changeLanguageMeta.severity, loading: changeLanguageMeta.loading, onSubmit: function (language) { return changeLanguage({ language: language }); } }));
};
