import React from "react";
import { Icon } from "@/components/atoms/Icon";
import { colors } from "@/styleguide";
var DropdownIndicator = function () {
    return React.createElement(Icon, { color: colors.java, name: "chevronDown", size: 18 });
};
export var customComponents = {
    ClearIndicator: null,
    DropdownIndicator: DropdownIndicator,
    IndicatorSeparator: null,
};
