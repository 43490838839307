import styled from "@emotion/styled";
import { fontSize, fontWeight, colors } from "@/styleguide";
export var ContentWrapper = styled.div({
    paddingTop: 30,
});
export var Row = styled.div({
    display: "flex",
    justifyContent: "space-between",
    marginTop: 120,
});
export var DescriptionColumn = styled.div({
    width: "calc(100% - 710px)",
});
export var CardsColumn = styled.div({
    width: 660,
});
export var Heading = styled.h1({
    fontSize: fontSize.huge,
    fontWeight: fontWeight.bold,
    margin: "50px 0 0",
    paddingTop: 20,
    position: "relative",
    ":before": {
        backgroundColor: colors.java,
        content: "''",
        display: "inline-block",
        height: 5,
        position: "absolute",
        top: 0,
        width: 140,
    },
});
export var Paragraph = styled.p({
    color: colors.grey,
    fontSize: fontSize.medium,
    fontWeight: fontWeight.light,
    lineHeight: 1.65,
});
export var Information = styled.div({
    marginLeft: 10,
});
export var FormMessageWrapper = styled.div({
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
});
export var FeeInputWrapper = styled.div({
    marginTop: 30,
    width: 200,
});
