var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import findIndex from "lodash/findIndex";
import memoizeOne from "memoize-one";
import React from "react";
import ReactSelect from "react-select";
import { customComponents } from "./custom";
import { styles } from "./styles";
var Select = /** @class */ (function (_super) {
    __extends(Select, _super);
    function Select() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onChange = function (option) {
            var _a;
            _this.props.onChange(_this.props.name, (_a = option === null || option === void 0 ? void 0 : option.value) !== null && _a !== void 0 ? _a : null);
        };
        _this.onBlur = function () {
            if (!_this.props.onBlur) {
                return;
            }
            _this.props.onBlur({
                target: {
                    name: _this.props.name,
                },
            });
        };
        _this.getSelectedOptions = memoizeOne(function (options, value) {
            if (value === null) {
                return null;
            }
            var index = findIndex(options, function (option) {
                return option.value === value;
            });
            if (index === -1) {
                return null;
            }
            return options[index];
        });
        return _this;
    }
    Select.prototype.render = function () {
        var selected = this.getSelectedOptions(this.props.options, this.props.value);
        return (React.createElement(ReactSelect, { closeMenuOnSelect: true, components: customComponents, isSearchable: false, onBlur: this.onBlur, onChange: this.onChange, options: this.props.options, styles: styles, placeholder: this.props.placeholder, value: selected }));
    };
    Select.defaultProps = {
        placeholder: null,
    };
    return Select;
}(React.Component));
export { Select };
