import React from "react";
import { Icon } from "@/components/atoms/Icon";
import { colors } from "@/styleguide";
import { Root, IconWrapper, Paragraph } from "./styles";
export function FormMessage(props) {
    var iconColor = colors.buttercup;
    var iconName = "cross";
    if (props.type === "success") {
        iconColor = colors.java;
        iconName = "check";
    }
    return (React.createElement(Root, null,
        React.createElement(IconWrapper, null,
            React.createElement(Icon, { color: iconColor, name: iconName })),
        React.createElement(Paragraph, null, props.children)));
}
