export var itTranslation = {
    General: {
        manageCompanies: "Società gestite",
        reports: "Report",
        employees: "Dipendenti",
        fundTransfer: "Richieste anticipi",
    },
    "Atoms.ActiveStatus": {
        active: "Attivo",
        inactive: "Inattivo",
    },
    "Atoms.Logo": {
        alt: "Consono Logo",
        src: "/assets/images/consonoLogo.svg",
    },
    "Molecules.ChangeLanguageBlock": {
        language: "Lingua",
    },
    "Molecules.CompanyDetails": {
        companyName: "Nome azienda",
        na: "N/A",
        industry: "Settore",
    },
    "Molecules.CompanyForm": {
        companyDetails: "Dettagli azienda",
        businessOwnerDetails: "Dettagli Business Owner",
        fieldsAreMandatory: "* i campi contrassegnati sono obbligatori",
        selectIndustry: "Selezionare l'area",
        na: "N/A",
        companyLabel: "Nome azienda*",
        companyPlaceholder: "Es. Industrie tecnologiche",
        companyIdLabel: "ID Azienda",
        companyIdPlaceholder: "Inserire ID Azienda",
        industryLabel: "Settore*",
        industryPlaceholder: "Selezionare un'area",
        vatLabel: "Partita Iva",
        vatPlaceholder: "Inserire la Partita Iva",
        paydayDateLabel: "Data del giorno di paga*",
        paydayDatePlaceholder: "Selezionare la data del giorno di paga",
        companyRealCode: "Codice Azienda",
        idPayrollItem: "Codice Voce",
        payrollFileFormat: "Formato file buste paga",
        cucNumberLabel: "Numbero CUC*",
        cucNumberPlaceholder: "8 caratteri",
        taxIdLabel: "Codice fiscale*",
        taxIdPlaceholder: "Inserire il codice fiscale",
        ibanLabel: "IBAN*",
        registeredAddress: "Inserire l'IBAN",
        countryLabel: "Nazione*",
        countryPlaceholder: "Selezionare la Nazione",
        provinceLabel: "Provincia*",
        provincePlaceholder: "Inserire la Provincia",
        cityLabel: "Città*",
        cityPlaceholder: "Es. Roma",
        postalCodeLabel: "Codice Postale*",
        postalCodePlaceholder: "Es. 00199",
        addressLabel: "Indirizzo*",
        addressPlaceholder: "Es. Via Sacra 1",
        copyRegisteredToTradingLabel: "L'indirizzo commerciale è lo stesso dell'indirizzo registrato",
        tradingAddress: "Indirizzo commerciale",
        firstNameLabel: "Nome*",
        firstNamePlaceholder: "Es. Marco",
        lastNameLabel: "Cognome*",
        lastNamePlaceholder: "Es. Rossi",
        emailLabel: "Email*",
        emailPlaceholder: "Es. marco.rossi@example.it",
        birthDateLabel: "Data di Nascita*",
        birthDatePlaceholder: "Es. 22-06-1987",
        phoneLabel: "Numero di cellulare*",
        phonePlaceholder: "Es. +39 0000 000000",
        businessOwnerAddress: "Indirizzo Business Owner",
        keyContactDetails: "Dettagli Contatto Chiave",
        jobTitleLabel: "Titolo di lavoro*",
        jobTitlePlaceholder: "Es. Sviluppatore",
        selectADay: "(Seleziona un giorno)",
        currentMonth27: "27 (Corrente mese)",
        followingMonth5: "5 (Mese successivo)",
        "payrollFileFormat-standard": "Standard",
        "payrollFileFormat-zucchetti": "Zucchetti",
        "KeyContact with email already exist": "L’utente con l’email {{ keyContactEmail }} esiste già",
        "Employer with email already exist": "L’utente con l’email {{ keyContactEmail }} esiste già",
        "User with mobile number already exist": "L’utente con il numero di cellulare {{ employerMobileNumber }} esiste già",
    },
    "Molecules.ConfirmModal": {
        cancel: "Annulla",
    },
    "Molecules.EmployeeForm": {
        na: "N/A",
        employeeAddress: "Indirizzo dipendente",
        employeeDetails: "Dettagli dipendente",
        firstNameLabel: "Nome*",
        firstNamePlaceholder: "Es. Marco",
        lastNameLabel: "Cognome*",
        lastNamePlaceholder: "Es. Rossi",
        emailLabel: "Email*",
        emailPlaceholder: "Es. marco.rossi@exaple.it",
        phoneLabel: "Numero di cellulare*",
        phonePlaceholder: "Es. +39 000 000000",
        birthDateLabel: "Data di Nascita*",
        birthDatePlaceholder: "GG-MM-ANNO",
        jobTitleLabel: "Titolo di lavoro",
        jobTitlePlaceholder: "Es. Sviluppatore",
        departmentLabel: "Dipartimento",
        departmentPlaceholder: "Inserire il Dipartimento",
        employeeIdLabel: "ID Dipendente",
        employeeIdPlaceholder: "Es. IE132",
        countryLabel: "Nazione*",
        countryPlaceholder: "Selezionare la Nazione",
        provinceLabel: "Provincia*",
        provincePlaceholder: "Inserire la Provincia",
        cityLabel: "Città*",
        cityPlaceholder: "Es. Roma",
        postalCodeLabel: "Codice Postale*",
        postalCodePlaceholder: "Es. 00199",
        addressLabel: "Indirizzo",
        addressPlaceholder: "Es. Via Sacra 1",
        salaryAndBankDetails: "Stipendio e coordinate bancarie",
        markedFieldsAreMandatory: "* i campi contrassegnati sono obbligatori",
        accruedAmountInactive: "Importo maturato indicativo",
        accruedAmountInactiveDetails: "Cifra indicativa che un dipendente ha maturato nell'attuale ciclo retributivo. Su questa base viene calcolato l'importo disponibile che un dipendente può prelevare.",
        grossMonthlySalaryLabel: "Stipendio mensile lordo*",
        grossMonthlySalaryPlaceholder: "Es. 3500.23",
        partTimeLabel: "Part-time* (%)",
        partTimePlaceholder: "Es. 50",
        insert100ForFullTime: "Inserisci 100 per tempo pieno",
        accountNameLabel: "Nome utente*",
        accountNamePlaceholder: "Es. Marco Rossi",
        accountNameSameAsEmployeeLabel: "Il nome del conto è uguale al nome dipendente",
        ibanLabel: "IBAN*",
        ibanPlaceholder: "Inserire l'IBAN",
        taxIdLabel: "Codice Fiscale*",
        taxIdPlaceholder: "Inserire il Codice Fiscale",
        "Employee with email already exist": "L’utente con l’email {{ employeeEmail }} esiste già",
        "User with mobile number already exist": "L’utente con il numero di cellulare {{ mobileNumber }} esiste già",
    },
    "Molecules.EmployeesUpload": {
        chooseFiles: "Scegliere i file da caricare",
        downloadSample: "Scaricare il file di esempio",
        dragAndDropFilesHere: "Trascinare e rilasciare il file qui",
    },
    "Molecules.PersonalDetails": {
        phone: "Telefono",
        email: "Email",
        na: "N/A",
    },
    "Molecules.CardViewButtons": {
        cancel: "Annulla",
        save: "Salva",
    },
    AppHeader: {
        withdrawalSettings: "Impostazioni sull’anticipo",
        manageProfile: "Gestire il profilo",
    },
    CompaniesReportsView: {
        currentYear: "(anno in corso)",
        currentMonth: "(mese in corso)",
        Daily: "Giornaliero",
        Monthly: "Mensile",
        pageTitle: "Report",
        theme: "scurire",
        transactionHistory: "Cronologia delle transazioni",
        transactionFeeNotice: "Le commissioni sulle transazioni sono escluse dagli importi indicati di seguito",
        fetchingTransactionList: "Caricamento in corso...",
        transactionListEmpty: "Nessuna transazione nel periodo di tempo specificato.",
        statCards: {
            totalPayAdvances: {
                icon: "portafoglio",
                title1: "Totale anticipi sugli stipendi",
                title2: "{{ value }}",
                value: "{{ value }}",
            },
            totalTransactions: {
                icon: "transazioni",
                title1: "Totale transazioni",
                title2: "{{ value }}",
                value: "{{ value }}",
            },
        },
    },
    "CompaniesReportsView.ReportsTable": {
        date: "Data",
        companyName: "Nome azienda",
        employeeName: "Nome dipendente",
        transactionId: "ID Transazione",
        amount: "Importo",
    },
    "CompaniesReportsView.CompanyName": {},
    "CompaniesReportsView.ChartPanel": {
        trendOfPayAdvances: "Elenco degli anticipi effettuati",
    },
    CompaniesView: {
        pageTitle: "Aziende",
        loadingComponents: "Caricamento in corso...",
        errorOccurred: "Qualcosa è andato storto",
        createCompany: "Creare un'Azienda",
        companyName: "Nome Azienda",
        keyContact: "Contatto Chiave",
        totalEmployees: "Totale dipendenti",
        activeEmployees: "Dipendenti Attivi",
        totalTransactions: "Transazioni totali",
        totalPayAdvanced: "Totale anticipato",
        addCompany: "Aggiungere un'Azienda",
    },
    "CompaniesView.CompanyActions": {
        notifyProblemMonthlyReport: "Si è verificato un problema durante il download del report",
        downloadResponsesReport: "Scarica il report sulle risposte",
        viewEmployees: "Visualizza dipendenti",
        viewReport: "Visualizza Report",
        inviteSent: "Invia invito",
        sendInvite: "Invito inviato",
    },
    CreateCompanyView: {
        notifyCompanyProfileAdded: "Profilo aziendale aggiunto con successo",
        addCompany: "Aggiungere un'Azienda",
        createCompany: "Creare un'Azienda",
        loading: "Caricamento in corso...",
        errorFetchSectorList: "Impossibile recuperare l'elenco dei settori",
        errorFetchCountryList: "Impossibile recuperare l'elenco dei paesi",
        activeEmployees: "Dipendente Attivo",
        totalTransactions: "Totale Transazioni",
        totalPayAdvanced: "Totale Anticipato",
    },
    CreateEmployeeView: {
        notifyEmployeeAdded: "Dipendente aggiunto con successo",
        addNewEmployee: "Aggiungere Nuovo Dipendente",
        newEmployee: "Nuovo Dipendente",
        loading: "Caricamento in corso...",
    },
    EmployeesReportsView: {
        Daily: "Giornaliero",
        Monthly: "Mensile",
        pageTitle: "Report",
        theme: "Scurire",
        downloadReport: "Download Report",
        downloadReportSmart: "Esporta Libro Paga",
        transactionHistory: "Cronologia delle transazioni",
        transactionFeeNotice: "Le commissioni sulle transazioni sono escluse dagli importi indicati di seguito",
        fetchingTransactionList: "Caricamento in corso...",
        transactionListEmpty: "Nessuna transazione nel periodo di tempo specificato.",
        statCards: {
            totalPayAdvances: {
                icon: "walletportafoglio",
                title1: "Totale anticipato",
                title2: "{{ value }}",
                value: "{{ value }}",
            },
            totalTransactions: {
                icon: "transazioni",
                title1: "Totale transazioni",
                title2: "{{ title2 }}",
                value: "{{ value }}",
            },
        },
    },
    "EmployeesReportsView.ReportsTable": {
        timeStamp: "Data",
        transactionId: "ID Transazione",
        amountAdvanced: "Importo Anticipato",
    },
    "EmployeesReportsView.ChartPanel": {
        trendOfPayAdvances: "Elenco degli anticipi effettuati",
    },
    EmployeeUploadReviewView: {
        notifyCreateSuccess: "Dipendente creato con successo",
        pageTitle: "Revisione dei dipendenti",
        employeeUploadedForReview: "Dipendente caricato per la revisione",
        cancel: "Annulla",
        selectAllCorrectRecords: "Seleziona tutti i campi corretti",
        firstName: "Nome",
        lastName: "Cognome",
        birthDate: "Data di Nascita",
        email: "Email",
        phone: "Numero di Cellulare",
        jobTitle: "Titolo di Lavoro",
        grossMonthSalary: "Stpendio Lordo Mensile",
        partTime: "Part Time",
        accountName: "Nome Utente",
        iban: "IBAN",
        taxId: "Codice Fiscale",
        country: "Nazione",
        province: "Provincia",
        city: "Città",
        postCode: "Codice Postale",
        address: "Indirizzo",
        loadMoreRows: "Carica più righe",
        save: "Salva",
        saveCount: "Salva ({{value}})",
    },
    "EmployeeUploadReviewView.EmployeeDetail": {},
    EmployeeView: {
        notificationForAction: {
            blockTransaction: "Lo stato sulle transazioni del dipendente è stato bloccato correttamente",
            unblockTransaction: "Lo stato sulle transazioni del dipendente è stato sbloccato correttamente",
            employeeDisabled: "Dipendente disabilitato con successo",
            employeeEnabled: "Dipendente attivato con successo",
        },
        messageForActions: {
            blockTransaction: "Conferma di voler bloccare le transazioni per questo dipendente",
            unblockTransaction: "Conferma di voler sbloccare le transazioni per questo dipendente",
            employeeDisabled: "Conferma di voler disabilitare questo dipendente",
            employeeEnabled: "Conferma di voler attivare questo dipendente",
        },
        pageTitle: "Dipendente",
        confirm: "Conferma",
        noEmployees: "Nessun dipendente nella richiesta specificata",
        noEmployeesAdded: "Nessun dipendente aggiunto",
        clickHere: "Clicca qui",
        toAdd: "o su aggiungere più dipendenti in alto",
        name: "Nome",
        id: "ID",
        email: "Email",
        status: "Stato",
        added: "Aggiunto",
        deactivate: "Disattivare",
        activate: "Attivare",
        block_transactions: "Bloccare le transazioni",
        unblock_transactions: "Sbloccare le transazioni",
    },
    "EmployeeView.EmployeeViewToolbar": {
        searchPlaceholder: "Cerca dipendente",
        clearFilters: "Cancella filtri",
    },
    "EmployeeView.EmployeeViewHeader": {
        totalEmployees: "Totale Dipendenti",
        activeEmployees: "Dipendenti Attivi",
        addEmployee: "Aggiungere un dipendente",
        addMultipleEmployees: "Aggiungere più dipendenti",
    },
    "EmployeeView.EmployeeActions": {
        inviteResent: "Invito Inviato",
        resendInvite: "Rinviare l'Invito",
    },
    ForgotPasswordView: {
        pageTitle: "Password Dimenticata",
        viewTitle: "Password Dimenticata",
        successMessage: "Riceverai un'email se il tuo indirizzo verrà riconosciuto",
        enterYourEmail: "Inserisci la tua email",
    },
    "ForgotPasswordView.ForgotPasswordForm": {
        labelEmail: "Email",
        emailPlaceholder: "Inserisci la tua email",
        submit: "Invia",
    },
    FundsView: {
        selected: "Selezionato",
        totalSelected: "Totale selezionato",
        totalApproved: "Totale completato",
        pageTitle: "Richieste Anticipi",
        viewTitle: "Richieste Anticipi",
        successMessage: "Riuscito",
        enterYourEmail: "Inserire la tua Email",
        transactionSuccessful: "Le transazioni sono state {{value}} correttamente",
        noFundsRequestedForCycle: "Non ci sono fondi disponibili",
        unableToDownloadReports: "Impossibile scaricare i report",
        actionModalMessageApprove: "Conferma di voler approvare queste transazioni?",
        actionModalMessageDecline: "Confermi di voler rifiutare queste transazioni?",
        actionModalMessageReverse: "Hai effettuato il pagamento dell'anticipo? Sei sicuro di voler annullare l'operazione?",
        confirm: "Confermare",
        loading: "Caricamento in corso...",
        empty: "Nessun trasferimento di fondi effettuato nel periodo di tempo richiesto.",
        select: "Selezionare",
        employeeName: "Nome Dipendente",
        requestDate: "Data della Richiesta",
        iban: "IBAN",
        requestAmount: "Importo Richiesto",
        requestStatus: "Stato della Richiesta",
        transactionStatus: "Stato della Transazione",
        decline: "rifiutata",
        declined: "rifiutata",
        approve: "approvata",
        approved: "approvata",
        reverse: "annullato",
        reverted: "annullato",
        revert: "annullato",
        reversed: "annullato",
        complete: "completato",
        completed: "completato",
        __decline: "Rifiuta",
        __approve: "Approva",
        __reverse: "Annulla",
        ___approved: "approvate",
        ___declined: "rifiutate",
        ___reversed: "annullate",
        pending: "In attesa di approvazione",
        areYouSureToSendBankTransfer: "Sei sicuro di voler scaricare il file per effettuare il bonifico bancario?",
        "There is not available transactions for reverse": "Operazione non consentita",
        "There is not available transactions for approve": "Operazione non consentita",
        "There is not available transactions for decline": "Operazione non consentita",
        "Authentication failed": "Autenticazione fallita",
        "More than one type of transaction selected to update": "Hai selezionato più di un tipo di transazione: aggiorna il tipo di selezione",
    },
    "FundsView.FundsViewHeader": {
        bankTransfer: "Bonifico Bancario",
        totalCompleted: "Totale Completato: {{value}}",
    },
    LoginView: {
        pageTitle: "Accedere",
        viewTitle: "Accedere",
        errorUnexpected: "Qualcosa è andato storto",
        error: "Qualcosa è andato storto",
    },
    "LoginView.LoginForm": {
        form: {
            email: {
                label: "Email",
                placeholder: "Inserire l'email",
            },
            password: {
                label: "Password",
                placeholder: "Inserire la password",
            },
        },
        resetPasswordActionCallTitle: "",
        resetPasswordActionCallContent: "Hai dimenticato la password?",
        submit: "Invia",
    },
    ResetPasswordWithTokenView: {
        viewTitle: "Ciao, {{value}}!",
        yourPasswordHasBeenSet: "La tua password è stata impostata",
        pageTitle: "Reimposta la password",
        validatingToken: "Convalida il Simbolo...",
        unexpectedError: "Qualcosa è andato Storto",
        setUpYourPassword: "Configura la tua password",
    },
    "ResetPasswordWithTokenView.ResetPasswordWithTokenForm": {
        passwordLabel: "Password",
        passwordPlaceholder: "Inserire la password",
        confirmPasswordLabel: "Confermare la Password",
        confirmPasswordPlaceholder: "Inserire la password",
        save: "Salva",
    },
    SetPasswordView: {
        viewTitle: "Hi, {{value}}!",
        yourPasswordHasBeenSet: "La tua password è stata impostata",
        pageTitle: "Reimposta la password",
        validatingToken: "Convalida il Simbolo...",
        unexpectedError: "Qualcosa è andato Storto",
        setUpYourPassword: "Configura la tua password",
    },
    "SetPasswordView.SetPasswordForm": {
        passwordLabel: "Password",
        passwordPlaceholder: "Inserire la password",
        confirmPasswordLabel: "Confermare la Password",
        confirmPasswordPlaceholder: "Inserire la password",
        save: "Salva",
    },
    StatementsDetailedView: {
        notifyDownloadReportError: "Impossibile scaricare il report",
        pageTitle: "Istruzioni",
        statementDetails: "Dettagli della dichiarazione",
        loading: "Caricamento in corso...",
        errorFetchCompany: "Impossibile recuperare l'azienda",
        goBack: "Tornare indietro",
        downloadReport: "Scarica report",
    },
    "StatementsDetailedView.StatementsTable": {
        totalPayAdvances: "Totale Anticipato",
        totalTransactionFees: "Totale commissioni sulle Transazioni",
        balancePayments: "Pagamenti a saldo",
        employerPayment: "Pagamento del datore di lavoro",
        payAdvances: "Anticipi sullo stipendio",
        date: "Data",
        amount: "Importo",
        grandTotalPayAdvances: "Totale degli Anticipi sulla retribuzione",
        transactionFees: "Commissioni di transazione",
        balancePayment: "Pagamento del saldo",
        type: "Genere",
    },
    StatementView: {
        pageTitle: "Estratto Conto",
        pageHeader: "Estratto Conto",
        loading: "Caricamento in corso...",
        errorFetchEmployee: "Impossibile recuperare i dipendenti",
    },
    "StatementView.StatementTimePeriodForm": {
        statementMonthLabel: "Estratto Conto Mensile",
        statementMonthPlaceholder: "Estratto Conto Mensile",
        cannotSelectAnyMonth: "Non è possibile selezionare alcun mese",
        statementYearLabel: "Anno della dichiarazione",
        statementYearPlaceholder: "Anno della dichiarazione",
        submit: "Inviare",
        monthlyStatementNotGenerated: "Estratto conto mensile non generato",
        goBack: "Tornare indietro",
    },
    TermsAndConditionsView: {
        pageTitle: "Terms and Conditions",
        viewTitle: "Terms and Conditions",
        goBack: "Tornare indietro",
    },
    UpdateCompanyView: {
        pleaseConfirmCompany: "Conferma di voler {{statusActionName}} questa transazione",
        notifyCompanyUpdated: "Azienda aggiornata con successo",
        notifyCompanyStatusUpdated: "Stato dell'azienda aggiornato con successo",
        errorGetCompanyId: "Impossibile ottenere l'ID aziendale",
        pageTitle: "Aggiornare l'Azienda",
        loading: "Caricamento in corso...",
        errorFetchCountries: "Impossibile recuperare le nazioni",
        errorFetchCompany: "Impossibile recuperare le aziende",
        errorCompanyNotFound: "Azienda non trovata",
        errorFetchSectors: "Impossibile recuperare i selettori",
        toggleCompany: "Azionare Azienda",
        kybStatus: "Stato KYB",
        enabled: "Enabled",
        disabled: "Disabled",
        active: "Attivo",
        "not active": "Inattivo",
    },
    UpdateEmployeeView: {
        disable: "disattivare",
        enable: "attivare",
        pleaseConfirmEmployee: "Conferma di voler {{value}} questo dipendente",
        id: "ID: {{value}}",
        notifyEmployeeUpdated: "Dipendente aggiornato con successo",
        notifyEmployeeStatusUpdated: "ID dipendente aggiornato con successo",
        pageTitle: "Dipendente",
        loading: "Caricamento in corso...",
        errorFetchEmployee: "Impossibile recuperare il dipendente",
        errorEmployeeNotFound: "Dipendente non trovato",
        toggleEmployee: "Attivare/Disattivare Dipendente",
    },
    UserSettingsView: {
        pageTitle: "Gestire Profilo",
        headerTitle: "Gestire Profilo",
        changePassword: "Cambiare Password",
    },
    "UserSettingsView.ChangePassword": {
        successMessage: "La Password è stata cambiata",
    },
    "UserSettingsView.ChangePasswordForm": {
        oldPasswordLabel: "Vecchia Password",
        oldPasswordPlaceholder: "Inserire la vecchia password",
        newPasswordLabel: "Nuova Password",
        newPasswordPlaceholder: "Inserire la nuova password",
        confirmNewPasswordLabel: "Confermare la nuova password",
        confirmNewPasswordPlaceholder: "Inserire la nuova password",
        save: "Salvare",
    },
    "UserSettingsView.CompanyDetails": {
        header: "Dettagli Azienda",
    },
    "UserSettingsView.PersonalDetails": {
        header: "Dettagli Personali",
    },
    WithdrawalSettingsView: {
        cannotRetriveCompanyId: "Impossibile recuperare l'ID aziendale",
        configurationsSavedSuccessfully: "Configurazione salvata con successo",
        pageTitle: "Titolo Pagina",
        loading: "Caricamento",
    },
    "WithdrawalSettingsView.WithdrawalSettingsForm": {
        withdrawalInformation: "Esempio: se imposti il 40% come limite per l’anticipo dello stipendio, significa che l'importo disponibile per un dipendente viene calcolato sul 40% dell’importo totale maturato.",
        withdrawalSettings: "Impostazioni sull'Anticipo",
        withdrawalLimit: "Limite sull'Anticipo",
        transactionMonthlyLimit: "Limite al numero di richieste mensili",
        transactionFeeEur: "Commissione sulle transazioni (EUR)",
        setPercentage: "Imposta una percentuale (massimo 50%)",
    },
    ValidationErrors: {
        "Incorrect Id Payroll Item": "Codice Voce errato",
        "Incorrect company code": "Codice azienda errato",
        "This field is required": "Questo campo è obbligatorio",
        "Account name is too long (max 70 characters)": "Il nome dell'account è troppo lungo (massimo 70 caratteri)",
        "Incorrect address line": "Riga indirizzo errata",
        "Incorrect date (DD-MM-YYYY)": "Data errata (GG-MM-ANNO)",
        "Person has to be at least 18 years old": "L'utente deve avere almeno 18 anni",
        "Person cannot be older than 100 years": "L'utente non può avere più di 100 anni",
        "Incorrect city name": "Nome città errato",
        "Incorrect company name": "Nome azienda errato",
        "Company name can be max 70 characters long": "Il nome dell'azienda può contenere al massimo 70 caratteri",
        "Incorrect company registration ID (max 11 characters)": "ID registrazione azienda non corretto (max 11 caratteri)",
        "Invalid CUC number (8 characters)": "Numero CUC non valido (8 caratteri)",
        "Incorrect e-mail address": "Indirizzo email errato",
        "Incorrect employee ID": "ID dipendente errato",
        "Invalid IBAN": "IBAN non valido",
        "Incorrect job title": "Titolo di lavoro errato",
        "Must include only A-Z, 0-9, spaces and -": "Deve includere solo A-Z, 0-9, spazi e -",
        "Part-time must be in range 1% - 100%": "Il part-time deve essere compreso tra 1% e 100%",
        "At least 8 characters required": "Sono richiesti almeno 8 caratteri",
        "One digit required": "È richiesta una cifra",
        "One upper case letter required": "È richiesta una lettera maiuscola",
        "One special character required": "È richiesto un carattere speciale",
        "Incorrect first name": "Nome errato",
        "Incorrect last name": "Cognome errato",
        "Has to start with country code (+XX)": "Deve iniziare con il codice del paese (+XX)",
        "Incorrect phone number": "Numero di telefono errato",
        "Incorrect postal code": "Codice postale errato",
        "Incorrect tax ID": "ID fiscale errato (11 cifre o 16 caratteri: 0-9 A-Z)",
        "Authentication failed": "Autenticazione fallita",
    },
    Chart: {
        Jan: "Gen",
        Feb: "Feb",
        Mar: "Mar",
        Apr: "Apr",
        May: "Mag",
        Jun: "Giu",
        Jul: "Lug",
        Aug: "Ago",
        Sep: "Set",
        Oct: "Ott",
        Nov: "Nov",
        Dec: "Dic",
    },
    XRHResponse: {
        "Only numeric characters are allowed": "Sono consentiti solo caratteri numerici",
        "Bank account number is too short": "Il numero del conto corrente è troppo corto",
        "Too many digits": "Troppi caratteri",
        "Incorrect company name": "Nome azienda sbagliato",
        "Company name can be max 35 characters long": "Il nome dell'azienda può contenere al massimo 35 caratteri",
        "Incorrect e-mail address": "Indirizzo email errato",
        "Incorrect email address": "Indirizzo email errato",
        "Incorrect employee ID": "ID dipendente errato",
        "Must include only A-Z and 0-9": "Deve includere solo caratteri da A-Z e numeri da 0-9",
        "Incorrect job title": "Titolo di studio errato",
        "Incorrect location": "Posizione sbagliata",
        "Message cannot be empty": "Lo spazio non può rimanere vuoto",
        "At least 8 characters required": "Sono richiesti almeno 8 caratteri",
        "One digit required": "È richiesta una cifra",
        "One upper case letter required": "È richiesta una lettera maiuscola",
        "One special character required": "È richiesto un carattere speciale",
        "Country code is mandatory (only UK for now +44)": "È obbligatorio il prefisso del paese (es. +39)",
        "Not a valid mobile number": "Numero di cellulare non valido",
        "Salary must be in range 1 - 50000000": "Lo stipendio deve essere compreso tra 1 e 50000000",
        "Subject cannot be empty": "Il campo non può rimanere vuoto",
        "You have to accept Terms and Conditions": "Devi accettare i termini e le condizioni",
        "Invalid verification code": "Codice di verifica non valido",
        "Unique code name can be max 7 characters long": "Il codice univoco può contenere al massimo 7 caratteri",
        success: "Inviata con successo",
        "Wrong email or password Try again or click Forgot password to reset it": "Email o password errata, riprova o fai clic su password dimenticata per reimpostarla.",
        "Couldnt find your Consono account": "Impossibile trovare il tuo account Consono",
        "Token expired or is invalid": "Sessione scaduta o non valida",
        "User doesnt exists": "L'utente non esiste",
        "Your password has been changed": "La tua password è stata modificata",
        "Authentication failed": "Autenticazione fallita",
    },
    Sectors: {
        "Wholesale and retail trade; vehicle repair": "Commercio all'ingrosso e al dettaglio",
        "Wholesale and retail trade": "Commercio all'ingrosso e al dettaglio",
        "Water supply, sewerage and waste management": "Approvvigionamento idrico, fognatura e gestione dei rifiuti",
        "Transport and logistics": "Trasporti e logistica",
        "Real estate": "Real estate",
        "Public administration and defence Professional": "Pubblica amministrazione e difesa",
        "Scientific and technical services": "Servizi professionali, scientifici e tecnologici",
        "Retail and other services": "Vendita e altri servizi",
        "Online gambling": "Giochi online o intrattenimento",
        "Mines, quarries and raw materials": "Estrazione di minerali, cave e materie prime",
        Manufacturing: "Manufacturing",
        "Information and communication": "Informazione, editoria e comunicazione",
        "Human health, pharma and social work": "Salute, pharma, cosmetica e lavoro sociale",
        "Financial and insurance services": "Servizi finanziari e assicurativi",
        "Electricity, gas, steam and air conditioning": "Elettricità, gas, energie rinnovabili, vapore e aria condizionata",
        Education: "Istruzione",
        Automotive: "Automotive",
        "Construction Arts, entertainment and recreation": "Arte e divertimento",
        "Agriculture, forestry and fishing": "Agricoltura, allevamento, selvicoltura e pesca",
        Consulting: "Consulenza",
        "Accommodation and food services": "Servizi alberghieri e di ristorazione",
        "Chemical and plastic": "Chimico e plastico",
        Construction: "Edilizia",
        "Luxury and fashion": "Luxury and fashion",
        Forniture: "Arredamento",
    },
    EmployeesUploadModal: {
        uploadFiles: "Carica file",
        reviewEmployeeData: "Verifica dati"
    },
    EmployeeUploadReviewDetail: {
        "already exist": "Già presente",
        "must be filled": "Completa",
        "is in invalid format": "Formato non valido",
        "defined multiple times": "Presente più volte"
    }
};
