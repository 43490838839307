import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { boxFrame, colors, fontSize, rem } from "@/styleguide";
export var Heading = styled.h1({
    alignItems: "center",
    color: colors.shipGrey,
    display: "flex",
    fontSize: rem(fontSize.big),
    justifyContent: "space-between",
    margin: boxFrame([45, 0]),
    padding: 0,
});
export var AlignToCenter = styled.div({
    alignItems: "center",
    display: "flex",
});
export var HeaderLink = styled(Link)({
    lineHeight: 0,
    marginRight: 20,
});
