import styled from "@emotion/styled";
export var Row = styled.div({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent: "space-between",
}, function (props) {
    var _a;
    var pxForGaps = (props.cardsCount - 1) * 15;
    return {
        minHeight: (_a = props.minHeight) !== null && _a !== void 0 ? _a : "unset",
        "> *": {
            width: "calc((100% - " + pxForGaps + "px) / " + props.cardsCount + ")",
        },
    };
});
