import { differenceInYears, parse } from "date-fns";
import emailValidator from "email-validator";
import deburr from "lodash/deburr";
var HAS_ONE_DIGIT = /\d/;
var XLS_MIME_REGEX = /^application\/vnd\.ms-excel$/;
var XLSX_MIME_REGEX = /^application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet$/;
export function isNullOrUndefined(value) {
    return value === null || value === undefined;
}
export function isEmptyString(value) {
    return typeof value === "string" && value.trim() === "";
}
export function isNonBlankString(value) {
    return typeof value === "string" && value.trim().length > 0;
}
export function isTrue(v) {
    return v === true || v === "true";
}
export function matchesRegex(regex, deburrValue) {
    if (deburrValue === void 0) { deburrValue = true; }
    return function (value) {
        if (typeof value !== "string") {
            return false;
        }
        if (deburrValue) {
            return regex.test(deburr(value));
        }
        return regex.test(value);
    };
}
export function isNotEmpty(value) {
    if (isNullOrUndefined(value)) {
        return false;
    }
    if (isEmptyString(value)) {
        return false;
    }
    if (value === false) {
        return false;
    }
    return true;
}
export function isInRange(minVal, maxVal) {
    if (maxVal === void 0) { maxVal = Infinity; }
    return function (value) {
        if (typeof value !== "number") {
            return false;
        }
        return value >= minVal && value <= maxVal;
    };
}
export function isEmail(value) {
    if (typeof value !== "string") {
        return false;
    }
    return emailValidator.validate(value);
}
export function isSalary(value) {
    var valueAsNumber = 0;
    if (typeof value === "number") {
        valueAsNumber = value;
    }
    else if (typeof value === "string") {
        valueAsNumber = Number(value);
    }
    else {
        return false;
    }
    return isInRange(1, 5e7)(valueAsNumber);
}
export function isFTE(value) {
    var valueAsNumber = 0;
    if (typeof value === "number") {
        valueAsNumber = value;
    }
    else if (typeof value === "string") {
        valueAsNumber = Number(value);
    }
    else {
        return false;
    }
    return isInRange(1, 100)(valueAsNumber);
}
export function hasOneDigit(value) {
    if (typeof value === "string" || typeof value === "number") {
        return HAS_ONE_DIGIT.test(value.toString());
    }
    return false;
}
export function hasLength(minLen, maxLen) {
    if (maxLen === void 0) { maxLen = Infinity; }
    return function (value) {
        if (typeof value === "string" || typeof value === "number") {
            var length = value.toString().length;
            return length >= minLen && length <= maxLen;
        }
        return false;
    };
}
export function startsWith(prefix) {
    return function (value) {
        if (typeof value !== "string") {
            return false;
        }
        return value.startsWith(prefix);
    };
}
export function startsWithCountryCode(value) {
    if (typeof value !== "string") {
        return false;
    }
    return /^\+\d/.test(value);
}
export function isExcelMime(value) {
    if (typeof value !== "string") {
        return false;
    }
    return XLS_MIME_REGEX.test(value) || XLSX_MIME_REGEX.test(value);
}
export function isCSVMime(value) {
    return value === "text/csv";
}
export function rangeInYears(minYears, maxYears) {
    if (maxYears === void 0) { maxYears = Infinity; }
    return function (value) {
        if (typeof value === "string") {
            var fomattedDate = value.split("-").reverse().join("-");
            var difference = differenceInYears(new Date(), parse(fomattedDate));
            return difference >= minYears && difference <= maxYears;
        }
        return false;
    };
}
