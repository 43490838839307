import styled from "@emotion/styled";
import { colors, fontSize, rem, fontWeight } from "@/styleguide";
export var StyledRoot = styled.div({
    alignItems: "center",
    display: "flex",
});
var PaginationItem = styled.div({
    alignItems: "center",
    display: "inline-flex",
    fontSize: rem(fontSize.small),
    height: 36,
    justifyContent: "center",
    width: 36,
    ":not(:first-of-type)": {
        marginLeft: 5,
    },
});
export var Page = styled(PaginationItem)({
    backgroundColor: colors.white,
    border: "1px solid transparent",
    borderRadius: 6,
    cursor: "pointer",
});
export var Separator = styled(PaginationItem)({
    color: colors.grey,
});
export var CurrentPage = styled(Page)({
    border: "1px solid " + colors.java,
    color: colors.java,
    fontWeight: fontWeight.semiBold,
});
