export var ViewPath;
(function (ViewPath) {
    ViewPath["HOME"] = "/";
    ViewPath["LOGIN"] = "/login";
    ViewPath["TERMS_AND_CONDITIONS"] = "/terms-and-conditions";
    ViewPath["SET_PASSWORD"] = "/set-password";
    ViewPath["RESET_PASSWORD"] = "/reset-password";
    ViewPath["RESET_PASSWORD_WITH_TOKEN"] = "/change-password";
    ViewPath["APP_ROOT"] = "/app";
    ViewPath["DASHBOARD"] = "/app/dashboard";
    ViewPath["REPORTS"] = "/app/reports";
    ViewPath["USER_SETTINGS"] = "/app/settings";
    ViewPath["WITHDRAWAL_SETTINGS"] = "/app/withdrawal-settings";
    ViewPath["EMPLOYEES"] = "/app/employees";
    ViewPath["NEW_EMPLOYEE"] = "/app/employees/create";
    ViewPath["EMPLOPYEES_REVIEW_UPLOAD"] = "/app/employees/review-upload";
    ViewPath["UPDATE_EMPLOYEE"] = "/app/employees/{employeeId:uuid}/update";
    ViewPath["COMPANIES"] = "/app/companies";
    ViewPath["COMPANY_EMPLOYEES"] = "/app/companies/{companyId:uuid}/employees";
    ViewPath["NEW_COMPANY"] = "/app/companies/create";
    ViewPath["COMPANY_TIME_PERIOD"] = "/app/companies/{companyId:uuid}/employees/{employeeId:uuid}/{encodedEmployeeEmail:string}/time-period-form";
    ViewPath["REPORTS_DETAIL"] = "/app/companies/employees/{employeeId:uuid}/{encodedEmployeeEmail:string}/statement/month/{statementMonth:string}/year/{statementYear:string}";
    ViewPath["UPDATE_COMPANY"] = "/app/companies/{companyId:uuid}/update";
    ViewPath["FUNDS"] = "/app/funds";
})(ViewPath || (ViewPath = {}));
