export var themes = {
    login: {
        height: 62,
        heightMobile: 43,
    },
    topNav: {
        height: 28,
        heightMobile: 18,
    },
};
