import styled from "@emotion/styled";
export var StyledRoot = styled.div({
    display: "flex",
}, function (props) {
    var _a;
    var isVertical = props.direction === "y";
    var marginProp = isVertical ? "marginTop" : "marginLeft";
    return {
        flexDirection: isVertical ? "column" : "row",
        "& > * + *": (_a = {},
            _a[marginProp] = props.size,
            _a),
    };
});
