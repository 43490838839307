import styled from "@emotion/styled";
import { colors } from "@/styleguide";
export var StyledRoot = styled.div({
    alignItems: "center",
    display: "flex",
    "&:not(:last-child)": {
        marginBottom: 20,
    },
});
export var Wrapper = styled.div({
    alignItems: "stretch",
    backgroundColor: colors.white,
    borderRadius: 12,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
});
export var CheckboxCell = styled.div({
    marginRight: 30,
    width: 30,
});
