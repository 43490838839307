import styled from "@emotion/styled";
import { colors, percent } from "@/styleguide";
var BAR_HEIGHT = 12;
export var Bar = styled.div({
    backgroundColor: colors.alabaster,
    borderRadius: BAR_HEIGHT,
    height: BAR_HEIGHT,
    overflow: "hidden",
    position: "relative",
    width: "100%",
}, function (props) { return ({
    "&:before": {
        backgroundColor: colors.java,
        bottom: 0,
        content: "''",
        left: 0,
        position: "absolute",
        top: 0,
        width: percent(props.progress * 100),
    },
}); });
