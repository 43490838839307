import React from "react";
import { StyledBody } from "./styles";
export var Tbody = function (props) {
    var cols = props.cols || [];
    return (React.createElement(StyledBody, null, React.Children.toArray(props.children).flatMap(function (child) {
        if (!React.isValidElement(child)) {
            return null;
        }
        return React.cloneElement(child, { cols: cols });
    })));
};
