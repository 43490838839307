import styled from "@emotion/styled";
import { colors, boxFrame, fontWeight, fontSize, rem } from "@/styleguide";
export var StyledRoot = styled.div({
    alignItems: "center",
    display: "flex",
});
export var StyledLabel = styled.label({
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    display: "inline-flex",
    justifyContent: "center",
}, function (props) { return ({
    backgroundColor: props.isChecked ? colors.turquoise : "transparent",
    borderColor: props.isChecked ? colors.turquoise : colors.alto,
    borderRadius: props.theme.borderRadius,
    cursor: props.isDisabled ? "not-allowed" : "pointer",
    height: props.theme.boxSize,
    opacity: props.isDisabled ? 0.4 : 1,
    width: props.theme.boxSize,
}); });
export var Input = styled.input({
    display: "none",
});
export var Text = styled.p({
    fontSize: rem(fontSize.small),
    fontWeight: fontWeight.semiBold,
    margin: boxFrame([0, 0, 0, 15]),
});
