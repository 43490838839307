var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { format, getYear, getMonth } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonSwitch } from "@/components/atoms/ButtonSwitch";
import { Hint } from "@/components/atoms/Hint";
import { Icon } from "@/components/atoms/Icon";
import { InnerContainer } from "@/components/atoms/InnerContainer";
import { Select } from "@/components/molecules/Select";
import { StatCard } from "@/components/molecules/StatCard";
import { ProtectedView } from "@/components/templates/ProtectedView";
import { ViewPath } from "@/constants/routing";
import { useCompaniesReportQuery } from "@/containers/queries/CompaniesReportQuery";
import { useToggle } from "@/hooks/useToggle";
import { useViewer } from "@/hooks/useViewer";
import { colors } from "@/styleguide";
import { formatMoney, formatNumber, getDateLocale } from "@/utils/format";
import { getMonthDatesInRange } from "@/utils/misc";
import { extractQueryParams } from "@/utils/routing";
import { ChartPanel } from "./ChartPanel";
import { CompanyName } from "./CompanyName";
import { reportOptions } from "./constants";
import { ReportsTable } from "./ReportsTable";
import { BackgroundProvider, BackLinkWrapper, CompanyNameWrapper, ContentHeader, ContentHeaderWrapper, Header, HeaderLink, Plug, SelectWrapper, StatsCards, StatsPanel, SwitchWrapper, } from "./styles";
import { createDailySeries, createMonthlySeries } from "./utils";
var now = new Date();
export var CompaniesReportsView = function (props) {
    var t = useTranslation("CompaniesReportsView").t;
    var viewer = useViewer().viewer;
    var confirmedAt = viewer ? viewer.confirmedAt : now;
    var _a = useToggle(), isHintOpen = _a[0], closeHint = _a[2], toggleHint = _a[3];
    var companyId = extractQueryParams(props.location.search).companyId;
    var monthDates = getMonthDatesInRange(confirmedAt, now);
    var lastMonthDate = monthDates[monthDates.length - 1] || null;
    var _b = React.useState("daily"), reportType = _b[0], setReportType = _b[1];
    var _c = React.useState(lastMonthDate), reportPeriod = _c[0], setReportPeriod = _c[1];
    var onChangeReportPeriod = React.useCallback(function (_, value) {
        setReportPeriod(value);
    }, []);
    var selectOptions = React.useMemo(function () {
        return monthDates.map(function (date) { return ({
            label: format(date, "MMMM YYYY", {
                locale: getDateLocale()
            }),
            value: date,
        }); });
    }, [monthDates]);
    var select = React.useMemo(function () {
        return reportType === "monthly" ? null : (React.createElement(SelectWrapper, null,
            React.createElement(Select, { name: "reportPeriod", onChange: onChangeReportPeriod, options: selectOptions, value: reportPeriod })));
    }, [onChangeReportPeriod, reportType, selectOptions, reportPeriod]);
    var statsLabel = React.useMemo(function () {
        if (reportType === "monthly") {
            return t("currentYear");
        }
        if (reportPeriod) {
            return format(reportPeriod, "(MMMM YYYY)", {
                locale: getDateLocale()
            });
        }
        return t("currentMonth");
    }, [reportType, reportPeriod, t]);
    var _d = React.useMemo(function () {
        var date = reportPeriod || now;
        return [getMonth(date) + 1, getYear(date)];
    }, [reportPeriod]), month = _d[0], year = _d[1];
    var reportQuery = useCompaniesReportQuery({
        companyId: companyId,
        type: reportType,
        period: reportType === "daily" ? { month: month, year: year } : undefined,
    });
    var report = reportQuery.data && reportQuery.data.report;
    var totalPayAdvances = report ? report.totalPayAdvances : 0;
    var totalTransactions = report ? report.totalTransactions : 0;
    var payAdvances = report ? report.payAdvances : [];
    var transactions = report ? report.transactions : [];
    var chartSeries = reportType === "daily" ? createDailySeries(payAdvances) : createMonthlySeries(payAdvances);
    return (React.createElement(ProtectedView, { pageTitle: t("pageTitle"), theme: t("theme") },
        React.createElement(BackgroundProvider, null,
            React.createElement(InnerContainer, null,
                React.createElement(Header, null,
                    companyId ? (React.createElement(BackLinkWrapper, null,
                        React.createElement(HeaderLink, { to: ViewPath.COMPANIES },
                            React.createElement(Icon, { color: colors.java, name: "arrowLeftCircle", size: 40 })),
                        React.createElement(CompanyNameWrapper, null,
                            React.createElement(CompanyName, { companyId: companyId })))) : (React.createElement("span", null)),
                    React.createElement(SwitchWrapper, null,
                        React.createElement(ButtonSwitch, { onChange: setReportType, options: reportOptions.map(function (item) { return (__assign(__assign({}, item), { label: t(item.label) })); }), value: reportType }))),
                React.createElement(StatsPanel, null,
                    React.createElement(ChartPanel, { headerSlot: select, series: chartSeries }),
                    React.createElement(StatsCards, null,
                        React.createElement(StatCard, { icon: t("statCards.totalPayAdvances.icon"), title: [
                                t("statCards.totalPayAdvances.title1"),
                                t("statCards.totalPayAdvances.title2", { value: statsLabel }),
                            ], value: formatMoney(totalPayAdvances) }),
                        React.createElement(StatCard, { icon: t("statCards.totalTransactions.icon"), title: [
                                t("statCards.totalTransactions.title1"),
                                t("statCards.totalTransactions.title2", { value: statsLabel }),
                            ], value: formatNumber(totalTransactions) }))))),
        React.createElement(InnerContainer, null,
            React.createElement(ContentHeaderWrapper, null,
                React.createElement(ContentHeader, null, t("transactionHistory")),
                React.createElement(Hint, { isOpen: isHintOpen, onClickOutside: closeHint, onToggle: toggleHint, width: 573, x: 22, y: -39 }, t("transactionFeeNotice"))),
            reportQuery.loading ? (React.createElement(Plug, null, t("fetchingTransactionList"))) : transactions.length === 0 ? (React.createElement(Plug, null, t("transactionListEmpty"))) : (React.createElement(ReportsTable, { transactions: transactions })))));
};
