var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import { useTranslation } from "react-i18next";
import { Chart } from "@/components/molecules/Chart";
import { ChartWrapper, Wrapper, Header, ChartTitle } from "./styles";
export var ChartPanel = function (props) {
    var t = useTranslation("EmployeesReportsView.ChartPanel").t;
    var maxValue = React.useMemo(function () {
        var values = props.series.map(function (serie) { return serie.value; });
        return Math.max.apply(Math, __spreadArrays([10], values));
    }, [props.series]);
    return (React.createElement(Wrapper, null,
        React.createElement(Header, null,
            React.createElement(ChartTitle, null, t("trendOfPayAdvances")),
            props.headerSlot),
        React.createElement(ChartWrapper, null,
            React.createElement(Chart, { max: maxValue, height: 180, width: 960, series: props.series }))));
};
