var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { DragAndDropFiles } from "@/components/atoms/DragAndDropFiles";
import { useFileDownload } from "@/hooks/useFileDownload";
import { isCSVMime, isExcelMime } from "@/utils/core";
import { ButtonWrapper, DropZoneHeader, FileInput, LinkWrapper, Link } from "./styles";
export var EmployeesUpload = function (_a) {
    var onFiles = _a.onFiles;
    var t = useTranslation("Molecules.EmployeesUpload").t;
    var download = useFileDownload("/users/files/sample", {
        fallbackFileName: "example-employees.xlsx",
    });
    var onFilesSelect = React.useCallback(function (evt) {
        var files = evt.target.files;
        if (!files) {
            return;
        }
        var filteredFiles = Object.entries(files).reduce(function (acc, _a) {
            var file = _a[1];
            if (file === null) {
                return acc;
            }
            return __spreadArrays(acc, [file]);
        }, []);
        onFiles(filteredFiles);
    }, [onFiles]);
    var onFilesDrop = React.useCallback(function (files) {
        var acceptedFiles = files.filter(function (file) {
            return isExcelMime(file.type) || isCSVMime(file.type);
        });
        onFiles(acceptedFiles);
    }, [onFiles]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FileInput, { accept: ".xls,.xlsx,.csv", id: "filesSelect", multiple: true, onChange: onFilesSelect, type: "file" }),
        React.createElement(DragAndDropFiles, { onFilesDrop: onFilesDrop },
            React.createElement(DropZoneHeader, null, t("dragAndDropFilesHere"))),
        React.createElement(ButtonWrapper, null,
            React.createElement(Button, { as: "label", htmlFor: "filesSelect", type: "button", width: 350 }, t("chooseFiles"))),
        React.createElement(LinkWrapper, null,
            React.createElement(Link, { onClick: download }, t("downloadSample")))));
};
