import styled from "@emotion/styled";
import { colors } from "@/styleguide";
export var StatusDot = styled.span({
    borderRadius: "50%",
    display: "inline-block",
    height: 10,
    width: 10,
}, function (props) { return ({
    backgroundColor: props.isActive ? colors.java : colors.alto,
}); });
export var Label = styled.label({
    color: colors.armadillo,
    marginLeft: 15,
});
