import styled from "@emotion/styled";
import { colors, fontSize, fontWeight } from "@/styleguide";
export var FieldsRow = styled.div({
    display: "flex",
});
export var FieldCell = styled.div({
    width: "calc((100% - 15px) / 2)",
});
export var InputWrapper = styled.div({
    minHeight: 68,
});
export var InputContent = styled.div({
    alignItems: "center",
    backgroundColor: colors.alabaster,
    borderRadius: 6,
    cursor: "not-allowed",
    display: "flex",
    height: 40,
    overflow: "hidden",
    padding: "0 18px",
    wordBreak: "break-word",
});
export var HeaderWrapper = styled.div({
    alignItems: "flex-start",
    display: "flex",
});
export var HeaderCell = styled.div({
    display: "flex",
    flexDirection: "column",
});
export var HeaderInfoCell = styled(HeaderCell)({
    alignItems: "flex-end",
    flexGrow: 1,
});
export var Information = styled.div({
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
});
export var InformationLabel = styled.span({
    fontSize: fontSize.small,
    fontWeight: fontWeight.regular,
    marginRight: 5,
});
export var AccruedAmount = styled.span({
    color: colors.java,
    fontSize: fontSize.medium,
    fontWeight: fontWeight.bold,
    marginTop: 5,
});
export var SectionHeader = styled.div({
    color: colors.shipGrey,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize.regular,
    marginTop: 25,
});
export var LabelWithHint = styled.div({
    alignItems: "end",
    display: "flex",
});
export var HintWrapper = styled.div({
    marginLeft: 10,
});
