import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { FormField } from "@/components/atoms/FormField";
import { Gap } from "@/components/atoms/Gap";
import { Link } from "@/components/atoms/Link";
import { TextInput } from "@/components/atoms/TextInput";
import { FormMessage } from "@/components/molecules/FormMessage";
import { ViewPath } from "@/constants/routing";
import { createValidation, emailRules, requiredValidationRules } from "@/utils/validate";
import { ButtonWrapper, LinkWrapper, FormMessageWrapper } from "./styles";
var validate = createValidation({
    email: emailRules,
    password: requiredValidationRules,
});
export function LoginForm(props) {
    var _a, _b;
    var t = useTranslation("LoginView.LoginForm").t;
    var xhrResponseTrans = useTranslation("XRHResponse");
    var _c = useFormik({
        validate: validate,
        initialValues: {
            email: (_a = process.env.DEFAULT_USER_EMAIL) !== null && _a !== void 0 ? _a : "",
            password: (_b = process.env.DEFAULT_USER_PASSWORD) !== null && _b !== void 0 ? _b : "",
        },
        onSubmit: props.onSubmit,
    }), errors = _c.errors, touched = _c.touched, handleBlur = _c.handleBlur, handleChange = _c.handleChange, handleSubmit = _c.handleSubmit, values = _c.values;
    var errorMessage = (props.errorMessage || "").replaceAll(/[^\w\s]/gi, "");
    return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(Gap, { direction: "y", size: 25 },
            React.createElement(FormField, { error: touched.email ? errors.email : undefined, label: t("form.email.label") },
                React.createElement(TextInput, { name: "email", onBlur: handleBlur, onChange: handleChange, placeholder: t("form.email.placeholder"), value: values.email })),
            React.createElement(FormField, { error: touched.password ? errors.password : undefined, label: t("form.password.label") },
                React.createElement(TextInput, { name: "password", onBlur: handleBlur, onChange: handleChange, placeholder: t("form.password.placeholder"), type: "password", value: values.password }))),
        React.createElement(LinkWrapper, null,
            React.createElement(Link, { to: ViewPath.RESET_PASSWORD, title: t("resetPasswordActionCallTitle") }, t("resetPasswordActionCallContent"))),
        React.createElement(ButtonWrapper, null,
            React.createElement(Button, { disabled: props.loading, type: "submit" }, t("submit"))),
        props.errorMessage && (React.createElement(FormMessageWrapper, null,
            React.createElement(FormMessage, { type: "error" }, xhrResponseTrans.t(errorMessage))))));
}
