import styled from "@emotion/styled";
import { fontSize, fontWeight, colors, rem } from "@/styleguide";
export var StyledRoot = styled.div({
    display: "flex",
    flexDirection: "column",
});
export var Label = styled.label({
    color: colors.grey,
    fontSize: rem(fontSize.small),
    fontWeight: fontWeight.medium,
    marginBottom: 10,
    whiteSpace: "nowrap",
});
export var Error = styled.p({
    color: colors.red,
    fontSize: rem(fontSize.tiny),
    margin: 0,
    marginTop: 5,
});
