import styled from "@emotion/styled";
export var ContentWrapper = styled.div({
    display: "flex",
    flexDirection: "column",
    marginTop: 60,
    minHeight: 375,
});
export var FilesList = styled.ul({
    flexGrow: 1,
    listStyle: "none",
    margin: 0,
    padding: 0,
});
export var ReviewButtonWrapper = styled.div({
    margin: "40px auto 0",
    width: 300,
});
