import styled from "@emotion/styled";
import { colors, rem, fontSize, fontWeight } from "@/styleguide";
export var YLabelText = styled.text({
    fill: colors.grey,
    fontSize: rem(fontSize.tiny),
    fontWeight: fontWeight.bold,
});
export var XLabelText = styled.text({
    fill: colors.grey,
    fontSize: rem(fontSize.tiny),
    fontWeight: fontWeight.bold,
    textAlign: "center",
    textAnchor: "middle",
});
export var YLines = styled.path({
    stroke: "#f0f0f0",
    strokeWidth: 1,
});
export var Bars = styled.path({
    stroke: colors.turquoise,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 12,
});
