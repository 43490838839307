var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Icon } from "@/components/atoms/Icon";
import { ProgressBar } from "@/components/atoms/ProgressBar";
import { Close, ErrorMessage, FileName, Root, Header } from "./styles";
export var FileUpload = function (_a) {
    var onAbort = _a.onAbort, uploadId = _a.uploadId, props = __rest(_a, ["onAbort", "uploadId"]);
    var handleAbort = React.useCallback(function () {
        onAbort(uploadId);
    }, [onAbort, uploadId]);
    return (React.createElement(Root, null,
        React.createElement(Header, null,
            React.createElement(FileName, null, props.fileName),
            props.progress < 1 && (React.createElement(Close, { onClick: handleAbort },
                React.createElement(Icon, { name: "cross", size: 12 })))),
        props.error ? (React.createElement(ErrorMessage, null, props.error)) : (React.createElement(ProgressBar, { progress: props.progress }))));
};
