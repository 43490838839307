import React from "react";
import { Icon } from "@/components/atoms/Icon";
import { colors } from "@/styleguide";
import { BottomRow, StyledRoot, Title, Value } from "./styles";
export var StatCard = function (props) {
    var title = React.useMemo(function () {
        if (Array.isArray(props.title)) {
            var linesCount_1 = props.title.length;
            return props.title.map(function (line, index) {
                return index + 1 === linesCount_1 ? (line) : (React.createElement(React.Fragment, { key: index },
                    line,
                    React.createElement("br", null)));
            });
        }
        return props.title;
    }, [props.title]);
    return (React.createElement(StyledRoot, { className: props.className },
        React.createElement(Title, null, title),
        React.createElement(BottomRow, null,
            React.createElement(Icon, { color: colors.alto, name: props.icon, size: 40 }),
            React.createElement(Value, null, props.value))));
};
