import styled from "@emotion/styled";
import { transparentize } from "polished";
import { Link } from "react-router-dom";
import { AppNav } from "@/components/atoms/AppNav";
import { ViewerArea } from "@/components/molecules/ViewerArea";
import { colors, percent, ZIndex, boxFrame, fontSize } from "@/styleguide";
var HEADER_HEIGHT = 70;
export var Header = styled.header({
    backgroundColor: colors.white,
    boxShadow: "0 0 10px " + colors.alto,
    display: "flex",
    height: HEADER_HEIGHT,
    paddingLeft: 20,
    paddingRight: 20,
    position: "relative",
    zIndex: ZIndex.APP_HEADER,
});
export var Wrapper = styled.div({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    height: "100%",
});
export var HeaderSection = styled.div({
    alignItems: "stretch",
    display: "flex",
    height: HEADER_HEIGHT,
});
export var StyledAppNav = styled(AppNav)({
    marginLeft: 80,
});
export var StyledViewerArea = styled(ViewerArea)({
    minWidth: 250,
});
export var LogoLink = styled(Link)({
    alignItems: "center",
    display: "flex",
    lineHeight: 0,
});
export var IconWrapper = styled.div({
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    a: {
        lineHeight: 0,
    },
});
export var LogoutWrapper = styled.span({
    alignItems: "center",
    borderRadius: percent(50),
    cursor: "pointer",
    display: "flex",
    lineHeight: 0,
    marginLeft: 30,
});
export var Settings = styled.div({
    marginRight: 30,
    position: "relative",
});
export var Dropdown = styled.div({
    backgroundColor: colors.white,
    borderRadius: 6,
    boxShadow: "0 0 10px " + transparentize(0.8, colors.shipGrey),
    display: "flex",
    flexDirection: "column",
    minWidth: 180,
    padding: boxFrame([10, 0]),
    position: "absolute",
    right: -10,
    top: "calc(100% - 10px)",
});
export var DropdownLink = styled(Link)({
    color: colors.shipGrey,
    cursor: "pointer",
    fontSize: fontSize.small,
    padding: 10,
    textDecoration: "none",
    whiteSpace: "nowrap",
    "&:hover": {
        backgroundColor: colors.alabaster,
    },
});
