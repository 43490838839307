import styled from "@emotion/styled";
export var Container = styled.div({
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    width: 1350,
}, function (props) {
    if (props.noPadding) {
        return null;
    }
    return {
        paddingLeft: 20,
        paddingRight: 20,
    };
});
