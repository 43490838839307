import styled from "@emotion/styled";
import { fontWeight, fontSize, colors } from "@/styleguide";
export var Root = styled.div({
    alignItems: "flex-start",
    display: "flex",
});
export var IconWrapper = styled.span({
    marginRight: 10,
});
export var Paragraph = styled.p({
    color: colors.shipGrey,
    fontSize: fontSize.small,
    fontWeight: fontWeight.semiBold,
    margin: 0,
});
