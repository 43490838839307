import styled from "@emotion/styled";
import { colors, fontSize, rem } from "@/styleguide";
export var Root = styled.div({
    display: "flex",
    flexDirection: "column",
});
export var Title = styled.span({
    color: colors.grey,
    fontSize: rem(fontSize.small),
});
export var Content = styled.span({
    color: colors.shipGrey,
    fontSize: rem(fontSize.small),
});
