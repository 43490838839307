import React from "react";
import { Icon } from "@/components/atoms/Icon";
import { IconWrapper, Message, Root } from "./styles";
import { themes } from "./themes";
export function PopupMessage(props) {
    var theme = themes[props.severity];
    return (React.createElement(Root, { theme: theme },
        React.createElement(Message, { theme: theme }, props.message),
        React.createElement(IconWrapper, { onClick: props.onClose },
            React.createElement(Icon, { color: theme.color, name: "cross", size: 14 }))));
}
