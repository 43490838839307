import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { fontSize, fontWeight, rem } from "@/styleguide";
export var OuterContainer = styled.div({
    backgroundImage: "url(/assets/images/loginImage.svg)",
    backgroundPositionX: "calc(50vw - 50px)",
    backgroundPositionY: 0,
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 1087px",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    minHeight: "100vh",
    "@media screen and (max-width: 1170px)": {
        backgroundPositionX: 535,
    },
});
export var InnerContainer = styled.div({
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    minHeight: "100vh",
    paddingLeft: 30,
    paddingRight: 30,
    width: 1170,
});
export var LogoLink = styled(Link)({
    lineHeight: 0,
});
export var ContentColumn = styled.div({
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    justifyContent: "center",
    paddingBottom: 30,
    paddingTop: 30,
});
export var ContentWrapper = styled.div({
    display: "flex",
    flexDirection: "column",
    marginTop: 45,
    maxWidth: "100%",
    width: 560,
});
export var Header = styled.header({
    fontSize: rem(fontSize.large),
    fontWeight: fontWeight.bold,
});
