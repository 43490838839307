import styled from "@emotion/styled";
import { percent, boxFrame, colors } from "@/styleguide";
export var Wrapper = styled.div({
    alignItems: "center",
    color: colors.grey,
    display: "inline-flex",
    marginBottom: 10,
    padding: boxFrame([0, 20]),
    whiteSpace: "pre-line",
}, function (props) { return ({
    width: percent(props.columnWidth || 10),
}); });
