import styled from "@emotion/styled";
import { colors, fontSize, fontWeight } from "@/styleguide";
export var Wrapper = styled.div({
    border: "1px solid " + colors.alto,
    borderRadius: 10,
    display: "inline-flex",
    overflow: "hidden",
});
export var Option = styled.div({
    alignItems: "center",
    color: colors.grey,
    cursor: "pointer",
    display: "flex",
    fontSize: fontSize.small,
    fontWeight: fontWeight.semiBold,
    height: 40,
    justifyContent: "center",
    width: 165,
    "&:not(:first-of-type)": {
        borderLeft: "1px solid " + colors.alto,
    },
});
export var ActiveOption = styled(Option)({
    backgroundColor: colors.alabaster,
    color: colors.shipGrey,
    fontWeight: fontWeight.bold,
});
