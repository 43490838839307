import React from "react";
import { Button } from "@/components/atoms/Button";
import { Gap } from "@/components/atoms/Gap";
import { useTranslation } from 'react-i18next';
export var CardsViewButtons = function (props) {
    var _a;
    var t = useTranslation("Molecules.CardViewButtons").t;
    return (React.createElement(Gap, { className: "flex-col-center mt-40", size: 15 },
        props.cancelHref && (React.createElement(Button, { to: props.cancelHref, theme: "secondary", type: "button", width: 225 }, t("cancel"))),
        React.createElement(Button, { disabled: props.isFormSubmitting, type: "submit", width: 225 }, (_a = props.confirmationButtonText) !== null && _a !== void 0 ? _a : t("save"))));
};
