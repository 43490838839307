import styled from "@emotion/styled";
import { fontSize, colors, rem } from "@/styleguide";
export var Input = styled.input({
    backgroundColor: "transparent",
    border: "1px solid " + colors.alto,
    borderRadius: 6,
    color: colors.shipGrey,
    fontFamily: "inherit",
    fontSize: rem(fontSize.small),
    height: 40,
    outline: "none",
    paddingLeft: 18,
    paddingRight: 18,
    ":focus": {
        borderColor: colors.shipGrey,
    },
});
