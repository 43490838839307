import { CardSection } from "@/components/atoms/CardSection";
import { UserProfile } from "@/components/molecules/UserProfile";
import { PersonalDetails as PersonalDetailsList } from "@/components/organisms/PersonalDetails";
import { ChangeLanguage } from '@/components/views/UserSettingsView/ChangeLanguage';
import React from "react";
import { useTranslation } from "react-i18next";
export var PersonalDetails = function (props) {
    var t = useTranslation("UserSettingsView.PersonalDetails").t;
    return (React.createElement(CardSection, null,
        React.createElement(CardSection.Heading, null, t("header")),
        React.createElement("div", { className: "flex-col-center" },
            React.createElement(UserProfile, { theme: "primary", firstName: props.firstName, lastName: props.lastName })),
        React.createElement(PersonalDetailsList, { className: "mt-40", email: props.email, mobile: props.mobile }),
        React.createElement(ChangeLanguage, null)));
};
