import { Global } from "@emotion/core";
import React from "react";
import { AppNavLink } from "@/components/atoms/AppNavLink";
import { PopupMessage } from "@/components/molecules/PopupMessage";
import { AppHeader } from "@/components/organisms/AppHeader";
import { ViewPath } from "@/constants/routing";
import { useNotifier } from "@/hooks/useNotifier";
import { useViewer } from "@/hooks/useViewer";
import { useTranslation } from 'react-i18next';
import { AdminRouter, PublicRouter, EmployerRouter } from "./Routers";
import { globalStyles } from "./styles";
export var App = function () {
    var t = useTranslation('General').t;
    var _a = useNotifier(), message = _a.message, onClose = _a.onClose, severity = _a.severity;
    var _b = useViewer(), isAdmin = _b.isAdmin, isEmployer = _b.isEmployer, loading = _b.loading, onLogOut = _b.onLogOut, viewer = _b.viewer;
    if (loading) {
        return null;
    }
    var homeUrl = ViewPath.HOME;
    var navLinkNodes = null;
    var routerNode = null;
    if (isAdmin) {
        homeUrl = ViewPath.COMPANIES;
        routerNode = React.createElement(AdminRouter, null);
        navLinkNodes = (React.createElement(React.Fragment, null,
            React.createElement(AppNavLink, { to: ViewPath.COMPANIES }, t('manageCompanies')),
            React.createElement(AppNavLink, { to: ViewPath.REPORTS }, t('reports'))));
    }
    else if (isEmployer) {
        homeUrl = ViewPath.EMPLOYEES;
        routerNode = React.createElement(EmployerRouter, null);
        navLinkNodes = (React.createElement(React.Fragment, null,
            React.createElement(AppNavLink, { to: ViewPath.REPORTS }, t('reports')),
            React.createElement(AppNavLink, { to: ViewPath.EMPLOYEES }, t('employees')),
            React.createElement(AppNavLink, { to: ViewPath.FUNDS }, t('fundTransfer'))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Global, { styles: globalStyles }),
        message && severity && (React.createElement(PopupMessage, { message: message, onClose: onClose, severity: severity })),
        viewer === null ? (React.createElement(PublicRouter, null)) : (React.createElement(React.Fragment, null,
            React.createElement(AppHeader, { homeUrl: homeUrl, onLogOut: onLogOut, viewerName: viewer.firstName + " " + viewer.lastName, viewerRole: viewer.role }, navLinkNodes),
            routerNode))));
};
