var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import ReactInputRange from "react-input-range";
import { StyledRoot } from "./styles";
export var RangeInput = function (_a) {
    var onChange = _a.onChange, name = _a.name, props = __rest(_a, ["onChange", "name"]);
    var handleChange = React.useCallback(function (value) {
        if (typeof value !== "number") {
            return;
        }
        onChange({ target: { value: value, name: name } });
    }, [name, onChange]);
    return (React.createElement(StyledRoot, null,
        React.createElement(ReactInputRange, { formatLabel: props.formatLabel, maxValue: props.maxValue, minValue: props.minValue, onChange: handleChange, step: 1, name: name, value: props.value || props.minValue || 1 })));
};
