import styled from "@emotion/styled";
import { colors } from "@/styleguide";
export var BackgroundProvider = styled.div({
    backgroundColor: colors.white,
});
export var TableWrapper = styled.div({
    marginTop: 40,
});
export var Plug = styled.p({
    color: colors.dustyGrey,
    lineHeight: 1.5,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
});
export var ErrorMessage = styled(Plug)({
    color: colors.buttercup,
});
export var ToRight = styled.div({
    display: "flex",
    justifyContent: "flex-end",
});
export var PaginationWrapper = styled.div({
    marginTop: 30,
});
export var Separator = styled.div({
    height: 10,
});
