import styled from "@emotion/styled";
import { colors, fontWeight } from "@/styleguide";
export var Root = styled.div({}, function (props) { return ({
    backgroundColor: props.hasError ? "#ffe4e4" : "unset",
    borderRadius: 2,
    padding: props.hasError ? 2 : "unset",
}); });
export var DataRow = styled.div({
    alignItems: "start",
    display: "flex",
});
export var Label = styled.span({
    color: colors.grey,
    flexShrink: 0,
});
export var Value = styled.span({
    fontWeight: fontWeight.semiBold,
    marginLeft: 5,
}, function (props) { return ({
    color: props.hasError ? colors.red : colors.shipGrey,
}); });
export var ErrorMessage = styled.div({
    color: colors.red,
    fontSize: 10,
    marginTop: 2,
});
