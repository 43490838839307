import styled from "@emotion/styled";
import { colors, fontWeight, ZIndex } from "@/styleguide";
export var Root = styled.div({
    backgroundColor: colors.white,
    borderRadius: 12,
    border: "2px solid",
    bottom: 80,
    left: "calc(50vw + 655px)",
    padding: 20,
    position: "fixed",
    transform: "translateX(-100%)",
    width: 500,
    zIndex: ZIndex.NOTIFIER,
}, function (props) { return ({
    borderColor: props.theme.color,
}); });
export var Message = styled.p({
    fontWeight: fontWeight.semiBold,
    margin: 0,
}, function (props) { return ({
    color: props.theme.color,
}); });
export var IconWrapper = styled.span({
    cursor: "pointer",
    position: "absolute",
    right: 20,
    top: 20,
});
