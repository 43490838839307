import React from "react";
import { useTranslation } from "react-i18next";
import { useChangePassword } from "@/containers/mutations/ChangePassword";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { formClassName } from "./styles";
export var ChangePassword = function () {
    var t = useTranslation("UserSettingsView.ChangePassword").t;
    var _a = useChangePassword({
        successMessage: t("successMessage"),
    }), changePassword = _a[0], changePasswordMeta = _a[1];
    return (React.createElement(ChangePasswordForm, { className: formClassName, formMessage: changePasswordMeta.message, formStatus: changePasswordMeta.severity, loading: changePasswordMeta.loading, onSubmit: changePassword }));
};
