import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "@/components/atoms/Button";
import { Gap } from "@/components/atoms/Gap";
import { Icon } from "@/components/atoms/Icon";
import { ViewPath } from "@/constants/routing";
import { colors } from "@/styleguide";
import { Header, StyledStatCard } from "./styles";
export var EmployeesViewHeader = function (props) {
    var t = useTranslation("EmployeeView.EmployeeViewHeader").t;
    var isAdmin = props.isAdmin;
    return (React.createElement(Header, { isAdmin: isAdmin },
        isAdmin && (React.createElement(Link, { to: ViewPath.COMPANIES },
            React.createElement(Icon, { color: colors.java, name: "arrowLeftCircle", size: 40 }))),
        React.createElement(StyledStatCard, { icon: "personWithPlus", title: t("totalEmployees"), value: props.totalUsersCount }),
        React.createElement(StyledStatCard, { icon: "mobile", title: t("activeEmployees"), value: props.activeUsersCount }),
        !isAdmin && (React.createElement(Gap, { direction: "y", size: 15 },
            React.createElement(Button, { theme: "secondary", to: ViewPath.NEW_EMPLOYEE, type: "button", width: 320 },
                React.createElement(Icon, { color: colors.java, name: "personWithPlus", size: 22 }),
                t("addEmployee")),
            React.createElement(Button, { onClick: props.onBulkUploadClick, theme: "secondary", type: "button", width: 320 },
                React.createElement(Icon, { color: colors.java, name: "personsWithPlus", size: 26 }),
                t("addMultipleEmployees"))))));
};
