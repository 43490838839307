import styled from "@emotion/styled";
import { Td, Tr } from "@/components/molecules/Table";
import { colors, fontWeight, fontSize, rem } from "@/styleguide";
export var Row = styled(Tr)({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "5px solid " + colors.java,
});
export var Cell = styled(Td)({
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: 20,
});
export var CellAlignRight = styled(Cell)({
    justifyContent: "flex-end",
});
export var BoldText = styled.span({
    color: colors.armadillo,
    fontSize: rem(fontSize.regular),
    fontWeight: fontWeight.semiBold,
});
export var GreySmallText = styled.span({
    color: colors.grey,
    fontSize: rem(fontSize.small),
    fontWeight: fontWeight.semiBold,
    marginLeft: 10,
});
