import React from "react";
import { useTranslation } from "react-i18next";
import { InfoBox } from "@/components/atoms/InfoBox";
import { StyledRoot } from "./styles";
export var PersonalDetails = function (props) {
    var t = useTranslation("Molecules.PersonalDetails").t;
    return (React.createElement(StyledRoot, { className: props.className },
        React.createElement(InfoBox, { content: props.mobile || t("na"), title: t("phone") }),
        React.createElement(InfoBox, { content: props.email, title: t("email") })));
};
