import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/atoms/Button";
import { Gap } from "@/components/atoms/Gap";
import { Select } from "@/components/molecules/Select";
import { CompanyName, Header, PeriodSelect, StatsInfo } from "./styles";
export var FundsViewHeader = function (props) {
    var t = useTranslation("FundsView.FundsViewHeader").t;
    return (React.createElement(Header, null,
        React.createElement(Gap, { direction: "y", size: 30 },
            React.createElement(CompanyName, null, props.companyName)),
        React.createElement(Gap, { direction: "x", size: 30 },
            props.isCurrentMonth && React.createElement(StatsInfo, null, t('totalCompleted', { value: props.totalCompleted })),
            React.createElement(PeriodSelect, null,
                React.createElement(Select, { name: "reportPeriod", onChange: props.onChangeReportPeriod, options: props.selectOptions, value: props.reportPeriod })),
            props.openReportModal && props.isCurrentMonth && (React.createElement(Button, { theme: "secondary", onClick: props.openReportModal, type: "button", width: 320 }, t("bankTransfer"))))));
};
