import React from "react";
import { CurrentPage, Page, Separator, StyledRoot } from "./styles";
import { cretePaginationItems } from "./utils";
export var Pagination = function (_a) {
    var currentPage = _a.currentPage, totalPages = _a.totalPages, onPageChange = _a.onPageChange;
    var paginationItems = React.useMemo(function () {
        return cretePaginationItems(currentPage, totalPages);
    }, [currentPage, totalPages]);
    var handlePageChange = React.useCallback(function (evt) {
        var page = evt.currentTarget.getAttribute("data-page");
        if (!page) {
            throw new Error("Undefined data-page attribute on pagination element");
        }
        onPageChange(parseInt(page, 10));
    }, [onPageChange]);
    return (React.createElement(StyledRoot, null, paginationItems.map(function (paginationItem) {
        var element = paginationItem.element, id = paginationItem.id;
        if (element === "separator") {
            return React.createElement(Separator, { key: id }, "...");
        }
        if (element === currentPage) {
            return React.createElement(CurrentPage, { key: id }, element);
        }
        return (React.createElement(Page, { "data-page": element, key: id, onClick: handlePageChange }, element));
    })));
};
