import { formatMoney } from "@/utils/format";
var BORDER_RADIUS = 6;
var X_OFFSET = 50;
var X_PADDING = 16;
var Y_LINES_COUNT = 6;
var Y_PADDING_BOTTOM = 22;
var Y_PADDING_TOP = 8;
var Y_PADDING = Y_PADDING_TOP + Y_PADDING_BOTTOM;
export var chartCalculator = function (settings) {
    var INNER_HEIGHT = settings.height - Y_PADDING;
    var getYLinesGapInPx = function () {
        var numberOfGaps = Y_LINES_COUNT - 1;
        return INNER_HEIGHT / numberOfGaps;
    };
    var getBarsGapInPx = function (series) {
        var barsCount = series.length;
        var numberOfGaps = barsCount - 1;
        var innerWidth = settings.width - 2 * X_PADDING - 2 * BORDER_RADIUS - X_OFFSET;
        return innerWidth / numberOfGaps;
    };
    return {
        getYLinesPath: function () {
            var linesGapInPx = getYLinesGapInPx();
            var parts = [];
            for (var lineIndex = 0; lineIndex < Y_LINES_COUNT; lineIndex += 1) {
                var yPosition = linesGapInPx * lineIndex + Y_PADDING_TOP;
                parts.push("M" + X_OFFSET + "," + yPosition + "L" + innerWidth + "," + yPosition + "Z");
            }
            return parts.join(" ");
        },
        getYLinesLabels: function (max) {
            var linesGapInPx = getYLinesGapInPx();
            var valuesGap = max / (Y_LINES_COUNT - 1);
            var lineLabels = [];
            for (var lineIndex = 0; lineIndex < Y_LINES_COUNT; lineIndex += 1) {
                var y = linesGapInPx * (Y_LINES_COUNT - 1 - lineIndex) + Y_PADDING_TOP;
                lineLabels.push({
                    key: y.toString(),
                    text: formatMoney(valuesGap * lineIndex),
                    x: 0,
                    y: y + 2,
                });
            }
            return lineLabels;
        },
        getBarsLabels: function (series) {
            var barsGapInPx = getBarsGapInPx(series);
            return series.map(function (serie, lineIndex) {
                var x = lineIndex * barsGapInPx + X_OFFSET + BORDER_RADIUS + X_PADDING;
                return {
                    x: x,
                    key: x.toString(),
                    text: serie.label,
                    y: INNER_HEIGHT + Y_PADDING_TOP + 20,
                };
            });
        },
        getBarsPath: function (series, max) {
            var barsGapInPx = getBarsGapInPx(series);
            var parts = series.map(function (serie, index) {
                if (serie.value <= 0) {
                    return;
                }
                var barHeight = max === 0 ? 0 : (settings.height - Y_PADDING) * (1 - serie.value / max);
                var x = barsGapInPx * index + X_OFFSET + X_PADDING + BORDER_RADIUS;
                var y1 = settings.height - Y_PADDING_BOTTOM - BORDER_RADIUS;
                var y2 = Math.min(y1, barHeight + Y_PADDING_TOP + BORDER_RADIUS);
                return "M" + x + "," + y1 + "L" + x + "," + y2 + "Z";
            });
            return parts.filter(Boolean).join(" ");
        },
    };
};
